import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, Tooltip, OverlayTrigger, Form, Tab, Tabs } from 'react-bootstrap'
import MainConteo from './main_conteo'
import data, { configuracion_visual } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import GraficosOrdenes from '../ordenes/graficos';
import ActividadConductores from '../conductores/actividad';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerRangos } from '../../lib/helpers/opcionesfecha';
import EstadisticaMensual from '../estadisticas/estadistica_mensual';
import EstadisticaGeografica from '../estadisticas/estadistica_geocercas';
import { obtenerEstadoTour } from '../../lib/helpers/interfaz';
import Joyride from 'react-joyride';
import { virtual_tour_settings } from '../../lib/global/data';
import { modificarEsquema } from '../../redux/actions/esquemaTablas';
import { cerrarSesion } from '../../redux/actions/session';
import ErrorBoundary from '../errorsHandler/errorBoundary';

const MainActividad = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [error, setError] = useState(null)
    const [selectionRange, setSelectionRange] = useState(initialDate)
    const dispatch = useDispatch()
    const [vehiculos, setVehiculos] = useState([])
    const [loadingVehiculos, setLoadingVehiculos] = useState(true)
    const [filtroConductor, setFiltroConductor] = useState("")
    const [loadingOrdenes, setLoadingOrdenes] = useState(true)
    const [ordenes, setOrdenes] = useState([])
    const [ordenesBase, setOrdenesBase] = useState([]) // SE GUARDA UNA COPIA DE LAS ORDENES EN EL ESTADO PARA FILTRAR Y REMOVER FILTRO
    const interfaz_usuario = useSelector(state => state.esquema_tablas)
    const [configVisual, setConfigVisual] = useState(configuracion_visual)
    const session = useSelector(state => state.miusuario)
    const nombre_tour = 'actividad'
    const [ resize, setResize ] = useState(0)
    const [tour, setTour] = useState(false)
    console.log({ tour, interfaz_usuario })
    const resetContadoresVehiculos = () => {
        const ids_vehiculos = vehiculos.filter(v => v.seleccionado === true).map(v => v._id)
        const ordenes_base_instancia = JSON.parse(JSON.stringify(ordenesBase))
        const filtradas = ordenes_base_instancia.filter(v => ids_vehiculos.includes(v.idconductor))
        return setOrdenes([...[], ...filtradas])
    }
    const seleccionarTodosConductores = async () => {
        const iterados = vehiculos.map((veh, i) => {
            veh.seleccionado = true
            return veh
        })

        setVehiculos(prev => [...[], ...iterados])
        resetContadoresVehiculos()
        return
    }

    const deseleccionarTodosConductores = async () => {
        const iterados = vehiculos.map((veh, i) => {
            veh.seleccionado = false
            return veh
        })

        setVehiculos(prev => [...[], ...iterados])
        resetContadoresVehiculos()
        return
    }

    const onClickConductor = async (id) => {
        const i = vehiculos.findIndex(v => v._id === id)
        if (i > -1) {
            vehiculos[i].seleccionado = vehiculos[i].seleccionado === true ? false : true
            setVehiculos(prev => [...[], ...vehiculos])
            resetContadoresVehiculos()
            return
        }
        return
    }
    const obtenerDatos = async (from, to) => {
        return fetch(`${data.urlapi}/conductores/reporte`, {
            method: 'POST',
            body: JSON.stringify({
                condicion: {
                    $or: [
                        { entrega_desde: { $gte: from, $lte: to } },
                        { entrega_hasta: { $lte: to, $gte: from } },
                        { entrega_hasta: { $gte: to }, entrega_desde: { $lte: from } },
                    ]
                }
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    setError('Sin datos')
                    return setLoadingVehiculos(false)

                } else if (res.errorMessage) {
                    setError(res.errorMessage)
                    return setLoadingVehiculos(false)

                } else if (res.conductores) {
                    if (Array.isArray(res.conductores) !== false) {
                        setVehiculos(res.conductores.map(e => {
                            e.seleccionado = true
                            return e
                        }))
                    }
                    if (Array.isArray(res.ordenes) !== false) {
                        setOrdenes(res.ordenes)
                        setOrdenesBase(res.ordenes)
                    }
                    // obtenerOrdenes(res.map(v => v._id))
                }
                return setLoadingVehiculos(false)
            })
            .catch(error => {
                setError(`Error al consultar la información: ${error.message}`)
                return setLoadingVehiculos(false)
            })
    }

    const gpt = () => {
        const assignValues = (payload, schema, assignments) => {
            const result = {};

            const assignRecursively = (source, target, assignments) => {
                for (const sourceKey in assignments) {
                    const value = assignments[sourceKey];

                    if (typeof value === 'object') {
                        target[sourceKey] = {};
                        assignRecursively(source, target[sourceKey], value);
                    } else if (Array.isArray(value)) {
                        target[sourceKey] = value.map(itemAssignments => {
                            const newItem = {};
                            assignRecursively(source, newItem, itemAssignments);
                            return newItem;
                        });
                    } else {
                        target[sourceKey] = getValueFromPath(payload, value);
                    }
                }
            };

            assignRecursively(payload, result, assignments);
            return result;
        };

        const getValueFromPath = (object, path) => {
            const keys = path.split('.');
            let value = object;
            for (const key of keys) {
                if (value.hasOwnProperty(key)) {
                    value = value[key];
                } else {
                    return undefined;
                }
            }
            return value;
        };

        // Ejemplo de uso
        const payload = {
            person: {
                name: 'Juan',
                age: 30,
                address: {
                    city: 'Example City',
                    country: 'Example Country'
                }
            },
            hobbies: ['reading', 'hiking']
        };

        const schema = {
            info: {
                fullname: '',
                years: 0,
                location: {
                    urban: '',
                    nation: ''
                }
            },
            interests: []
        };

        const assignments = {
            person: {
                name: 'fullname',
                age: 'years',
                address: {
                    city: 'location.urban',
                    country: 'location.nation'
                }
            },
            hobbies: [
                {
                    interest: 'hobbies'
                }
            ]
        };

        const resultado = assignValues(payload, schema, assignments);

    }

    useEffect(() => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        obtenerDatos(desde, hasta)
        console.log({ interfaz_usuario })
        const abrir_tour = obtenerEstadoTour(nombre_tour, interfaz_usuario)
        console.log({ abrir_tour, nombre_tour, interfaz_usuario })
        setTour(abrir_tour)
    }, [])

    const refrescarOrdenes = (data) => {
        const { desde, hasta } = data
        obtenerDatos(desde, hasta)
    }

    const cargandoScreen = () => {
        return <Row>
            <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
        </Row>
    }

    const handleChangeFiltroProveedor = (e) => {
        const { value } = e.target
        return setFiltroConductor(value)
    }

    const tabla_registros = (datos) => {
        if (loadingVehiculos) return cargandoScreen()
        if (datos.length < 1) return <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        const filtrar = filtroConductor.toLowerCase();
        let pedidos_filtrados = datos.filter(item => {
            return Object.keys(item).some(key => {
                if (!item[key]) return false
                if (key === 'orden') {
                    if (!item[key].billing) return false
                    return Object.keys(item[key].billing).some(llave => {
                        if (!item[key].billing[llave]) return false
                        return item[key].billing[llave].toString().toLowerCase().includes(filtrar)
                    })
                }
                return item[key].toString().toLowerCase().includes(filtrar)
            })
        })

        return <div style={{ maxHeight: 200, overflowX: "scroll" }} >
            <input className='form-control mb-2' placeholder='BUSCAR VEHÍCULO' onChange={handleChangeFiltroProveedor} />
            <Button variant="outline-dark mr-2 mb-2" size="sm" style={{ fontSize: 10 }} onClick={() => seleccionarTodosConductores()}>SELECCIONAR TODOS</Button>
            <Button variant="outline-dark mb-2" size="sm" style={{ fontSize: 10 }} onClick={() => deseleccionarTodosConductores()}>DESELECCIONAR TODOS</Button>
            {
                pedidos_filtrados.map(conductor => {
                    return <div md={2} key={conductor._id} className="mb-3 hover" onClick={() => onClickConductor(conductor._id)}>
                        <h6 style={{ fontSize: 13 }} className="mb-0">{conductor.seleccionado === true ? <i className="fa-solid fa-square-check text-primary"></i> : <i className="fa-regular fa-square"></i>} {conductor.nombres} {conductor.apellidos} {conductor.modelo ? conductor.modelo.toUpperCase() : ''} {conductor.ano}</h6>
                        { /* <BoxList conductor={ve} seleccionado={ve.seleccionado} onClickConductor={(id) => onClickConductor(id)} /> */}
                    </div>
                })
            }
        </div>
    }

    const mostrarDataOrdenes = () => {
        if (loadingOrdenes === true) return <Spinner animation='border' />
        if (ordenes.length < 1) return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <img src="images/pedidos.png" style={{ width: "50%" }} />
                    <h3>No hay datos para mostrar</h3>
                </Col>
            </Row>
        </div>

        return <div>
            <GraficosOrdenes ordenes={ordenes} />
        </div>
    }

    const handleCheck = (e, name) => {
        const valor = e.target.checked
        configVisual[name] = valor
        return setConfigVisual(prev => ({ ...{}, ...configVisual }))
    }

    const selectorVisualizacionCajas = () => {
        return <div>
            <Row className='mb-3'>
                <Col>
                    <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="grafico_vehiculo_proveedor">
                        <Form.Check type="switch" label="Mostrar Gráfico por vehículo" defaultChecked={configVisual.grafico_vehiculo_proveedor} onChange={(e) => handleCheck(e, "grafico_vehiculo_proveedor")} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="grafico_general">
                        <Form.Check type="switch" label="Mostrar Gráfico general" defaultChecked={configVisual.grafico_general} onChange={(e) => handleCheck(e, "grafico_general")} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="eventos_conductores">
                        <Form.Check type="switch" label="Mostrar Eventos conductores" defaultChecked={configVisual.eventos_conductores} onChange={(e) => handleCheck(e, "eventos_conductores")} />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    }

    const mostrarVehiculosSeleccionados = () => {
        const filtrados = vehiculos.filter(v => v.seleccionado === true)
        if (filtrados.length < 1) return false
        return <OverlayTrigger
            overlay={
                <Tooltip >
                    A continuación los datos son solo de los vehículos seleccionados
                </Tooltip>
            }
        >
            <div>
                <h6 className='mb-3'>Los siguientes datos corresponden a: {filtrados.map(v => <span className='mr-2' style={{ background: '#cfcfcf', fontSize: 11, padding: '4px 15px', borderRadius: 5 }}>{v.patente ? v.patente.toUpperCase() : ''}</span>)}</h6>
            </div>
        </OverlayTrigger>
    }

    const handleJoyrideCallback = (e) => {
        const { action } = e
        console.log({ action })
        if (action === 'close' || action === "reset") {
            // Aquí puedes manejar la acción de cerrar el tutorial
            console.log('Usuario cerró el tutorial');
            setTour(false)
            const instancia_esquema = JSON.parse(JSON.stringify(interfaz_usuario))
            if (!instancia_esquema.tour_details) instancia_esquema.tour_details = {}
            instancia_esquema.tour_details[nombre_tour] = { dismiss: true }
            return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
        } else if (action === "skip") {
            setTour(false)
            const instancia_esquema = JSON.parse(JSON.stringify(interfaz_usuario))
            instancia_esquema.tour_dismiss = true
            return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))

        }
    }

    const tour_virtual = () => {
        if (!tour) return false
        return <Joyride showSkipButton callback={handleJoyrideCallback} styles={{ options: virtual_tour_settings.estilo }} continuous={true} showProgress={true} steps={tour} locale={virtual_tour_settings.locale} />
    }

    const onActualizar = (data) => {
        vehiculos.map((v, i) => vehiculos[i].seleccionado = false)
        vehiculos.map((v, i) => {
            if (!v.id_proveedor) return
            if (data.includes(v.id_proveedor)) vehiculos[i].seleccionado = true
            return
        })
        setVehiculos(prev => [...[], ...vehiculos])
        const ids_vehiculos = vehiculos.filter(v => v.seleccionado === true).map(v => v._id)
        const ordenes_base_instancia = JSON.parse(JSON.stringify(ordenesBase))
        const filtradas = ordenes_base_instancia.filter(v => ids_vehiculos.includes(v.idconductor))
        return setOrdenes([...[], ...filtradas])
    }

    const handleTabing = () => {
        const nuevo_valor = resize + 1
        return setResize(nuevo_valor)
    }

    return <div className='px-4'>
        <ErrorBoundary message={error}/>
            <Tabs
                defaultActiveKey="vista_operacion"
                className="container-fluid border-0"
                onSelect={handleTabing}
            >
                <Tab eventKey="vista_operacion" title="Vista por Operación">
                    {/* <Col md={12}> */}
                    {/* {selectorVisualizacionCajas()} */}

                    <Card className='px-3 pt-3 shadow-sm-sm-sm mb-3'>
                        <EstadisticaMensual />
                    </Card>
                    <h3 style={{ fontWeight: "bold" }}>Ver operación geográficamente</h3>
                    <Card className='mb-3 shadow-sm'>
                        <EstadisticaGeografica resize={resize} />
                    </Card>
                </Tab>

                {/* <Tab eventKey="vista_general" title="Vista General">
                    <Card className='mb-3 shadow-sm-sm-sm'>
                        {
                            configVisual.grafico_vehiculo_proveedor ? <div>
                                <MainConteo token={session.tokenSession} ordenes={ordenes} bg="dark" onChangeDates={data => refrescarOrdenes(data)} />
                            </div> : false
                        }
                    </Card>
                </Tab> */}
                {/*
                <h4><i className="fa-solid fa-arrow-right"></i> Vista por conductores o proveedores</h4>
                <Card className='p-3 mb-3 shadow-sm-sm-sm'>
                <Row>
                    <Col md={3}>
                        <BuscadorProveedores token={session.tokenSession} onActualizar={(data) => onActualizar(data)} />
                        {tabla_registros(vehiculos)}
                    </Col>
                    <Col md={9}>
                        {mostrarVehiculosSeleccionados()}
                        {
                        configVisual.grafico_general ? <div>
                        <ConteoEstatico token={session.tokenSession} datos={ordenes} />
                        </div> : false
                        }
                    </Col>
                </Row>
                </Card>
                */}
                {/* <Tab eventKey="vista_geografica" title="Vista Geográfica">
                    <h4 className='delpa_lastmile_actividad_geografico'><i className="fa-solid fa-arrow-right"></i> Búsqueda geográfica</h4>
                    
                </Tab> */}

                {/* <Tab eventKey="reporte_conductor" title="Reporte App Conductor">
                    {
                        configVisual.eventos_conductores ? <Card className='mb-3 shadow-sm'>
                            <ActividadConductores token={session.tokenSession} id_conductores={vehiculos.map(v => v._id)} />
                        </Card> : false
                    }
                </Tab> */}
                {/* </Col> */}
            </Tabs>
        {/* </Row> */}
        {tour_virtual()}
    </div>
}

export default MainActividad