import React, { FC } from "react"
import { Button, Card, Col, Modal, Row } from "react-bootstrap"
import { FaChevronDown } from "react-icons/fa"
import { MdContentPaste } from "react-icons/md"
import Select from 'react-select'

type registroEdicion = {
    concatenar: any[],
}

interface ComponentProps {
    registro: registroEdicion,
    onChange: (data: any ) => void,
    onPaste: () => void,
    valToSet: any
}

const Concatenaciones: FC<ComponentProps> = ({ registro, onChange, onPaste, valToSet }) => {

    const [ row, setRow ] = React.useState(registro)
    const [ show, setShow ] = React.useState(false)

    const handleClose = () => {
        return setShow(false)
    }

    const concatenarColumna = () => {
        return setRow(prev => {
            const actual = { ...prev }
            if(!actual.concatenar) actual.concatenar = []
            actual.concatenar.push({
                texto: "",
                columna: ""
            })
            if(onChange) onChange(actual)
            return actual
        })
    }

    const handleChangeTexto = (e, posicion_concatenacion ) => {
        const { name, value } = e.target
        return setRow(prev => {
            let actual = { ...prev }
            actual.concatenar[posicion_concatenacion][name] = value
            if(onChange) onChange(actual)
            return actual
        })
    }

    const removerConcat = (posicion_concatenacion) => {
        return setRow(prev => {
            let actual = { ...prev }
            actual.concatenar.splice(posicion_concatenacion,1)
            return actual
        })
    }

    const pegarValor = (i) => {
        return setRow(prev => {
            let actual = { ...prev }
            actual.concatenar[i].valor = valToSet
            if(onChange) onChange(actual)
            if(onPaste) onPaste()
            return actual
        })
    }

    const boxConcatenacion = () => {


        return <Modal show={show} onHide={()=> handleClose()} centered 
      backdrop="static"
      keyboard={false}
      >
          <Modal.Header closeButton >
            <Modal.Title>Conectar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Puedes conectar una columna con otra y agregar texto en el medio...</p>
          <Button variant="outline-primary" className="mb-3" size="sm" onClick={() => concatenarColumna()}>AGREGAR CONCATENACIÓN</Button>
            {
                row.concatenar.map((co,ico) => {

                    return <Card key={`con-${ico}`} className="shadow mb-2 p-2">
                        <Row>
                            <Col md={8}><h4>{ico+1}</h4></Col>
                            <Col md={4} className="text-right"><Button size="sm" variant="link" className="text-danger" onClick={() => removerConcat(ico)}> REMOVER</Button></Col>
                        </Row>
                        <label className="form-control-label">Agrega un texto entre</label>
                        <input className="form-control" value={co.texto} placeholder="Escribe un texto antes de la concatenación" name="texto" onChange={(e) => handleChangeTexto(e,ico)} />

                        <label className="form-control-label">Valor</label>
                        <Row>
                            <Col md={7}>
                                <input className="form-control" value={co.valor} placeholder="Escribe un texto antes de la concatenación" name="valor" onChange={(e) => handleChangeTexto(e,ico)} />
                            </Col>
                            <Col md={5}>
                                <Button variant="light" disabled={!valToSet} onClick={() => pegarValor(ico)}><MdContentPaste /> PEGAR VALOR</Button>
                            </Col>
                        </Row>
                    </Card>
                })
            }
          </Modal.Body>
        </Modal>
    }
    
    if(!row.concatenar) return <Button size="sm" className="mt-2" onClick={() => concatenarColumna()}>CONCATENAR</Button>
    return <div>
        <h6 className="hover" onClick={() => setShow(true)}><FaChevronDown /> <b>{row.concatenar.length} CONCATENACIONES</b></h6>
        {boxConcatenacion()}
    </div>

}

export default Concatenaciones