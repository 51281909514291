import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { urlapi } from "../../../lib/backend/data"
import { Badge, Button, Card, Modal, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useReactToPrint } from "react-to-print"
import { FaBarcode, FaCheckCircle, FaPrint } from "react-icons/fa"
import { default_proveedores, encontrarLogoPorCodigoProveedor } from "../../proveedores_transporte/data"
import { Link } from "react-router-dom"
import { PiWarehouse } from "react-icons/pi"
import AgendarRecoleccionModal from "../../recolecciones/crear_modal"
import { stringByStatusRecolection } from "../../recolecciones/helper"
import ErrorBoundary from "../../errorsHandler/errorBoundary"

const EtiquetaCourrier = (props) => {
    const {
        viewType,
        id_pedido,
        propietario,
        id_ruta,
        recoleccion
    } = props
    const [ error, setError ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ recoleccionActiva, setRecoleccionActiva] = useState(false)
    const [ courriers, setCourriers ] = useState([{ nombre: "tracking-avanzado" }])
    const [ loadingCourriersActuales, setLoadingCourriersActuales ] = useState(true)
    const [ courriersActuales, setCourriersActuales ] = useState([])
    const componentRef = useRef()
    const [ relacion, setRelacion ] = useState({
        idrecurso: id_pedido,
        valor: "tracking-avanzado"
    })
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const pageStyle = `
        @media print {
            .page-break {
            page-break-before: always;
            }
            h3 {
            font-size: 40px !important;
            }
            .qr svg {
            width: 50%;
            height: auto;
            display: block;
            margin: 0 auto;
            }
            .etiquetabodega {
            width: 100%
            }
        }  
        `;


    const obtenerDatos = async () => {
        if(!id_pedido) return
        setLoading(true)
        return fetch(`${urlapi}/ordenes/courriers/etiqueta`,{
            method: 'POST',
            body: JSON.stringify({
                idrecurso: id_pedido
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                setError("Sin datos")
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if( Array.isArray(res)){
                setEtiquetas(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            setLoading(false)
        })
    }

    const eliminarReferencia = async (id) => {
        setLoadingCourriersActuales(true)
        return fetch(`${urlapi}/courriers/courriers?id=${id}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                setError("Sin datos")
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){
                return obtenerCourriersActuales()
            }
            return setLoadingCourriersActuales(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            setLoadingCourriersActuales(false)
        })
    }

    const obtenerCourriersActuales = async () => {
        if(!id_pedido) return setLoadingCourriersActuales(false)

        setLoadingCourriersActuales(true)
        return fetch(`${urlapi}/courriers/courriers?id=${id_pedido}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                setError("Sin datos")
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if( Array.isArray(res) !== false){
                setCourriersActuales(res)
            }
            return setLoadingCourriersActuales(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            setLoadingCourriersActuales(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        relacion[name] = value
        return setRelacion(relacion)
    }

    const mostrarCourriersActuales = () => {
        if(loadingCourriersActuales) return <Spinner animation="border" />

        return <div className="mt-3">
            <h3>Courriers actualmente asignados </h3>
            <div className="row">
                {
                    courriersActuales.map((cou,ic) => {
                        return <div key={cou._id} className="col-md-3">
                                <div className="card p-3">
                                    <p className="mb-0">{cou.codigo}</p>
                                    <h3 className="mb-0">{cou.tracking_number}</h3>
                                    <button className="btn btn-sm btn-outline-danger" onClick={() => eliminarReferencia(cou._id)}>ELIMINAR CONEXIÓN COURRIER</button>
                                </div>
                        </div>
                    })
                }
            </div>
        </div>
    }
      
    useEffect(() => {
        if(recoleccion) setRecoleccionActiva(recoleccion)
        obtenerDatos()
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle
    })

    const handleClose = () => {
        setShowModal(false)
    }

    const decodificar = (etiqueta, carrier) => {

        if(carrier === "bluexpress"){
            return <div>
                <object data={`data:application/pdf;base64,${etiqueta.image}`} type="application/pdf" width="600" height="400">
                <p>Tu navegador no tiene soporte para PDF. Por favor descarga el PDF para verlo: <a href={`data:application/pdf;base64,${etiqueta.image}`}>Descargar PDF</a>.</p>
            </object>

            </div>
        } else if(carrier === "correos de chile"){
            return <div>
                <object data={`data:application/pdf;base64,${etiqueta.image}`} type="application/pdf" width="600" height="400">
                <p>Tu navegador no tiene soporte para PDF. Por favor descarga el PDF para verlo: <a href={`data:application/pdf;base64,${etiqueta.image}`}>Descargar PDF</a>.</p>
            </object>

            </div>
        }

        return <img src={`data:image/png;base64,${etiqueta.image}`} />
    }

    const modalImpresion = (carrier) => {
        return <div>
            <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Etiquetas personalizadas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            <Button className="mb-3" variant="light" onClick={() => handlePrint()}><FaPrint /> IMPRIMIR</Button>
            <div id="print-labels" ref={componentRef}>
            {
                etiquetas.map((etiqueta,i) => {
                    return <div key={`et-${i}`} >
                        {decodificar(etiqueta, carrier)}
                        { (i+1) === etiquetas.length ? false : <div className="page-break"></div> }
                    </div>
                })
            }
            </div>
            </Modal.Body>
            </Modal>
        </div>
            
    }

    const openModal = () => {
        setShowModal(true)
    }

    const encontrarSucursalPorProveedor = (carrier) => {

        let link = ''
        if(carrier === "chilexpress"){
            link = "https://centrodeayuda.chilexpress.cl/sucursales"
        } else if(carrier === "bluexpress"){
            link = "https://www.blue.cl/punto-blue-express/"
        } else if(carrier === "correos de chile"){
            link = "https://www.correos.cl/sucursales"
        }

        return <div>
            <Link target="_blank" to={link}><Button className="w-100 mb-2" size="sm" variant="light"><PiWarehouse /> VER SUCURSALES</Button></Link>
            
        </div>
    }

    const setearRecoleccion = (rec) => {
        return setRecoleccionActiva(true)
    }

    const vistaSimple = (carrier, url_logo) => {

        return <div>
            { url_logo ? <img src={url_logo} className="mb-2 mt-3" style={{ width: 170 }} /> : <h3>{carrier}</h3> }
        </div>
    }

    const formulario = () => {
        if(loading) return <Spinner animation="border" />
        if(etiquetas.length < 1) return false
        let carrier = ''

        for( const label of etiquetas){
            if(label.carrier){
                carrier = label.carrier
            }
        }

        const url_logo = encontrarLogoPorCodigoProveedor(carrier, default_proveedores)

        if(viewType === "simple") return vistaSimple(carrier, url_logo)
        
        return <div>
            <h3>¿Qué debo hacer?</h3>
            <h6 className="mb-1" style={{fontWeight:700}}>Imprime las etiquetas</h6>

            <Button variant="light" size="sm" onClick={() => openModal()}><FaBarcode /> MOSTRAR ETIQUETAS</Button>
            {modalImpresion(carrier)}
            { url_logo ? <img src={url_logo} className="mb-2 mt-3" style={{ width: 170 }} /> : <h3>{carrier}</h3> }
            <h6 className="mb-1" style={{fontWeight:700}}>Consigna en la sucursal o solicita una recolección</h6>
            {encontrarSucursalPorProveedor(carrier)}
            { !recoleccionActiva ? <AgendarRecoleccionModal id_ruta={id_ruta} id_pedidos={id_pedido} onProveedorNuevo={(rec => setearRecoleccion(rec))} /> : <div>
                <Card className="p-3">
                <h3><FaCheckCircle color="green" /> Recolección agendada</h3>
                <Badge variant="dark" style={{ textTransform: "uppercase" }}>{stringByStatusRecolection(recoleccionActiva.status)}</Badge>
                </Card>
                </div> }
        </div>
    }

    if(!id_pedido) return false

    return <div>
        <ErrorBoundary message={error} />
        {formulario()}
    </div>
}

export default EtiquetaCourrier