import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb, InputGroup, FormControl, Modal } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import { rutas } from '../../lib/routes/routes'
// import { obtenerNumeroCelularPorPais } from '../../lib/helpers/data/internationa'
import { cerrarSesion } from '../../redux/actions/session'
import VerifyModelBusiness from '../../subComponents/model_business/verify_model_business'
import { BiSolidError } from 'react-icons/bi'
import { FaCheckCircle, FaRegClock } from 'react-icons/fa'
import { TiScissors } from 'react-icons/ti'
import TiposServicioSelectorPersonalizado from '../../subComponents/ordenes/tipos_servicio/selector_personalizado'

class VehiculosCrear extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            nuevoconductor: {
                activo: true,
                phone: "+"
            },
            validarTelefono: false,
            creandoVehiculo: false,
            openModal: false,
            statusBoton: false,
            value: ""
        }
        this.handleChangeVehiculo = this.handleChangeVehiculo.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.limpiarInput = this.limpiarInput.bind(this)
        
    }

    abrirModal(){
        this.setState({openModal: true})
    }

    handleClose(){
        this.setState({openModal: false})
    }

  validarPhone = (str) => {
    const { nuevoconductor} = this.state
    nuevoconductor.phone = str
    this.setState({nuevoconductor})
    const regex = /^\+\d{11,}$/;
    return regex.test(str); // Retorna true si cumple, false si no
  };

  limpiarInput = (inputValue) => {
      if (inputValue.charAt(0) !== '+') {
          this.setState({ value: '' }); 
          return; 
        }
    let cleanedValue = inputValue.replace(/[^0-9]/g, ''); 
    // Si el primer carácter es '+', lo mantenemos, si no, lo añadimos
    if (inputValue.charAt(0) === '+') {
      cleanedValue = '+' + cleanedValue; // Agrega el '+' al inicio
    }
    this.setState({ value: cleanedValue });
    const validar = this.validarPhone(cleanedValue)
                    if(validar){
                            this.setState({validarTelefono: true })
                          }else{ 
                            this.setState({validarTelefono: false})}      
  };

 handleChange(e){
        this.limpiarInput(e.target.value);
    }

    handleChangeVehiculo(e){
        const { name, value } = e.target
        const { nuevoconductor} = this.state
        if(name === 'rut'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === 'frecuencytracking'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === 'ano'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === "phone"){
            const valor = value ? value : "+"
            nuevoconductor[name] = valor
        } else if(name === "patente"){
            nuevoconductor[name] = value.toLowerCase().trim().replace(/\s/g, "")
        } else {
            nuevoconductor[name] = value
        }
        console.log(nuevoconductor)
        return this.setState({ nuevoconductor })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    componentDidMount(){
        const { user } = this.state
    }

    crearVehiculo(){
        const { nuevoconductor, user, validarTelefono } = this.state
        if(!validarTelefono) return  toast.error("Verifique que el telefono este bien escrito", this.state.toaststyle)
   

        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'patente', label: 'Patente' },
            { value:'phone', label: 'Teléfono' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoconductor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        

        const loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores`,{
            method:'POST',
            body: JSON.stringify( nuevoconductor ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = `/${rutas.vehiculos.slug}/edit/${res._id}`
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    modalTelefono(){
        const { openModal } = this.state
        return <Modal show={openModal} size="lg"  onHide={() => this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col xs={12} className="p-1 text-center centrador">
                   <h5>Debes ingresar el telefono de la siquiente manera:</h5>
              </Col>
                <Col xs={12} className="p-4 text-center centrador">
                <h3><b>+56912345678</b></h3>            
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    formularioVehiculo(){
        const { nuevoconductor, creandoVehiculo, statusBoton, user, validarTelefono, value } = this.state
        if(creandoVehiculo === true) return <Spinner animation='border' />
        const items = nuevoconductor.capabilities ? nuevoconductor.capabilities.map(e => e._id) : []

        return <div className='text-primary' style={{fontWeight:700}}>
            <Row>
                <Col md={12}>
                <h5 className='mb-1' style={{fontWeight:700}}><i className="fa-solid fa-id-card"></i> Datos del Conductor</h5>
                </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label'>Nombres</label>
                <input name="nombres" className='form-control shadow-sm' value={nuevoconductor.nombres}  onChange={this.handleChangeVehiculo}/>
            </Col >
            <Col md={3} className='mb-3'>
                <label className='form-control-label'>Apellidos</label>
                <input name="apellidos" className='form-control shadow-sm' value={nuevoconductor.apellidos} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label'>Rut</label>
                <input name="rut" className='form-control shadow-sm' value={nuevoconductor.rut} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={4} className='mb-3'>
                <label className='form-control-label'>Móvil Whatsapp</label>
                <input
                                                    placeholder="Ingresa el número"
                                                    className='form-control mb-3 shadow-sm'
                                                    type="tel"
                                                    name="phone"
                                                    value={nuevoconductor.phone}
                                                    onChange={this.handleChange} />
            </Col>
            <Col xs={12}>
            <h5 className='mb-1' style={{ fontWeight: 700 }}><FaRegClock /> Horario de trabajo</h5>
            <Row>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label'>Desde</label>
                    <input name="horario_desde" type="time" className='form-control shadow-sm' value={nuevoconductor.horario_desde} onChange={this.handleChangeVehiculo} />
                </Col >
                <Col md={2} className='mb-3'>
                    <label className='form-control-label'>Hasta</label>
                    <input name="horario_hasta" type="time" className='form-control shadow-sm' value={nuevoconductor.horario_hasta} onChange={this.handleChangeVehiculo} />
                </Col >
            </Row>
            </Col>
        
            </Row>
            <Row>
            <Col md={12}>
                <h5 className='mb-1' style={{fontWeight:700}}><i className="fa-solid fa-car"></i> Datos del Vehículo</h5>
                <label className="form-control-label d-block">Capacidades especiales que posee este vehículo</label>
                <TiposServicioSelectorPersonalizado full_payload={true} defaultValue={items} isMulti={true} condicion_default={{ tipo: "logistica", sub_tipo: "capacidad-especial" }} onChangeValue={(val) => {
                    const { nuevoconductor } = this.state
                    nuevoconductor.capabilities = val
                    this.setState({ nuevoconductor })
                }} />
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label'>Modelo</label>
                <input name="modelo" className='form-control shadow-sm' value={nuevoconductor.modelo} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={3} className='mb-3'>
                <label className='form-control-label'>Año</label>
                <input name="ano" className='form-control shadow-sm' maxLength={4} value={nuevoconductor.ano} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={3} className='mb-3'>
                <label className='form-control-label'>Patente</label>
                <input name="patente" className='form-control shadow-sm' value={nuevoconductor.patente} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={3} className="d-flex align-items-center">
                <Button className='shadow-sm mt-3 w-100' variant='success' disabled={statusBoton} onClick={()=>this.crearVehiculo()}><b>CREAR NUEVO VEHÍCULO</b></Button>
            </Col>
            <Col md={12}>
            <VerifyModelBusiness vehiculo={true} token={user.tokenSession} handleChangeBoton={(status) => this.setState({statusBoton: status})}/>
            </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <>
            <div className='text-primary'  style={{background: this.state.statusBoton ? '#ffd971' : '', padding: 10, borderRadius: 10}}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#"><Link to="/vehiculos">Vehículos</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Crear</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4 style={{fontWeight:700}}><i className="fa-solid fa-truck"></i> Crear</h4>
                    <Card>
                        <Card.Body>
                            {this.formularioVehiculo()}
                        </Card.Body>
                    </Card>
            </div>
                {this.modalTelefono()}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario,
        pais: state.pais
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VehiculosCrear);