import { useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify'
import CargandoMainConteos from './cargando'
import { useSelector } from 'react-redux'
import ErrorBoundary from '../errorsHandler/errorBoundary'

const ConteoEstatico = (props) => {
    const [error, setError] = useState(null)
    const bg = props.bg ? props.bg : 'light'
    const datos = props.datos ? props.datos : [] 
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [loadingEstados, setLoadingEstados] = useState(true)
    const [estados, setEstados] = useState([])

    useEffect(()=>{
        obtenerEsquemaEstados()
    },[ datos ])

    const obtenerEsquemaEstados = () => {
        return fetch(`${data.urlapi}/ordenes/suite-config-estados`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
        .then(pros => pros.json())
        .then(data => { 
              if(!data){
              return setLoadingEstados(false)
              } else if(data.errorMessage){
                  setError(data.errorMessage)
                  return setLoadingEstados(false)
              } else if(Array.isArray(data) !== false){
                  setEstados(data)
              }
              return setLoadingEstados(false)
        })
        .catch(error => {
            return setLoadingEstados(false)
        })
      }

      const SinDatos = () => {
        const src = window.location.protocol + "//" + window.location.host + "/images/pedidos.png"
        return <div className='text-center'>
            <img src={src} style={{ maxWidth: 200 }} className='mb-2' />
            <h5>Sin información</h5>
        </div>
      }

      const conteos = () => {
        if(loadingEstados===true) return <CargandoMainConteos />
        if(datos.length < 1) return <SinDatos />
        if(estados.length < 1) return <SinDatos />
        let retrasadas = 0
        const cantidad_finalizadas = datos.filter(d => d.finalizado === true).length
        const porcentaje_finalizadas = (cantidad_finalizadas/datos.length)*100
        const hoy = new Date()
        for( const orden of datos ){
            if(!orden.fecha_max_entrega) continue
            if(orden.finalizado === true){
                if(!orden.fecha_finalizado) continue
                if(orden.fecha_finalizado > orden.fecha_max_entrega) retrasadas++
                continue
            }
            const fecha = new Date(orden.fecha_max_entrega)
            // console.log({ fecha, bd: orden.fecha_max_entrega })
            if(fecha < hoy) retrasadas++
        }
        const porcentaje_retrasados = (retrasadas/datos.length)*100

        return <div>
            <Row>
                <Col>
                    <Card className='p-2'>
                        <h6 style={{ fontSize: 12 }}>Retrasadas </h6>
                        <h2 className='text-danger'>{retrasadas} <span style={{ fontSize: 15 }}>{porcentaje_retrasados.toFixed(0)}%</span></h2>
                    </Card>
                </Col>
                <Col>
                    <Card className='p-2'>
                        <h6 style={{ fontSize: 12 }}>Finalizadas</h6>
                        <h2>{cantidad_finalizadas} <span style={{ fontSize: 15 }}>{porcentaje_finalizadas.toFixed(0)}%</span></h2>
                    </Card>
                </Col>
                {
                    estados.map((info,i) => {
                        const filtrar = datos.filter(e => {
                            if(!e.estado_entrega) return false
                            if(typeof e.estado_entrega !== 'object') return false
                            if(!e.estado_entrega.codigo_estado) return false
                            return e.estado_entrega.codigo_estado === info.codigo_estado ? true : false
                        })
                        return <Col key={`c-${info.titulo}`}>
                            <Card className={`p-2 ${info.seleccionado === true ? 'border-white' : ''}`} bg={bg}>
                                <h6 style={{ fontSize: 12 }}>{info.titulo}</h6>
                                <h2>{filtrar.length}</h2>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </div>
      }


    return <div className='mb-3'>
        <ErrorBoundary message={error} />
        {conteos()}
    </div>
}

export default ConteoEstatico