import React, { useEffect, useState } from 'react'
import { Accordion, Card, Col, Row, Spinner, Modal, Button, Table,  Pagination, Badge } from 'react-bootstrap'
import { estilo_last_mile } from "../../lib/global/styles";
import Skeleton from 'react-loading-skeleton'
import backend_data, { modulo, status, types } from "../../subComponents/ordenes/ordenes_tickets/data";
import { LuTicket } from 'react-icons/lu';
import { useParams } from 'react-router-dom'
import CargaImagenes from "./carga_imagenes";
import CamposObligatoriosSimbolo from "../../subComponents/general/campo_requerido";
import { FaCheckCircle, FaChevronDown, FaRegClock, FaRegPlusSquare } from "react-icons/fa";
import { toast } from 'react-toastify'
import data, { urlapi } from '../../lib/backend/data'
import polyline from '@mapbox/polyline';
import datos from '../../lib/backend/data';
import { configuracion_fechas, configuracion_horas } from '../../lib/helpers/dates'
import { duracionTiempoString, fechaATextoSimple, fechaATextoSimpleCorto, fechaUTCATexto } from '../../lib/helpers/helpers';
import { useDispatch } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import { url_images } from '../../lib/global/data'
import MapaDetallePedidoTrackingPage from './mapa_tracking_page'
import { BsAsterisk, BsFillTrashFill } from "react-icons/bs";
import { esNumero, formatoMoneda } from '../../lib/helpers/main';
import TablaProductos from '../../subComponents/ordenes/tabla_productos'
import { canalEstadosString } from '../../lib/helpers/pedidos/pedidos'
import { fechaATexto } from '../../lib/helpers/helpers'
import { IoIosAttach } from "react-icons/io";
import ModalListadoOrdenes from '../../subComponents/ordenes/modal_listado';
import DetailFull from './detalle-full';
import CargandoDetalleFull from './cargando_detalle_full';
import { DateTime } from 'luxon';
import CalificarRegistro from '../../subComponents/calificaciones_configuracion/calificar';
import ModalPersonalizado from '../../subComponents/general/modalPersonalizado';
import TagSinDatos from '../../subComponents/general/tag_sin_datos';
import { MdPlace } from 'react-icons/md';
const turf = require('@turf/turf');

const Tracking = (props) => {
  const {
    size,
    id_local
  } = props
  const [loadingPedido, setLoadingPedido] = useState(true)
  const [detalle_pedido, setDetallePedido] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ archivos, setArchivos ] = useState([])
  const [nuevoProveedor, setNuevoProveedor] = useState({})
  const [ loadingPosicionConductor, setLoadingPosicionConductor ] = useState(false)
  const [ lineaTiempo, setLineaTiempo ] = useState([])
  const [ ordenSeleccionada, setOrdenSeleccionada ] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalDetalle, setShowModalDetalle] = useState(false)
  const [loadingEquipos, setLoadingEquipos] = useState(true)
  const [personalizacionTracking, setPersonalizacionTracking] = useState({})
  const trash = props.trash ? props.trash : false
  const [ routesConductor, setRoutesConductor ] = useState([])
  const dispatch = useDispatch()
  const [proveedores, setProveedores] = useState([])
  const [ marcadores, setMarcadores ] = useState([])
  const [ total, setTotal ] = useState(0)
  const [ intervalo, setIntervalo ] = useState(null)
  const [ pagina, setPagina ] = useState(1)
  const [ loadingDetalle, setLoadingDetalle ] = useState(false)
  const [ loadingProveedores, setLoadingProveedores ] = useState(true)
  const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
  const [ limitPage, setLimitPage ] = useState(datos.pagina)
  const [ idSeleccionado, setIdSeleccionado ] = useState(false)
  const [ crearNuevo, setCrearNuevo ] = useState(true)
  const [show, setShow] = useState(false); 
  const [isActive, setIsActive] = useState(false);    
  const [ ubicacionConductor, setUbicacionConductor ] = useState(null)
  const route = useParams()
  const [ configuracionTracking, setConfiguracionTracking ] = useState(false)
  // const session = useSelector(state => state.miusuario)
  // const token = session.tokenSession
  const { id } = route
  const id_orden = id_local ? id_local : id

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModalDetalle(true);
  const handleCloseModalDetalle = () => setShowModalDetalle(false);

  const handleChangeNuevo = (e) => {
    const { name, value } = e.target
    nuevoProveedor[name] = value
    return setNuevoProveedor(nuevoProveedor)
}

const validarRespuestaRutaMapbox = (sugerencias) => {
  let way_points          = []
  let rutas_consultadas   = []
  let errores_mensajes    = []

  for( const res of sugerencias){
      if(res.uuid){
          if(res.code) if(res.code.toString().toUpperCase() === "OK") {
              if(res.routes) if(Array.isArray(res.routes) !== false) if(res.routes.length > 0){
                  rutas_consultadas = [...rutas_consultadas, ...[ res.routes[0] ]]
                  way_points = [...way_points, ...res.waypoints]
              }
          } else {
              errores_mensajes.push(res.message)
          }
      }
  }
  if(errores_mensajes.length > 0) toast.error(errores_mensajes.join(', '))
  // alinearMapa(way_points)
  console.log({ rutas_consultadas, sugerencias })
  if(rutas_consultadas.length > 0) {
      setRoutesConductor(rutas_consultadas)
  }
}


const calcularDistanciaConductor = async (origen, puntos, profile) => {
  setLoadingPosicionConductor(true)
  // openNotification("Obteniendo información de la ruta...", <div><h5 level={5} className='mt-0 mb-0'><Spin /> Verás la información del camino sugerido</h5></div>)
  return fetch(`${urlapi}/geodata/direcciones-public`,{
      method:'POST',
      body: JSON.stringify({
          origen,
          puntos,
          profile
      }),
      headers: {
          'Content-Type':'application/json',
          // 'Authorization': `Bearer: ${sesion.tokenSession}`
      }
  })
  .then(res => {
      // if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      if(!res){
      } else if(res.errorMessage){
          
      } else if(Array.isArray(res) !== false){
          validarRespuestaRutaMapbox(res)
      }
      return false
  })
  .catch(error => console.log(error.message))
}

const refrescarUbicacion = async (id, points) => {
  if(!id) return
  const url = `${urlapi}/ordenes/tracking-vehicle?id=${id}`
  return fetch(url, {
      method: "GET",
      headers: {
          'Content-type': "application/json",
          // 'Authorization': `Bearer: ${session.tokenSession}`,
      }
  })
  .then(res => res.json())
  .then(async res => {
      if(!res){
      } else if(res.location){
          if(res.location?.coordinates){
              if(res.location.coordinates.length > 1){
                  setUbicacionConductor(prev => {
                      let actual = {...prev, ...res}
                      if(!actual) return actual
                      calcularDistanciaConductor(actual, points, "driving-traffic")
                      return {...{}, ...actual }
                  })
              }
          }
      }
      return false
  })
  .catch(error => false)
}


  const iniciarIntervalosRastreo = (id_orden, points) => {
    const id_intervalo = setInterval(() => {
        refrescarUbicacion(id_orden, points)
      }, 4000);
      setIntervalo(id_intervalo)
  }

  const obtenerPedido = async () => {
    return fetch(`${urlapi}/ordenes/tracking?id=${id_orden}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (!res) {
          toast.error('Sin datos')
          return setLoadingPedido(false)
        } else if (res.errorMessage) {
          toast.error(res.errorMessage)
          return setLoadingPedido(false)
        } else if (res.orden) {
          setDetallePedido(res)
          let puntos = []
          if(res.orden?.linea_tiempo){
            setLineaTiempo(res.orden?.linea_tiempo)
          }
          if(res.orden.location){
            if(Array.isArray(res.orden.location.coordinates)){
                if(res.orden.location.coordinates.length > 0){
                  puntos.push({
                    lat: res.orden.location.coordinates[1],
                    lng: res.orden.location.coordinates[0],
                    ref: res.orden.pedido
                  })
                  setMarcadores(puntos)
                }
            }
          }
          if(Array.isArray(res.orden?.conductor?.ubicacion?.location?.coordinates)) if(res.orden?.conductor?.ubicacion?.location?.coordinates.length > 0){
            if(res.orden.estado_entrega?.codigo_estado){
              
              if(res.orden?.finalizado !== true){
              setUbicacionConductor(prev => {
                let actual = {...prev, ...res.orden?.conductor?.ubicacion}
                if(!actual) return actual
                  calcularDistanciaConductor(actual, puntos.map(e => ({ location: { coordinates: [ e.lng, e.lat ]} }) ), "driving-traffic")
                  setTimeout(() => {
                      iniciarIntervalosRastreo( id_orden, puntos.map(e => ({ location: { coordinates: [ e.lng, e.lat ]} }) ) )
                  }, 200);
                  return {...{}, ...actual }
                })
              }

            }
          }

          if(res.configuracion_interfaz) setPersonalizacionTracking(res.configuracion_interfaz)
        }
        return setLoadingPedido(false)
      })
      .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingPedido(false)
      })
  }

  const paginar = (page,ignorar) => {
    if(ignorar) return false
    setPagina(page)
    obtenerProveedores(page)
}   

const obtenerProveedores = async (page, query, page_quantity)=>{
  setLoadingProveedores(true)
  if(query) if(trash === true) query.status = 'trash'
  const condicion = query ? query : {id_orden}

  let limite_paginacion = page_quantity ? page_quantity : limitPage

  return fetch(`${urlapi}/ordenes/${modulo}/list/tracking`,{
      method:'POST',
      body: JSON.stringify({
          condicion,
          pagina: page
      }),
      headers: {
          'Content-Type':'application/json',
          limit: limite_paginacion
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      console.log(res)
      if(!res){
          toast.error('Sin datos')
          return setLoadingProveedores(false)
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
          return setLoadingProveedores(false)
      }
      if(Array.isArray(res.datos) !== false){
          setProveedores(res.datos)
          setTotal(res.total)
      }
      return setLoadingProveedores(false)
  })
  .catch(error => {
      toast.error("Error al consultar la información, intente nuevamente")
      return setLoadingProveedores(false)
  })
}

const cerrarTiket = async (id)=>{
  setLoadingProveedores(true)

  return fetch(`${urlapi}/ordenes/${modulo}/list/tracking`,{
      method:'PUT',
      body: JSON.stringify({
          id
      }),
      headers: {
          'Content-Type':'application/json',
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      console.log(res)
      if(!res){
          toast.error('Sin datos')
          return setLoadingProveedores(false)
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
          return setLoadingProveedores(false)
      }
      if(typeof res === 'object'){
        handleCloseModalDetalle()
        obtenerProveedores(pagina)
      }
      return setLoadingProveedores(false)
  })
  .catch(error => {
      toast.error("Error al consultar la información, intente nuevamente")
      return setLoadingProveedores(false)
  })
}

  useEffect(() => {
    obtenerPedido()
    obtenerProveedores()
  }, [])

  const mostrarSubEstadoHTML = (sub_estado_entrega) => {
    if (sub_estado_entrega) {
      if (typeof sub_estado_entrega === 'object') {
        if (sub_estado_entrega.titulo) {
          return <span className="spanstatus mr-2" style={{ textTransform: "uppercase", backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2', padding: "1px 10px", borderRadius: 5, color: "white" }}>{sub_estado_entrega.titulo}</span>
        }
      }
    }
    return false
  }

  const statusString = (status) => {

    let cadena = "";

    if (status) {
      if (status == 1) {
        cadena = "Preparado";
      } else if (status == 2) {
        cadena = "Leído en el móvil";
      } else if (status == 3) {
        cadena = "Retirado en origen";
      } else if (status == 4) {
        cadena = "En reparto";
      } else if (status == 5) {
        cadena = "Entregado";
      } else if (status == 6) {
        cadena = "Entrega Confirmada";
      } else if (status == 7) {
        cadena = "Entrega Confirmada";
      } else if (status == 9) {
        cadena = "Retiro de bodega";
      } else if (status == 18) {
        cadena = "En CD Delpa";
      } else if (status == 13) {
        cadena = "No entregado";
      } else {
        cadena = "Sin datos";
      }
    } else {
      cadena = "Sin datos";
    }
    return cadena;
  }

  const statusColor = (level) => {

    let color = "#dbdbde";

    if (level == 1) {
      color = "#cad004";
    } else if (level == 2) {
      color = "#d09d04";
    } else if (level == 3) {
      color = "#d03e04";
    } else if (level == 4) {
      color = "#5e72e4";
    } else if (level == 5) {
      color = "#64b612";
    } else if (level == 6) {
      color = "#64b612";
    } else if (level == 7) {
      color = "#64b612";
    } else if (level == 9) {
      color = "#ff71a6";
    } else if (level == 18) {
      color = "#a31be1";
    } else if (level == 13) {
      color = "#000";
    }

    return color;

  }

  const mostrarLineaTiempo = () => {
    if(!lineaTiempo) return false
    if(!lineaTiempo.hitos) return false
  
    return <div className='py-2 mb-3 text-center p-3'>
        <Row>
        {
            lineaTiempo.hitos.map((evento,i) => {
              const emitidos = lineaTiempo.hitos.filter((hito,pos) => hito.choosen === true && pos > i).length > 0
                // if(evento.opcional_linea_tiempo === true && !evento.emitido) return false
                return <Col key={evento._id}>
                    {evento.choosen === true || emitidos ? <i className="fa-solid fa-circle-check text-success" style={{fontSize:20}}></i> : <i className="fa-solid fa-clock text-secondary" style={{fontSize:20}}></i>}
                    <h6 className='mb-0' style={{ fontSize: 12 }}><b>{evento.titulo}</b></h6>
                </Col>
            })
        }
        </Row>
        </div>
  }

  const sinRegistrosHTML = (palabra, archivo) => {
    return <div className='text-center p-3'>
      {/* <img src={`/images/${archivo}`} style={{ width: 70 }} /> */}
      <h3>No hay {palabra} registrados</h3>
    </div>
  }

  const sizeImage = () => {
    if(size==="sm") return 50
    return 100
  }

  const isPointWithinRoute = (point, route, tolerance) => {
    // Convertir el punto y la ruta a features de Turf
    const pointFeature = turf.point(point);
    const lineFeature = turf.lineString(route);
    
    // Calcular la distancia del punto a la línea
    const distance = turf.pointToLineDistance(pointFeature, lineFeature, {units: 'kilometers'});
    
    // Verificar si la distancia está dentro de la tolerancia
    return distance
}


  const showRouteEspectation = () => {
    if(!ubicacionConductor) return false
    if(!ubicacionConductor.location) return false
    if(!ubicacionConductor.location.coordinates) return false
    if(ubicacionConductor.location.coordinates.length < 2) return false
    if(!routesConductor) return false
    if(routesConductor.length < 1) return false
    const ruta_conductor = routesConductor[0]

    const punto = [ubicacionConductor.location.coordinates[0],ubicacionConductor.location.coordinates[1]]
    const route = polyline.decode(ruta_conductor.geometry)
    
    const en_ruta = isPointWithinRoute(punto, route)
    
    return en_ruta < 200 ? <Badge variant="success">EN RUTA</Badge> : <Badge variant="danger">FUERA DE RUTA</Badge>
}


const obtenerValorEta = (key) => {
  let valor_exportar = 0
  if(configuracionTracking) if(configuracionTracking.detalles) if(typeof configuracionTracking.detalles === "object") if(configuracionTracking.detalles[key]) if(!isNaN( configuracionTracking.detalles[key]) ) valor_exportar = parseInt(configuracionTracking.detalles[key])
  return valor_exportar
}
const showTimeEspectation = (minutos_faltantes_llegada) => {
  const usuario = detalle_pedido.orden
  const fecha_entrega = DateTime.fromISO(usuario.fecha_max_entrega)
  const faltante_esperado = DateTime.now().plus({ minutes: minutos_faltantes_llegada })
  
  const tiempo_diferencia = fecha_entrega.diff(faltante_esperado, ["minutes", "hours", "days"])?.values

  const {
      days,
      hours,
      minutes
  } = tiempo_diferencia

  const maximo_dias           = obtenerValorEta('flexibilidad_dias')
  const maximo_horas          = obtenerValorEta('flexibilidad_horas')
  const maximo_minutos        = obtenerValorEta('flexibilidad_minutos')

  console.log({ tiempo_diferencia: { days, hours, minutes }, flexibilidad: { maximo_dias, maximo_horas, maximo_minutos }, faltante_esperado: faltante_esperado.toISO(), fecha_entrega: usuario.fecha_max_entrega })
  
  if(parseInt(days) < maximo_dias || parseInt(hours) < maximo_horas || parseInt(minutes) < maximo_minutos ){

      let dias_retraso = days < 0 ? (days * -1) : 0
      let horas_retraso = hours < 0 ? (hours * -1) : 0
      let minutos_retraso = minutes < 0 ? (minutes * -1) : 0

      if(dias_retraso > 0)        dias_retraso        = Math.round( dias_retraso - maximo_dias )
      if(horas_retraso > 0)       horas_retraso       = Math.round( horas_retraso - maximo_horas )
      if(minutos_retraso > 0)     minutos_retraso     = Math.round( minutos_retraso - maximo_minutos )

      let tiempo_faltante = ''
      if(dias_retraso > 0) tiempo_faltante += `${dias_retraso} Días`
      if(horas_retraso > 0) tiempo_faltante += ` ${horas_retraso} Horas`
      if(minutos_retraso > 0) tiempo_faltante += ` ${minutos_retraso} Minutos`

      return <Badge color="danger">RETRASADO</Badge>
  }

  return <Badge variant="success">A TIEMPO</Badge>
  
}



  const mostrarInformacionTrafico = () => {
    let ruta_transito = null
    if(routesConductor.length > 0) ruta_transito = routesConductor[0]

    if(!ruta_transito) return false

    return <Card size="small" className='p-2'>
      <Row>
        <Col>
        <p className="mt-0 mb-0"><b>Tiempo estimado de llegada</b></p>
        <h5 level={4} className="mt-0 mb-2" style={{ color: estilo_last_mile.color_primary }}>{duracionTiempoString(ruta_transito.duration / 60)} </h5> 
        {/* {showTimeEspectation(ruta_transito.duration / 60)} */}
        </Col>
        <Col>
        <p className="mt-0 mb-0"><b>Distancia para la entrega</b></p>
        <h5 level={4} className="mt-0 mb-2" style={{ color: estilo_last_mile.color_primary }}>{formatoMoneda(ruta_transito.distance / 1000)} Km.</h5>  
        </Col>
      </Row>
          
        
        </Card>
}

  const showComponentByTipoFormulario = (campo) => {
    const height = sizeImage()
    switch (campo.tipo_accion) {
      case 'campo-texto':
        if(!campo.valor) return false
        return <Row className="mt-2 mb-3 px-2">
          <Col>
            <Card className='d-block border-0'>
              <h6 className='mb-0 text-primary' style={{ fontWeight: 900 }}>{campo.titulo}</h6>
              <p className='mb-0 text-primary' >{campo.valor ? campo.valor : 'Sin información'}</p>
            </Card>
          </Col>
        </Row>
      case 'selector':
        if(!campo.valor) return false
        return <div className="mt-2 mb-3 px-2">
          <h6 className='mb-0 text-primary' style={{ fontWeight: 900 }}>{campo.titulo}</h6>
          <p className='mb-0 text-primary'>{campo.valor ? campo.valor : 'Sin información'}</p>
        </div>
      case 'carga-imagenes':
        if(campo.valores.length < 1) return false
        return <div className="mt-2 mb-3 px-2">
          <h6 className='mb-2' style={{ fontSize: 14 }}>{campo.titulo}</h6>
          {
              campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                if(campo.private === true) return false
                return <a href={foto.url} target="_blank" className='pr-2' key={`foto-estado-${i}`}>
                  <div style={{ width: 70, height: 70, marginRight: 5, boxShadow: "1px 4px 5px #878686", display:"inline-block", backgroundImage: `url(${foto.url})`, backgroundSize: "cover", borderRadius: 13, backgroundPosition: "center" }}></div>
                  </a>
              }) : <div className='col-md-12'><p>Sin información</p></div>
            }
        </div>
      case 'firma-digital':
        if(!campo.valor) return false
        const isNumero = esNumero(campo.valor)
        if(isNumero === true) return false

        return <div className="mt-2 mb-3 px-2 text-primary">
            <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
            <p className='mb-0'>
                {campo.valor 
                    ? <img src={campo.valor} alt="Firma digital" style={{maxWidth: '25%'}} /> 
                    : 'Sin información'
                }
            </p>                  
        </div>
      default:
        break;
    }
  }

  const mostrarMetaDatosSubEstado = (metadatos) => {
    if (!metadatos) return false
    if (Array.isArray(metadatos) !== true) return false
    if (metadatos.length < 1) return false

    return metadatos.map((meta, i) => {
      return <div key={`meta-${i}`}>
        {showComponentByTipoFormulario(meta)}
      </div>
    })
  }

  const paginacion = (ciclo, total) => {
    const cantidad = Math.ceil(total / ciclo)
    return <div>
        <Pagination size='sm'>
            {
                Array.from(Array(cantidad).keys()).map(number => {
                    const active = pagina === (number+1) ? true : false
                    return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                })
            }
        </Pagination>
    </div>
}


  
  const agregarArchivo = (url,name) => {
    console.log(url,name)
    return setArchivos(prev => {
        let actual = [...prev]
        actual.unshift({
            url,
            name
        })
        return [...actual]
    })
}
  const removerArchivo = (i) => {
    setArchivos(prev => {
        let actual  = [...prev]
        actual.splice(i,1)
        return [...actual]
    })
}

  const mostrarEstados = (estados, typeView) => {
      if (!estados) return sinRegistrosHTML('estados', 'tracking-estados.png')
      if (Array.isArray(estados) !== true) return sinRegistrosHTML('estados', 'tracking-estados.png')
      if (estados.length < 1) return sinRegistrosHTML('estados', 'tracking-estados.png')


        return <div className=''>
        {typeView === 'accordion' ? (<>
          <div className=''>
            <div className='d-flex align-items-center justify-content-between'>
            {/* <h5 className='text-primary' ><b>Estados de carga</b></h5> */}
            <h6 className='text-secondary' style={{fontSize:13}}><i className="fa-regular fa-hand-pointer"></i> Haz click en el Estado para ver mas detalles.</h6>
            </div>
            <Accordion defaultActiveKey={''} className="mb-2">
            {
                estados.map((estado,i) => {

                  let lat = 0
                  let lng = 0

                  if(estado.location){
                    if(Array.isArray(estado.location.coordinates) !== false){
                      if(estado.location.coordinates.length > 1){
                        lat = estado.location.coordinates[1]
                        lng = estado.location.coordinates[0]
                      }
                    }
                  }

                    return <Card className="p-0 mx-1 shadow-sm text-left">
                        <Accordion.Toggle as={Card.Header} style={{ padding: 8, fontSize: 12, backgroundColor:'rgba(27, 54, 101, 0.1)' }} eventKey={`estado-${i+1}`}>
                        <Row>
                          <Col md={9}>
                          <div className="pl-2 pb-0">
                            { lat !== 0 && lat !== 0 ? <span style={{ fontWeight: 700 }}>{fechaATexto(estado.fecha)} · <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank"><MdPlace style={estilo_last_mile.icon} /> </a></span> : false }
                            <p className="mb-0" style={{fontWeight:900}}><i className="fa-solid fa-circle" style={{ color: estado.estado_color }}></i> <b>{estado.estado}</b> <b>{estado.subestado}</b></p>
                          </div>
                          </Col>
                          <Col md={3} className='text-right'><FaRegPlusSquare  className='hover' size={20} /></Col>
                        </Row>
                        
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`estado-${i+1}`}>
                        <Card.Body className='text-primary' style={{fontWeight:700}}>
                          <p className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</p>
                          <p className="mb-0"><b>Por :</b> {estado.identificador_creador}</p>
                            {
                                mostrarMetaDatosSubEstado(estado.metadatos)
                            }
                            {/* {
                              (i+1) === estados.length ? <div>
                                {
                                  eliminandoEstado ? <div className="loadingbox">
                                    <Spinner animation='border' />
                                  </div> : <button className='btn btn-sm mt-3 btn-outline-danger' onClick={() => solicitarEliminarEstado(estado._id)}>ELIMINAR ESTADO</button>
                                }
                                
                              </div> : false
                            } */}
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                })
            }
            </Accordion>
          </div>
          </>) : (
              <div>
                <Card className='p-2 pb-3 shadow-sm'>
                <h5 className='mb-2 text-primary' style={{ fontWeight: 900, fontSize: 20 }} ><i className="fa-solid fa-map-pin"></i> Estados de carga</h5>
                  {estados.map((estado, index) => {
                      const fecha = new Date(estado.fecha);
                      return (
                          <div key={`estado-${index}`} className='border shadow p-2 bg-white'>
                              <div className='d-flex text-primary p-0 align-items-center py-3 pl-1 border bg-light'>
                                  <div>
                                      <h6 className='text-white bg-primary px-2 py-1 rounded-circle' style={{
                                        fontWeight: 700,
                                        padding: "3px 11px"
                                      }}>{index + 1}</h6>
                                    </div>
                                    <div className=''>
                                      <h6 className='mb-0 ml-2' style={{ textTransform: "uppercase", fontSize: 12 }} >{fecha.toLocaleDateString('es-ES', configuracion_fechas)} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></h6>
                                      <span className="spanstatus mx-2" style={{ fontWeight:700, fontSize:12, textTransform: "uppercase", backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado.estado}</span>
                                      {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color, })}
                                    </div>
                                </div>
                                {/* <hr className='hr' /> */}
                                {mostrarMetaDatosSubEstado(estado.metadatos)}                        
                          </div>
                      );
                  })}
          </Card>
              </div>
          )}
      </div>
    


    // return <div className='row'
    // style={{ backgroundColor: '#eee', paddingTop: 15, marginBottom: 20 }}
    // >
    //   {
    //     estados.map((estado, im) => {
    //       const fecha = new Date(estado.fecha)
    //       return <Card className='shadow-sm mx-2 mt-2 w-100' key={`estado-${im}`}>
    //         <div className='p-3 text-primary'>
    //           <h4 className='bubleestado text-white shadow'>{im + 1}</h4>
    //           <h6 className='mb-2' style={{ textTransform: "uppercase", fontSize: 13 }} >{fecha.toLocaleDateString('es-ES', configuracion_fechas)} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></h6>
    //           <span className="spanstatus mr-2" style={{ textTransform: "uppercase", backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado.estado}</span>
    //           {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color, })}
    //           <hr className='hr' />
    //           {mostrarMetaDatosSubEstado(estado.metadatos)}
    //         </div>
    //       </Card>
    //     })
    //   }
    // </div>
  }


  const shoyEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
    if (estado_entrega) {
      if (typeof estado_entrega === 'object') {
        if (estado_entrega.titulo) {
          return <div>
            <span className="spanstatus mr-2 mt-0" style={{ backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb', padding: "1px 10px", textTransform: "uppercase", borderRadius: 5, color: "white" }}>{estado_entrega.titulo}</span>
            {mostrarSubEstadoHTML(sub_estado_entrega)}
          </div>
        }
      }
    }
    return <span className="spanstatus" style={{ backgroundColor: statusColor(estadoentrega) }}>{statusString(estadoentrega)}</span>
  }


  const Header = () => {
    return <div className='mb-3'>
      <img src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
    </div>
  }

  const mostrarDatosPersonalizados = () => {
    if (loadingPedido) return <div className="loadingbox text-center mb-3">
      <Header />
      <Spinner animation='border' />
      <h4 className='my-2' style={{fontWeight:900}}>Cargando información...</h4>
    </div>

    if (!detalle_pedido) return <div className="loadingbox text-center">
      <Header />
      <h2 className='mb-2' style={{fontWeight:900}}>Sin información</h2>
    </div>

    const orden = typeof detalle_pedido.orden.orden === 'object' ? detalle_pedido.orden.orden : {}
    const conductor = typeof detalle_pedido.orden.conductor === 'object' ? detalle_pedido.orden.conductor : {}
    const facturacion = typeof orden.billing === 'object' ? orden.billing : {}


    const estados = mostrarEstadosCarga()
    const destinatario = mostrarDestinatario(facturacion)
    const mapa = mostrarMapa()
    const productos = mostrarMasInfo(orden, facturacion)

    const items = [
      { title: 'Estado de la carga', content: estados },
      { title: 'Destinatario', content: destinatario },
      { title: 'Productos', content: productos },
    ].map((item, i) => ({ ...item, key: (i+2).toString() }))

    return <div>

      <Row className=''>
          <Col md={mapa ? 6 : 12} xs={12}>
          {mostrarReferencia(orden, conductor)}
          {mostrarCalificacion()}

          <Accordion defaultActiveKey={items.filter(e => e.content).length > 0 ? items.filter(e => e.content)[0].key : "" } className="mb-2">
                    {
                      items.map((item, posi) => {
                        if(!item.content) return false
                        return <Card className="p-0 shadow-sm text-left">
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12 }} eventKey={item.key}>
                        <div className="pl-2 pb-0 hover"><h5><FaChevronDown size={20} /> <b>{item.title}</b></h5> </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={item.key}>
                        <Card.Body className='text-primary' style={{fontWeight:700}}>
                          {item.content}
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                      })
                    }
            </Accordion>
          </Col>
          { mapa ? <Col md={6} xs={12}>{mapa}</Col> : false }
      </Row>
    </div >
  }

  const crearNuevoTicket = async () => {

    const requeridos = [
        { value:'titulo', label: 'Título'},
        { value: "type", label: "Tipo" },
    ]
    let faltantes = []
    requeridos.map(campo => {
        if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
        return true
    })
    if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
    setLoading(true)
    if(id_orden) {
        nuevoProveedor.id_orden = id_orden
    } else {
        if(ordenSeleccionada) nuevoProveedor.id_orden = ordenSeleccionada?._id
    }
    nuevoProveedor.atachment = archivos
    return fetch(`${urlapi}/ordenes/tickets/tracking`, {
        method: 'POST',
        body: JSON.stringify(nuevoProveedor),
        headers: {
            'Content-Type':'application/json'
        }
      })
      .then(pros => pros.json())
      .then(async pros => {
          if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
          } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
          } else if(pros._id){
                if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                toast.success(`Realizado exitosamente`)
                setNuevoProveedor({...{}, ...{}})
                setArchivos([])
                handleCloseModal()
                obtenerProveedores()
          }
          return setLoading(false)
      })
      .catch(async error => {
            toast.error('No se pudo efectuar la operación')
            return setLoading(false)
      })
}

    const crearTickets = () => {
      if (proveedores.length > 0 && proveedores.some(orden => orden.open === true)) return false;
          return <div className=''>
            {/* <h5 className='mb-0' style={{fontWeight:900}}><LuTicket /> Crear ticket</h5>
                <p className="p-0 m-0"><i className="fa-solid fa-circle-info text-warning"></i> Puedes generar un ticket de atención sobre ordenes.</p> */}
                { 
                <Button className="shadow-sm mr-2 mb-2" size="sm" variant="primary" onClick={handleOpenModal}><b>CREAR TICKET <i className="fa-solid fa-angles-right"> </i></b></Button>
                }
        <Row>
            
            <Modal show={showModal} onHide={handleCloseModal} size="lg"  >
                <Modal.Header closeButton className="pb-0 ">
                    <Modal.Title className="text-primary" style={{fontWeight:900}}>CREAR TICKET</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 text-primary">
                    <p className="text-secondary mb-0 pb-0" style={{fontWeight:700}}>Los campos marcados con <BsAsterisk/> son obligatorios</p>
                <hr />
                <Row>
                {/* <Col md={4} className="">
                    <div>
                        {!id_orden ? <ModalOrdenesEditar title="Seleccionar una orden" isMulti={false} onSelect={(data) => asignarOrden(data)} /> : false }                
                    </div>
                </Col> */}
                <Col md={12} className="d-flex justify-content-end">
                    <div className="pb-2 mr-2">    
                        { ordenSeleccionada ? <h5 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}><FaCheckCircle /> Orden seleccionada: {ordenSeleccionada?.pedido}</h5> : false }
                    </div>
                </Col>
                <Col md={4} className="">
                    <div>
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Tipo <CamposObligatoriosSimbolo /></label>
                    <select className="form-control shadow-sm" name="type" value={nuevoProveedor.type} onChange={handleChangeNuevo} >
                        <option value="">Selecciona</option>
                        { types.map((type,i) => <option key={`ty-${i}`} value={type.value}>{type.label}</option>) }
                    </select>
                    </div>
                </Col>
                <Col md={8} className="">
                    <div>
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Observaciones <CamposObligatoriosSimbolo /></label>
                    <input className="form-control shadow-sm" name="titulo" value={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
                    </div>
                </Col>
                <Col md={12}>
                <textarea defaultValue={nuevoProveedor.content} className="form-control my-3" style={{ minHeight: 100 }} name="content" placeholder="Escribe tu mensaje" onChange={handleChangeNuevo} />
                <CargaImagenes onUploaded={(url,name) => agregarArchivo(url,name)} />
                <div className="mt-3">
                {
                    archivos.map((archivo,i) => {
                        return <div className='d-flex' key={`ar-${archivo.name}-${i}`}>
                            { archivo.url ? <a href={archivo.url} target="_blank" rel="noreferrer"><h5 style={{fontWeight: "bold", color: "blue" }}><IoIosAttach /> {archivo.name}</h5></a> : <h5><IoIosAttach /> {archivo.name}</h5> }
                            <Button className='ml-2 pt-0 px-0 bg-transparent border-0' style={{fontSize:12}} size="sm" onClick={() => removerArchivo(i)} ><i className="fa-solid fa-circle-xmark text-danger"></i></Button>
                        </div>
                    })
                }
                </div>
                </Col>
                {/* <Col md={4} className="">
                    <SelectorZonasEmpresa titulo="Ruta a asignar" onChange={handleChangeZona} />
                </Col> */}
                {/* <Col xs={12}>
                <div className="d-flex justify-content-between mb-2">
                <h5 style={{fontWeight:900}}><i className="fa-solid fa-envelope"></i> Emails adicionales</h5>
                <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirEmailAdicional()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                </div>
                    {mostrarEmailsAdicionales()}

                    <div className="d-flex justify-content-between my-2">
                    <h5 style={{fontWeight:900}}><i className="fa-solid fa-phone"></i> Teléfonos adicionales</h5>
                    <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirMovilAdicional()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                    </div>
                    {mostrarMovilesAdicionales()}

                </Col> */}
                <Col md={12}>
                    <div className="d-flex justify-content-end">
                    <Button size="sm" variant="primary" disabled={loading} className="shadow-sm w-25" onClick={()=>crearNuevoTicket()} ><b>{ loading ? "CREANDO..." : "CREAR TICKET" }</b></Button>
                    </div>
                </Col>
                </Row>
                </Modal.Body>
        </Modal>
        </Row>
        </div>
    }

    const onClickProveedor = async (id) => {
      setLoadingDetalle(id)
      setIdSeleccionado(id)
      setCrearNuevo(false)
      setShow(!show)
      setIsActive(true)
      return fetch(`${urlapi}/ordenes/${modulo}/tracking/detail?id=${id}`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json'
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(res => {
          console.log(res)
          if(!res){
              toast.error('Sin datos')
              return setLoadingDetalle(false)
          } else if(res.errorMessage){
              toast.error(res.errorMessage)
              return setLoadingDetalle(false)
          } else if(res._id){
              setVehiculoSeleccionado(res)
              handleShowModal()
          }
          return setLoadingDetalle(false)
      })
      .catch(error => {
          toast.error(`Error al consultar la información: ${error.message}`)
          return setLoadingDetalle(false)
      })
  }


    const mostrarMapa = () => {
        let show_module = getConfigFromModuleSlug('mapa')
        const configuracion = getConfigModuleFromSlug('mapa')
        if(show_module === false) return false
      
      return <div className='mt-2'>
        {mostrarInformacionTrafico()}
        <MapaDetallePedidoTrackingPage markers={marcadores} height={450} ubicacionConductor={ubicacionConductor}  routesConductor={routesConductor} />
        {configuracion.extra}
      </div> 
      } 

    const mostrarEstadosCarga = () => {
        let show_module = getConfigFromModuleSlug('estados_carga')
        const configuracion = getConfigModuleFromSlug('estados_carga')
        if(show_module === false) return false

        return <div className=''>
          {mostrarEstados(detalle_pedido.estados, 'accordion')}
            {configuracion.extra}
          </div> 
    }

    const calificar = () => {

    }

    const mostrarCalificacion = () => {
      const show_module = getConfigFromModuleSlug('calificar')
      if(!show_module) return false
      const esquema  = detalle_pedido?.orden?.modelo_calificacion
      if(!esquema) return false
      if(detalle_pedido?.orden?.calificacion_asociada) return <h3>¡Gracias por calificar!</h3>

      return <div>
        <ModalPersonalizado component={<CalificarRegistro id_target={detalle_pedido.orden?._id} calificado={detalle_pedido?.orden?.calificacion_asociada ? true : false } objeto={esquema} onCalificado={(data) => calificar(data)} />} title="CALIFICAR" />
      </div>
    }

    const mostrarReferencia = (orden, conductor) => {
      
        let show_module = getConfigFromModuleSlug('resumen')
        const configuracion = getConfigModuleFromSlug('resumen')
        if(show_module === false) return false

        return  <div className='shadow-sm border pt-2 mb-2 px-3 w-100 bg-white'>
        {/* <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Referencia</label> */}
        <div className='d-flex justify-content-between align-items-center mt-2'>
        <div>
          <h4 className=' text-primary' style={{ fontWeight: 900 }}>Orden {detalle_pedido.orden.pedido}</h4>
          <p className='' style={{fontWeight:700}}>{shoyEstadoEntregaHTML(detalle_pedido.orden.estadoentrega, detalle_pedido.orden.estado_entrega, detalle_pedido.orden.sub_estado_entrega)}</p>
          
        </div>
        {/* <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Orden de transporte</label>
          <p className=' text-primary' style={{ fontWeight: 700 }}>{detalle_pedido.orden.ot ? detalle_pedido.orden.ot : <p><i style={{ fontSize: 14, fontWeight: 400 }}>- sin Informacion -</i></p>} </p>
        </div> */}
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Fecha Creación</label>
          <p className=' text-primary'>{orden.createdAt ? orden.createdAt : <i style={{ fontSize: 14, fontWeight: 400 }}>{fechaATextoSimple(detalle_pedido?.orden?.createdAt)}</i>}</p>
          {configuracion.extra}        
        </div>
        </div>
        {mostrarLineaTiempo()}
        <hr className='mt-0' />
        <h5 className='mb-2 d-block text-primary' style={{ fontWeight: 900 }}><i className="fa-regular fa-id-card"></i> Conductor:</h5>
        <div className='d-flex mb-2 mx-4 justify-content-between'>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Nombre:</label>
          <p className='mb-0 text-primary'>{conductor?.nombres ? conductor?.nombres : <i style={{ fontSize: 14, fontWeight: 400 }}><TagSinDatos/></i>}</p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Apellido:</label>
          <p className='mb-0 text-primary'>{conductor?.apellidos ? conductor?.apellidos : <i style={{ fontSize: 14, fontWeight: 400 }}><TagSinDatos/></i>}</p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Teléfono:</label>
          <p className='mb-0 text-primary'>{conductor?.phone ? conductor?.phone : <i style={{ fontSize: 14, fontWeight: 400 }}><TagSinDatos/></i>}</p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Vehículo:</label>
          <div className='d-flex justify-content-between'>
          <p className='mb-0 text-primary'>{conductor?.modelo ? conductor?.modelo : <i style={{ fontSize: 14, fontWeight: 400 }}><TagSinDatos/></i>}</p>
          <p className='mb-0 ml-2 text-primary'>{conductor?.patente ? conductor?.patente : false}</p>
          </div>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Año:</label>
          <p className='mb-0 text-primary'>{conductor?.ano ? conductor?.ano : <i style={{ fontSize: 14, fontWeight: 400 }}><TagSinDatos/></i>}</p>
        </div>
      </div>
      </div>

      } 

    //   const selectorCantidades = () => {
    //     return <select className='ml-3' style={{ fontSize: 13, padding: 5 }} value={limitPage} onChange={handleChangeCantidadPagina} >
    //             <option value="20">MOSTRAR 20</option>
    //             <option value="50">MOSTRAR 50</option>
    //             <option value="100">MOSTRAR 100</option>
    //         </select>
    // }

      const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const handleCloseTicket = (id) => {
      cerrarTiket(id)      
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()

        const tipos = {}
        types.map(t => tipos[t.value] = t.label)
        const string_length = 50

        // const handleSelectAll = () => {
        //     setSelectAll(!selectAll);
        //     if (!selectAll) {
        //         setSelectedTicket(registros.map(orden => orden._id));
        //     } else {
        //         setSelectedTicket([]);
        //     }
        // }
    
        // const handleSelectTicket = (id) => {
        //     if (selectedTicket.includes(id)) {
        //         setSelectedTicket(selectedTicket.filter(ticketId => ticketId !== id));
        //         setSelectAll(false);
        //     } else {
        //         setSelectedTicket([...selectedTicket, id]);
        //         if (selectedTicket.length + 1 === registros.length) {
        //             setSelectAll(true);
        //         }
        //     }
        // }

        if(registros.length < 1) return  false

        return <div>
            <Row>
            <Col md={6}>
            <h6>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b> </h6>
            {/* {selectorCantidades()} */}
            {paginacion(data.pagina, total)}
            </Col>
            {/* <Col md={6} className="d-flex justify-content-end align-items-end">
                {(selectAll || selectedTicket.length > 1) &&
                    <Button variant="success" className=" mb-2 w-50 shadow-sm" style={{fontSize:14}}>
                        Responder Masivo
                    </Button>
                }
                {selectedTicket.length === 1 && !selectAll && 
                    <Button variant="success" className=" mb-2 w-50 shadow-sm" style={{fontSize:14}}>
                        Responder
                    </Button>
                }
            </Col> */}
            </Row>

            <Card className='shadow-sm border-0 p-0 mb-3'>
                <div className='table-responsive'>
            <Table responsive="md" bordered hover className='mb-0 align-middle'>
                <thead className='bg-secondary text-white'>
                    <tr>
                        {/* <th>
                             <Form.Check type="checkbox" checked={selectAll} onChange={handleSelectAll} /> 
                        </th> */}
                        <th></th>
                        <th>CREADO</th>
                        <th>CREADOR</th>
                        <th>CONDICIÓN</th>
                        <th>REFERENCIA</th>
                        <th>DESTINATARIO</th>
                        <th>TICKET</th>
                        <th>DESCRIPCION</th>
                        <th>USUARIO MENSAJE</th>
                        <th>ULTIMO MENSAJE</th>
                        <th>TIPO</th>
                        <th>ESTADO</th>
                    </tr>
                </thead>
                <tbody>
                {
                    registros.map(orden => {
                        // let seleccionado = false
                        // if(idSeleccionado === orden._id) seleccionado = true
                        return <tr key={orden._id} className="align-middle">
                            {/* <td>
                                <Form.Check type="checkbox" checked={selectedTicket.includes(orden._id)} onChange={() => handleSelectTicket(orden._id)} />
                            </td> */}
                            <td className='p-3' ><Button disabled={loadingDetalle} variant='light'  onClick={() => onClickProveedor(orden._id)} size="sm" >
                             
                            { loadingDetalle === orden._id ? <Spinner size="sm" animation='border' /> : "VER" }
                            </Button> </td>
                            <td className='p-3' >{ fechaATextoSimpleCorto(orden.createdAt)}</td>
                            <td>{orden.creador?.nombres}</td>
                            <td>{backend_data.status[orden.status]}</td>
                            <td>{orden.order?.pedido}</td>
                            <td>{orden.order?.orden?.billing?.first_name}</td>
                            <td className='p-3' ><LuTicket /> {orden.id}</td>
                            <td className='p-3' >{orden.titulo.substring(0,string_length)} {orden.titulo.length >= string_length ? "..." : ""}</td>
                            <td className='p-3' >{orden.ultimo_mensaje ? orden.ultimo_mensaje?.creador?.nombres : "Sin información" }</td>
                            <td className='p-3' >{orden.ultimo_mensaje ? fechaUTCATexto(orden.ultimo_mensaje?.createdAt) : "Sin información" }</td>
                            <td className='p-3' >
                                <Badge variant="secondary" style={{ textTransform: "uppercase" }} className="mb-0">{tipos[orden.type]}</Badge>
                            </td>
                            <td className='p-3' >
                                <Badge variant={orden.open ? "success" : "dark"} style={{ textTransform: "uppercase" }} className="mb-0">{orden.open ? "ABIERTO" : "CERRADO"}</Badge>
                                {
                                  orden.open ?
                                  <Button size='sm' style={{fontSize: "10px"}} className='m-1 pr-2 pl-2 pt-0 pb-0' onClick={()=>handleCloseTicket(orden._id)}>Cerrar</Button>
                                  : false
                                }
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </Table>
            </div>
            </Card>
            {/* <BoxList proveedor={orden} seleccionado={seleccionado} onClickProveedor={(id) => onClickProveedor(id)} /> */}
        </div>
    }

      const listadoTickets = () => {
      
        return <div className='text-primary mb-2'>
        {/* {modalFiltro()} */}
        <Row >
            <Col md={12}>
            <h3 className='pt-2' style={{fontWeight:900}}><LuTicket /> Tickets</h3>
            </Col>
            </Row>
            {crearTickets()}
            <Modal show={showModalDetalle} onHide={handleCloseModalDetalle} size="lg">
                <Modal.Header closeButton className='pb-0'>
                    <Modal.Title className='text-primary' style={{fontWeight:900}}>DETALLES TICKET</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <hr className='mt-0 mb-3' />
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull 
                        id_orden={id_orden}
                        proveedor={vehiculoSeleccionado}
                        onCloseTicket={(id)=>cerrarTiket(id)}
                        />
                    }
                </Modal.Body>
            </Modal>

            {tabla_registros(proveedores)}
    </div>
      }

      const mostrarExtra = (obj) => {
        if(!obj?.extra) return false 
        return <p className="form-control-label text-primary mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
        }

        const showField = (show, element) => {
            if(show === false) return false
            if(typeof show === "undefined" ) return element
            return element
        }

        const getConfigFromFieldSlug = (slug) => {
            if(!personalizacionTracking) return true
            if(typeof personalizacionTracking !== "object") return true
            if(!personalizacionTracking.detalles) return true
            if(!personalizacionTracking.detalles?.campos) return true
            const i = personalizacionTracking.detalles.campos.findIndex(c => c.slug === slug)
            if( i < 0 ) return true
            const config = personalizacionTracking.detalles.campos[i]
            if(typeof config !== "object") return true
            return config.activo === true
        }
        const getConfigFromModuleSlug = (slug) => {
            if(!personalizacionTracking) return true
            if(typeof personalizacionTracking !== "object") return true
            if(!personalizacionTracking.detalles) return true
            if(!personalizacionTracking.detalles?.modulos) return true
            const i = personalizacionTracking.detalles.modulos.findIndex(c => c.slug === slug)
            if( i < 0 ) return true
            const config = personalizacionTracking.detalles.modulos[i]
            if(typeof config !== "object") return true
            return config.activo === true
        }
        const getConfigModuleFromSlug = (slug) => {
            if(!personalizacionTracking) return {}
            if(typeof personalizacionTracking !== "object") return {}
            if(!personalizacionTracking.detalles) return {}
            if(!personalizacionTracking.detalles?.modulos) return {}
            const i = personalizacionTracking.detalles.modulos.findIndex(c => c.slug === slug)
            if( i < 0 ) return {}
            const config = personalizacionTracking.detalles.modulos[i]
            if(typeof config !== "object") return {}
            return config
        }

        const mostrarDestinatario = (facturacion) => {

          const elemento_direccion = <Col md={4}>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Detalles dirección</label>
          <p className='text-primary'>{facturacion.address_1}</p>
          <p className='text-primary'>{facturacion.address_2}</p>
          </Col>
  
  
  
  
          const elemento_nombre = <Col md={4}>
          <label className='form-control-label text-primary mb-0' style={{ fontWeight: 700, fontSize: 14 }}>Nombre</label>
          <p className='text-primary'>{facturacion.first_name}</p>
          </Col>
  
  
  
          const elemento_email = <Col md={4}>
          <label className='form-control-label mb-0 d-block text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Email</label>
          <p className='text-primary'>{facturacion.email}</p>
          </Col>
  
  
          const elemento_movil = <Col md={4}>
          <label className='form-control-label mb-0 d-block text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Móvil</label>
          <p className='text-primary'>{facturacion.phone}</p>
          </Col>
  
          const show_direccion              = showField( getConfigFromFieldSlug("direccion"), elemento_direccion  )
          const show_nombre                 = showField( getConfigFromFieldSlug("destinatario"), elemento_nombre  )
          const show_email                  = showField( getConfigFromFieldSlug("email"), elemento_email  )
          const show_movil                  = showField( getConfigFromFieldSlug("movil"), elemento_movil  )

          if(!show_nombre && !show_email && !show_movil && !show_direccion) return false
          
          return <Card className='shadow mb-3 p-3'>
            <h5 className='mb-0 text-primary'><b>Destinatario</b></h5>
            <div >
              <Row>
            {show_nombre}
            {show_email}
            {show_movil}     
            {show_direccion}
              </Row>
            </div>
          </Card>
  
        }

      const mostrarMasInfo = (orden, facturacion) => {

        let show_module = getConfigFromModuleSlug('mas_info')
        const configuracion = getConfigModuleFromSlug('mas_info')
        if(show_module === false) return false
        
        const elemento_notas = <Col md={12} className='mb-1'>
            <hr className='mt-0' />
            <h6 className='mb-0 d-block text-primary' style={{ fontWeight: 900 }}><i class="fa-regular fa-clipboard"></i> Notas:</h6>
            {configuracion.extra}
        </Col>


        const elemento_refrigerados = <div className='d-flex align-items-center'>
        <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Refrigerados:</label>
        <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.refrigerado}</h6>
        </div>


        const elemento_bultos = <div className='d-flex align-items-center'>
        <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Secos:</label>
        <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.bultos}</h6>
        </div>


        const bultos_congelados = <div className='d-flex align-items-center'>
        <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Congelados:</label>
        <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.congelado}</h6>
        </div >
       
        const show_notas                  = showField( getConfigFromFieldSlug("notas"), elemento_notas  )
        const show_refrigerados           = showField( getConfigFromFieldSlug("bultos_refrigerados"), elemento_refrigerados  )
        const show_bultos                 = showField( getConfigFromFieldSlug("bultos"), elemento_bultos  )
        const show_congelados             = showField( getConfigFromFieldSlug("bultos_congelados"), bultos_congelados  )
        
        if(!show_bultos && !show_congelados && ! show_refrigerados) return false

        return <div className=' w-100 bg-white'>

          <div className='d-flex justify-content-between' style={{ gap: '2rem' }}>
          {/* <h5 className='text-primary' ><b>Productos</b></h5> */}
          {show_bultos}
          {show_congelados}
          {show_refrigerados}
          </div>
          <TablaProductos productos={detalle_pedido.orden.productos} viewType='simple' />
          {show_notas}
        </div>
      }

        const mostrarFooter = () => {
          let show_module = getConfigFromModuleSlug('footer')
          const configuracion = getConfigModuleFromSlug('footer')
          if(show_module === false) return false
          return <div>
            <h6>Powered By</h6>
            <img src='https://clientes.tulogistica.cl/img/logo-delpa-gris.png' style={{ width: 140 }} alt="logo-delpa" />
            {configuracion.extra}
          </div>
          } 

          
      return (
        <div>      
          {mostrarDatosPersonalizados()} 
          <div className='mt-3 text-center'>
          {mostrarFooter()}
          </div>
        </div>
      )
}

export default Tracking
