import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, Form, Table, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data, { timezone } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import { Link } from 'react-router-dom';
import { obtenerFechaHoraZonaHorariaLocal, obtenerRangos } from '../../lib/helpers/dates';
import BuscadorVehiculos from '../conductores/buscador';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../lib/helpers/router';
import { useLocation, useNavigate } from 'react-router-dom'
import { fechaATexto, fechaATextoSimple } from '../../lib/helpers/helpers';
import { getDireccion1, getEmail, getNombreDestinatario, getTelefono, obtenerGeoValor } from '../../lib/helpers/pedidos/pedidos';
import { Esquema } from '../../lib/esquemas/tablas';
import { DateTime } from 'luxon';
import Filtros from './filtros';
import { AiOutlineMenu, AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai'
import { estilo_last_mile } from '../../lib/global/styles';
import SelectorZonas from '../geodata/selector_zonas';
import SelectorZonaAvanzadoFiltro from '../../components/RadioOperacion/selector_zona_avanzado_filtro';
import PersonalizarColumnas from '../interfaz/columnas_personalizar';
import { encontrarLlaveValorEnEsquema } from '../../lib/helpers/interfaz';
import { FiSettings } from 'react-icons/fi'
import { BiArrowBack } from 'react-icons/bi'
import SelectorCamposPersonalizados from '../campos-personalizados/selector';
import { modificarEsquema } from '../../redux/actions/esquemaTablas';
import { AiFillEyeInvisible } from 'react-icons/ai'
import { cerrarSesion } from '../../redux/actions/session';
import TablaOrdenesStatic from './visual/tabla_registros_static';
import SelectorDestinatarios from '../destinatarios/selector';
import LoadingOrders from '../general/loadingAnimations/loadingOrders';
import HelperModal from '../general/helperModal';
import TiposServicioSelector from '../ordenes/tipos_servicio/selector';
import { CiBarcode } from 'react-icons/ci';
import { FaPrint, FaRegTrashAlt } from 'react-icons/fa';
import ModalSelectorRutas from '../rutas/modal_seleccion';
import CotizadorOrdenes from '../rutas/importador/cotizador_ordenes';
import ErrorBoundary from '../errorsHandler/errorBoundary';

const OrdenesListadoV3 = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const {
        hideHeader,
        condicion_default,
        refresh,
        hideTitle,
        typeView,
        flow,
        acciones
    } = props
    const [ error, setError ] = useState('')
    const [ equipos, setEquipos ] = useState([])
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ idsQuotatinos, setIdsQuotations ] = useState([])
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ showModalEtiquetas, setShowModalEtiquetas ] = useState(false)
    const [ showModalQuotation, setShowModalQuotation ] = useState(false)
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ pedidoSeleccionado, setPedidoSeleccionado ] = useState(false)
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ clienteBusqueda, setClienteBusqueda ] = useState('')
    const [ pedidoBusqueda, setPedidoBusqueda ] = useState('')
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ showModalBusquedaAvanzada, setShowModalBusquedaAvanzada ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const [ incluirGestionadas, setIncluirGestionadas ] = useState(false)
    const [ filtroZona, setFiltroZona ] = useState(false)
    const [ tipoCondicion, setTipoCondicion ] = useState('')
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const [ estados, setEstados ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ incluyeEstadoExcel, setIncluyeEstadoExcel ] = useState(false)
    const [ checlBoxIds, setCheckBoxIds ] = useState([])
		const [ conductor, setConductor ] = useState(false)
		const [ tipoServicio, setTipoServicio ] = useState(false)
    const hide_title = typeof hideTitle !== "undefined" ? hideTitle : false
    const showFilter = typeof props.showFilter !== "undefined" ? props.showFilter : true
    const showSearch = typeof props.showSearch !== "undefined" ? props.showSearch : true
    const showExporter = typeof props.showExporter !== "undefined" ? props.showExporter : true
    const showAddButton = typeof props.showAddButton !== "undefined" ? props.showAddButton : true
    const upWhenClick = typeof props.upWhenClick !== "undefined" ? props.upWhenClick : true
    const interfaz_usuario = useSelector(state => state.esquema_tablas)
    const titulo = typeof props.titulo !== "undefined" ? props.titulo : "Envíos"
    const screen = "ordenes"
    const dispatch = useDispatch()
    let query = useQuery();
    const location = useLocation()
    const navigate = useNavigate();
    let default_key = 'apikeys'
    if(query.get("tab")) default_key = query.get("tab")


    const obtenerOrdenes = async (page, query, tipo_condicion)=>{
        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        let condicion = query ? query : condicion_busqueda

        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                tipo_condicion: tipo_condicion ? tipo_condicion : tipoCondicion,
                filtro_zona: filtroZona ? filtroZona._id : false,
                pagina: page,
                gestionadas: incluirGestionadas,
                acciones
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }
    
    const obtenerOrdenesUrl = async (page, query, tipo_condicion) => {
        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        const currentUrl = new URL(window.location.href);    
        const search_url = currentUrl.search
        let condicion = query ? query : condicion_busqueda
        
        // Parsear la cadena de consulta (query string) a un objeto
        const queryParams = new URLSearchParams(search_url);
        console.log("queryParams", queryParams )
        const tiposervicio = queryParams.get("tiposervicio");
        const filtro_zona = queryParams.get("filtro_zona");
        const fecha = queryParams.get("fecha");
        const desde = queryParams.get("desde");
        const hasta = queryParams.get("hasta");


        if(tiposervicio) condicion.tiposervicio = tiposervicio
        if(fecha === "creacion") condicion.createdAt = {$gte: desde,$lte: hasta}
        if(fecha === "actualizacion") condicion.updatedAt = {$gte: desde,$lte: hasta}
        if(fecha === "maxima-entrega") condicion.$or = [{fecha_min_entrega: {$lte: desde}}, {fecha_max_entrega: {$gte: desde,$lte: hasta}, fecha_min_entrega: {$lte: desde}}, {fecha_max_entrega: {$gte: hasta}, fecha_min_entrega: {$lte: desde}} ]

        // Crear un objeto con las propiedades deseadas
        let requestBody = {
        filtro_zona
        };

        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                tipo_condicion: tipo_condicion ? tipo_condicion : tipoCondicion,
                filtro_zona: requestBody.filtro_zona ? requestBody.filtro_zona : false,
                pagina: page,
                gestionadas: incluirGestionadas,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            // if(res.id_geo !== undefined){
            //     setFiltroZona(res.id_geo)
            // }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }
    
    const obtenerOrdenesAvanzado = async (valor, key_campo)=>{
        setLoadingEquipos(true)
        return fetch(`${data.urlapi}/ordenes/list/campo-personalizado`,{
            method:'POST',
            body: JSON.stringify({
                valor,
                key_campo,
                pagina: 1
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const obtenerPedido = async (id) => {
        setLoadingDetalle(true)
        if(upWhenClick === true) window.scrollTo({top: 0, behavior: 'smooth'})
        setIdSeleccionado(id)
        return fetch(`${data.urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                setPedidoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerOrdenes(page)
    }

    const handleClose = () => {
        setOpenModal(false)
        setShowModalBusquedaAvanzada(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const handleChangeFechaBusqueda = (e) => {
        const { value } = e.target
        const newFecha = value;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('fecha', newFecha);
        window.history.pushState({}, '', currentUrl.toString())
        return setTipoFechaBusqueda(value)
    }

    const handleChangeTipoServicio = (data) => {
        const newService = data._id;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('tiposervicio', newService);
        window.history.pushState({}, '', currentUrl.toString())
        return setTipoServicio(data);
      }

    const handleChangeZona = (data) => {
        const newZona = data._id;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('filtro_zona', newZona);
        window.history.pushState({}, '', currentUrl.toString())
        return setFiltroZona(data);
      }

    const filtrar = () => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        const fechaDesde = desde;
        console.log("desde", fechaDesde)
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('desde', fechaDesde);
        window.history.pushState({}, '', currentUrl.toString())
        const fechaHasta = hasta;
        console.log("hasta", fechaHasta)
        currentUrl.searchParams.set('hasta', fechaHasta);
        window.history.pushState({}, '', currentUrl.toString())
			
			  
        if(tipoFechaBusqueda === 'creacion'){
            const condicion = {createdAt: { $gte: desde, $lte: hasta }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            if (tipoServicio) condicion.tiposervicio = tipoServicio._id
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            console.log("entro en crear")
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = {updatedAt: { $gte: desde, $lte: hasta }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            if (tipoServicio) condicion.tiposervicio = tipoServicio._id
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            console.log("entro en actalizar")
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            const condicion = { $or:[
                { fecha_min_entrega: { $gte: desde, $lte: hasta }, fecha_min_entrega: { $lte: desde }},
                {fecha_max_entrega: { $lte: hasta, $gte: desde }, fecha_min_entrega: { $lte: desde }},
                {fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde} }               
            ]
            }
            if (tipoServicio) condicion.tiposervicio = tipoServicio._id
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
					  if (conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            console.log("entro en maxi")
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'todos'){
            let condicion = { }
            if(tipoServicio) condicion.tiposervicio = tipoServicio._id
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if (conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            console.log("entro en todos")
            return setOpenModal(false)
        }
        const condicion = { }
        if (tipoServicio) condicion.tiposervicio = tipoServicio._id
        console.log("entro a fuera")
        obtenerOrdenes(1, condicion)
    }

    const handleCheck = (e) => {
        const valor = e.target.checked
        return setIncluyeEstadoExcel(valor)
    }
    
    const onChangeConductor = (e) => {
        setConductor(e)
    }
    const onChangeTipoServicio = (e) => {
        console.log('tipos servicio', e);
        //orden.tiposervicio = e._id
        //return setOrden(orden)
    }

    const handleChangeGestionadas = (e) => {
        setIncluirGestionadas(e.target.checked)
    }

    const modalBusquedaAvanzada = () => {
        const sub_estados = []
        return <Modal show={showModalBusquedaAvanzada} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona el campo con el cual deseas buscar</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form.Group className="mb-1" controlId={"gestionadas"}>
                <Form.Check type="switch" checked={incluirGestionadas} name="gestionadas" label="Incluir órdenes gestionadas" onChange={handleChangeGestionadas} />
            </Form.Group>

            <SelectorCamposPersonalizados onChange={(data) => {
                const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario))
                instancia_esquema.campo_busqueda = data
                dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
                setCampoBusqueda(data)
            }} titulo="Selecciona el campo por el cual deseas buscar" extraOptions={[ { value: "", label: "Referencia" } ]} />

            

                                        
            <Button variant='link-dark' className='w-100' size="sm" onClick={() => handleClose()} ><BiArrowBack /> VOLVER</Button>
        </Modal.Body>
      </Modal>
    }

    const modalFiltro = () => {
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row>
                <Col xs={12}>
                <SelectorZonaAvanzadoFiltro condicion={{ local_name: "region" }} onChangeValue={(data) => handleChangeZona(data)} /> 
                </Col>
                <Col xs={12}>
                    <label className='form-control-label'>Tipo de logística</label>
                    <TiposServicioSelector defaultValue={tipoServicio} onChangeValue={(data) => handleChangeTipoServicio(data)
                        } />
                </Col>
                <Col xs={12}>
                    <SelectorDestinatarios defaultValue={condicion_busqueda.id_cliente ? condicion_busqueda.id_cliente : ""} titulo="Cliente" onChange={handleChangeCliente} />
                </Col>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control' value={tipoFechaBusqueda} onChange={handleChangeFechaBusqueda}  >
                        <option value="creacion">Creación</option>
                        <option value="actualizacion">Actualización</option>
                        <option value="maxima-entrega">Máxima fecha entrega</option>
                        <option value="todos">Cualquiera</option>
                    </select>
                </Col>
                
                {
                    tipoFechaBusqueda !== "todos" ? <Col xs={12} className="mb-2">
                    <DateRangePicker
                        locale={es}
                        showSelectionPreview={true}
                        editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        months={1}
                    />
                    </Col> : false
                }
                
                <Col md={12}>
                    <Button size="sm" variant="success" onClick={()=>filtrar()} >ACTUALIZAR DATOS</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total, registros) => {

        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Row>
                <Col xs={12}><h5 className='m-0' style={{ fontSize:13 }}>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5></Col>
                <Col xs={12}>
                <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
                </Col>
            </Row>
        </div>
    }

    const obtenerEstados = () => {
        return fetch(`${data.urlapi}/estadoscarga/details`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                setError(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data.estados) !== false){
                setEstados(data.estados.map(e => ({ value: e.codigo_estado, label: e.titulo })))
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            setError("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    useEffect(() => {
       
const condicion = condicion_default ? condicion_default : {}
const currentUrl = new URL(window.location.href);        
        if(currentUrl.search ) {
            return obtenerOrdenesUrl(1, condicion)
        } else {
        console.log("condicion", condicion)
        obtenerOrdenes(1, condicion)
        obtenerEstados()
        const id_pedido = query.get("id")
        if(id_pedido){
            obtenerPedido(id_pedido)
        }
    }}, [ refresh ]) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <LoadingOrders />
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const descargarReporteExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reporte/ordenes`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda,
                incluir_estados: incluyeEstadoExcel
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const esimacionFechas = (orden) => {
        if(!orden) return false

        const minimo    = DateTime.fromISO(orden.fecha_min_entrega)
        const maximo    = DateTime.fromISO(orden.fecha_max_entrega)
        const hoy       = DateTime.now().setZone(timezone)
        
        if(!orden.fecha_finalizado){
            if(maximo < hoy) return false
        }

        const finalizado = DateTime.fromISO(orden.fecha_finalizado)

        if(finalizado > maximo) return false

        return true
    }
    const mostrarConductor = (orden) => {
        if(!orden.conductor) return false
        if(typeof orden.conductor !== "object") return false
        return orden.conductor.nombres
    }

    const mostrarRuta = (orden) => {
        if(!orden.ruta) return false
        if(typeof orden.ruta !== "object") return false
        return orden.ruta.id
    }

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[screen].findIndex(e => e.key === column.key)
        if(i > -1){
            instancia_esquema[screen][i].active = value
        } else {
            instancia_esquema[screen].push({
                ...column,
                active: value
            })
        }
        return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
    }

    const verificarCheckBoxes = (e) => {
        return setTimeout(() => {
            const list = document.querySelectorAll('input[type=checkbox]:checked');
            let ids = []
            for (const checkbox of list) {
                if(checkbox.id.length < 24) continue
                ids.push(checkbox.id)
            }
            setCheckBoxIds(ids)
            return ids
        }, 100);
      }

    const deseleccinoarTodos = (query) => {
        const list = document.querySelectorAll(`${query} input[type=checkbox]`);
            for (const checkbox of list) {
            checkbox.checked = false;
            }
            verificarCheckBoxes()
      }

      const seleccinoarTodos = (query) => {
        const list = document.querySelectorAll(`${query} input[type=checkbox]`);
            for (const checkbox of list) {
            checkbox.checked = true;
            }
            verificarCheckBoxes()
      }

      const onConfirmation = (manifest) => {
        if(checlBoxIds.length < 1) return setError("Debes seleccionar al menos un pedido")
        gestionarPedidos(['asign-manifest'], { id_ruta: manifest.value })
      }

      const mostrarAcciones = () => {
        if( flow === "generar-etiquetas"){
            return <ButtonGroup className='mb-3'>
                <Button disabled={loadingAcciones} variant="light" onClick={() => eliminarTodos()}><FaRegTrashAlt style={{ verticalAlign: "middle" }} /> Eliminar todos</Button>
                <Button disabled={loadingAcciones} variant="light" onClick={() => gestionarPedidos(['generate-shipment'])}><CiBarcode style={{ verticalAlign: "middle" }} /> Generar etiquetas</Button>
            </ButtonGroup>
        } else if( flow === "imprimir-etiquetas"){
            return <ButtonGroup className='mb-3'>
                    <Button disabled={loadingAcciones} variant="light" onClick={() => eliminarTodos()}><FaRegTrashAlt style={{ verticalAlign: "middle" }} /> Eliminar todos</Button>
                    <Button disabled={loadingAcciones} variant="light" onClick={() => gestionarPedidos(['confirm-print'])}><FaPrint style={{ verticalAlign: "middle" }} /> Imprimir etiquetas</Button>
            </ButtonGroup>
        } else if( flow === "preparar-manifiesto"){
            return <ButtonGroup className='mb-3'>
                    <Button disabled={loadingAcciones} variant="light" onClick={() => eliminarTodos()}><FaRegTrashAlt style={{ verticalAlign: "middle" }} /> Eliminar todos</Button>
                    {/* <Button disabled={loadingAcciones} variant="light" onClick={() => gestionarPedidos(['asign-manifest'])}><FaPrint style={{ verticalAlign: "middle" }} /> Asignar Manifiesto</Button> */}
                    <ModalSelectorRutas typeView="selector" onConfirmation={(data) => onConfirmation(data)} />
            </ButtonGroup>
        }
      }
    
    const tablaRegistros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()

        if(registros.length < 1) return <div style={{ textAlign: "center" }}><HelperModal tipo="ordenes" style="simple" /></div>

        if(typeof typeView !== "undefined") return <TablaOrdenesStatic typeView={typeView} ordenes={registros} />

        return <Card>
            <div className='p-2'>
            
            {paginacion(data.pagina, total, registros)}
            <Row>
                <Col >
                <ButtonGroup className='mb-3'>
                    <Button variant="light" onClick={() => seleccinoarTodos(`.ordenes`)}>{}Seleccionar todos</Button>
                    <Button variant="light" onClick={() => deseleccinoarTodos(`.ordenes`)}>Deseleccionar todos</Button>
                </ButtonGroup>
                </Col>
                <Col >
                {mostrarAcciones()}
                </Col>
            </Row>
            </div>
        <div className='table-responsive'>
            <Table className='ordenes'>
            <thead>
                <tr style={{ backgroundColor: "#f2f1f1", textTransform: "uppercase", color: "#8c8c8c" }}>
                    <td>
                    <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    className={`ordenes`}
                                                    onChange={(e) => {
                                                        const { checked } = e.target
                                                        if(checked === true){
                                                            return seleccinoarTodos(`.ordenes`)
                                                        } else {
                                                            return deseleccinoarTodos(`.ordenes`)
                                                        }
                                                    }}
                                                />
                    </td>
                    {
                        Esquema.ORDENES.map(esq => {
                            const mostrar = encontrarLlaveValorEnEsquema(esq.key, interfaz_usuario.ordenes) === false ? false : true
                            return mostrar === true ? <td style={{ border: "none" }}>{esq.label} 
                            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Toca para ocultar esta columna de la vista</Tooltip>
                            }
                            >
                            <AiFillEyeInvisible onClick={() => activarDesactivarTabla(false, esq)} className='hover ml-2' style={{ fontSize: 15 }} />
                            </OverlayTrigger>
                             </td> : false
                        })
                    }
                </tr>
            </thead>
            <tbody>
        {
            registros.map(orden => {

                let titulo_estado           = ''
                let titulo_sub_estado       = ''
                let color_estado            = '#bebebe'

                if(orden.estado_entrega){
                    if(typeof orden.estado_entrega === 'object'){
                        if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                        if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                    }
                }
            
                if(orden.sub_estado_entrega){
                    if(typeof orden.sub_estado_entrega === 'object'){
                        if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                    }
                }

                let string_estado = `${titulo_estado} ${titulo_sub_estado}`
                if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"

                const a_tiempo = esimacionFechas(orden)
                return <tr key={orden._id} className="mb-3">
                    <td>
                    <Form.Check
                                                    inline
                                                    name={orden._id}
                                                    type="checkbox"
                                                    className={`pedidos`}
                                                    id={orden._id}
                                                    onChange={verificarCheckBoxes}
                                                />
                    </td>
                        { encontrarLlaveValorEnEsquema("pedido", interfaz_usuario.ordenes) === false ? false : <td style={{ fontWeight: "bold", fontSize: 17 }}><Link to={`/ordenes/${orden._id}`}>{orden.pedido}</Link> </td> }
                        { encontrarLlaveValorEnEsquema("tipo_servicio", interfaz_usuario.ordenes) === false ? false : <td>{orden.tipo_servicio}</td> }
                        { encontrarLlaveValorEnEsquema("status", interfaz_usuario.ordenes) === false ? false : <td><p style={{ fontSize:9, fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado.toUpperCase()}</p></td> }
                        { encontrarLlaveValorEnEsquema("destinatario", interfaz_usuario.ordenes) === false ? false : <td>{getNombreDestinatario(orden)}</td> }
                        { encontrarLlaveValorEnEsquema("phone", interfaz_usuario.ordenes) === false ? false : <td>{getTelefono(orden)}</td> }
                        { encontrarLlaveValorEnEsquema("email", interfaz_usuario.ordenes) === false ? false : <td>{getEmail(orden)}</td> }
                        { encontrarLlaveValorEnEsquema("direccion", interfaz_usuario.ordenes) === false ? false : <td>{getDireccion1(orden)}</td> }
                        { encontrarLlaveValorEnEsquema("volumen", interfaz_usuario.ordenes) === false ? false : <td>{orden.volumen}</td> }
                        { encontrarLlaveValorEnEsquema("peso", interfaz_usuario.ordenes) === false ? false : <td>{orden.peso}</td> }
                        { encontrarLlaveValorEnEsquema("bultos", interfaz_usuario.ordenes) === false ? false : <td>{orden.bultos}</td> }
                        { encontrarLlaveValorEnEsquema("ruta", interfaz_usuario.ordenes) === false ? false : <td>{mostrarRuta(orden)}</td> }
                        { encontrarLlaveValorEnEsquema("compromiso", interfaz_usuario.ordenes) === false ? false : <td style={{ color: a_tiempo ? estilo_last_mile.color_success : estilo_last_mile.color_danger }}>{a_tiempo ? <AiFillCheckCircle /> : <AiFillClockCircle />} {a_tiempo ? "A TIEMPO" : "RETRASADA"}</td> }
                        { encontrarLlaveValorEnEsquema("fecha_compromiso_desde", interfaz_usuario.ordenes) === false ? false : <td>{fechaATextoSimple(orden.fecha_min_entrega)}</td> }
                        { encontrarLlaveValorEnEsquema("fecha_compromiso_hasta", interfaz_usuario.ordenes) === false ? false : <td>{fechaATextoSimple(orden.fecha_max_entrega)}</td> }
                        { encontrarLlaveValorEnEsquema("fecha_entregado", interfaz_usuario.ordenes) === false ? false : <td>{orden.fecha_finalizado ? fechaATextoSimple(orden.fecha_finalizado) : "NO DISPONIBLE"}</td> }
                        { encontrarLlaveValorEnEsquema("conductor", interfaz_usuario.ordenes) === false ? false : <td>{mostrarConductor(orden)}</td> }
                        { encontrarLlaveValorEnEsquema("conductor_asignado", interfaz_usuario.ordenes) === false ? false : <td>{orden.idconductor ? "SI" : "NO"}</td> }
                        { encontrarLlaveValorEnEsquema("visible", interfaz_usuario.ordenes) === false ? false : <td>{orden.show_on_app ? "SI" : "NO"}</td> }
                        { encontrarLlaveValorEnEsquema("finalizado", interfaz_usuario.ordenes) === false ? false : <td>{orden.finalizado ? "SI" : "NO"}</td> }
                        { encontrarLlaveValorEnEsquema("pais", interfaz_usuario.ordenes) === false ? false : <td>{obtenerGeoValor(orden, 'pais')}</td> }
                        { encontrarLlaveValorEnEsquema("region", interfaz_usuario.ordenes) === false ? false : <td>{obtenerGeoValor(orden, 'level1')}</td> }
                        { encontrarLlaveValorEnEsquema("comuna", interfaz_usuario.ordenes) === false ? false : <td>{obtenerGeoValor(orden, 'level3')}</td> }
                        { encontrarLlaveValorEnEsquema("fecha", interfaz_usuario.ordenes) === false ? false : <td>{fechaATexto(orden.createdAt)}</td> }
                    </tr>
                })
        }
        </tbody>
        </Table>
        </div>
        <div className='p-2'>{paginacion(data.pagina, total, registros)}</div>
        </Card>
    }

    const listadoOrdenes = (registros) => {

        return <div>
            { /** <Joyride styles={{options:virtual_tour_settings.estilo}} continuous={true} showProgress={true} steps={virtual_tour_settings.pasos_ordenes} locale={virtual_tour_settings.locale} /> */ }
                    {buscador()}
            
            {tablaRegistros(registros)}
        </div>
    }

    const eliminarTodos = async () => {
        setLoadingAcciones(true)
        return fetch(`${data.urlapi}/ordenes/masive-delete`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: checlBoxIds
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res.success){
                window.location.reload()
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }

    const gestionarPedidos = async (acciones, datos) => {
        setLoadingAcciones(true)
        return fetch(`${data.urlapi}/ordenes/confirm-labels`,{
            method:'POST',
            body: JSON.stringify({
                ids: checlBoxIds,
                acciones,
                data: datos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res.success){
                if(acciones){
                    if(acciones.includes('confirm-print')){
                        if([]) {
                            setEtiquetas([])
                            setShowModalEtiquetas(true)
                        }
                    } else if(acciones.includes('generate-shipment')){
                        toast.success('Etiquetas generadas correctamente')
                        window.location.reload()
                    } else if(acciones.includes('asign-manifest')){
                        toast.success('Manifiesto asignado correctamente')
                        window.location.reload()
                    }
                } else {
                    window.location.reload()
                }
            } else if(res.action){
                if(res.action === "quotation"){
                    setIdsQuotations(res.ids)
                    setShowModalQuotation(true)
                }
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSubmitReferencia = (e) => {
        e.preventDefault()
        if(!pedidoBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerOrdenes(1, condicion)
        }

        if(interfaz_usuario.campo_busqueda){
            if(typeof interfaz_usuario.campo_busqueda === "object" ){
                if(interfaz_usuario.campo_busqueda.value){
                    setPagina(1)
                    obtenerOrdenesAvanzado(pedidoBusqueda, interfaz_usuario.campo_busqueda.value)
                }
            }
        }


        const condicion = { pedido: pedidoBusqueda.toLowerCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const handleSubmitNumeroInterno = (e) => {
        e.preventDefault()
        if(!numeroInterno) return setError('Selecciona una patente')
        const condicion = { numero_interno: numeroInterno.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerOrdenes(1, {})
    }

    const abrirFiltro = () => {
        // const currentUrl = new URL(window.location.href)
        // if(currentUrl.search) borrarFiltrosUrl()
        return setOpenModal(true)
    }

    const handleChangeCliente = (e) => {
        if(!e) if(condicion_busqueda.id_cliente) delete condicion_busqueda.id_cliente
        if(typeof e !== "object") if(condicion_busqueda.id_cliente) delete condicion_busqueda.id_cliente
        if(!e.value) if(condicion_busqueda.id_cliente) delete condicion_busqueda.id_cliente
        if(e.value) condicion_busqueda.id_cliente = e.value
        console.log(condicion_busqueda)
        return setCondicionBusqueda(condicion_busqueda)
    }
    const handleChangePedido = (e) => {
        const { value } = e.target
        return setPedidoBusqueda(value)
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {
        const i = equipos.findIndex(pe => pe._id === pedidoSeleccionado.orden._id)
        if(i < 0) return 
        equipos[i].estado_entrega = estado_entrega
        equipos[i].sub_estado_entrega = sub_estado_entrega
        return setEquipos(prev => [...[], ...equipos])
    }

    const onFilter = (nueva_condicion, tipo_condicion) => {
        setCondicionBusqueda(nueva_condicion)
        setPagina(1)
        setTipoCondicion(tipo_condicion)
        obtenerOrdenes(1, nueva_condicion, tipo_condicion)
    }

    const borrarFiltrosUrl = () => {
        // Obtén la URL actual
        const currentUrl = new URL(window.location.href);
        
        // Crea una nueva URL sin parámetros de búsqueda
        const newUrl = new URL(currentUrl.origin + currentUrl.pathname);
        
        // Actualiza la URL en el historial del navegador sin recargar la página
        window.history.pushState({}, '', newUrl.toString());
        toast.success('Se Limpiaron los filtros de la URL para una nueva consulta')
        return false
            }

    const abrirModalBusquedaAvanzada = () => {
        setShowModalBusquedaAvanzada(true)
    }
   
    
    const buscador = () => {
        return showSearch === true ? <Row> 
            <Col md={3} className='mb-2'>
                <form onSubmit={handleSubmitReferencia}>
                <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Toca para configurar tu campo de búsqueda</Tooltip>
                            }
                            >
                <div style={{ position: "absolute", right: 26, top: 1 }} className='hover' onClick={() => abrirModalBusquedaAvanzada()} ><FiSettings /></div>
                </OverlayTrigger>
                <input style={{ height: 30, padding: "2px 10px", fontSize: 13 }} className='mb-3 w-100 delpa-buscador' placeholder={`BUSCAR POR ${interfaz_usuario.campo_busqueda ? interfaz_usuario.campo_busqueda.label.toUpperCase() : "REFERENCIA"}`} onChange={handleChangePedido} />
                </form>
            </Col>
            <Col md={2} className='mb-2'>
               <PersonalizarColumnas screen="ordenes" />
            </Col>
            { showFilter === true ? <Col md={2} className="text-right mb-2"><Button size='sm' className='w-100' variant="light" onClick={()=>abrirFiltro()}><AiOutlineMenu className='mr-3' />MÁS FILTROS</Button> </Col> : false }
            {
                showExporter === true ? <Col md={5} className='mb-2'>
                    <ButtonGroup className='w-100'>
                    <Button size="sm" variant='light' disabled={loadingExcel} className='w-100 mb-2' onClick={() => descargarReporteExcel()} ><i className="fa-solid fa-file-excel"></i>  REPORTE</Button>
                    <Button size="sm" variant='light' className='w-100 mb-2' onClick={() => setIncluyeEstadoExcel( incluyeEstadoExcel === true ? false : true )} ><i className="fa-solid fa-file-excel"></i> REPORTE AVANZADO</Button>
                    </ButtonGroup>
                </Col> : false
            }
<Col md={3} className='mb-3'>
    <Select 
        style={{ marginBottom: 10 }}
        onChange={handleChangeSelectEstado}
        isLoading={loadingEstados}
        isMulti={true}
        options={estados}
        defaultValue={''}
        placeholder="Filtrar por estados..."
        noOptionsMessage={()=>'Sin opciones'}
        />
</Col>
{/* <Col md={4} className='mb-3'>
    <BuscadorVehiculos placeholderText="Filtra por vehículos" hideLabel={true} isMulti={true} token={token} onChange={(data) => onChangeConductor(data)} />
</Col> */}
<Col md={3}>
       <OverlayTrigger
         placement={'top'}
         overlay={
           <Tooltip>Elimina los filtros de la Url</Tooltip>
         }>
            <Button variant="primary" className='delpa-filtro shadow-sm w-100' onClick={()=>borrarFiltrosUrl()}>LIMPIAR FILTROS</Button>
           </OverlayTrigger>
</Col>
<Col md={2} className='mb-3'>
    <Button  className='w-100' variant="dark" onClick={()=>filtrar()} >ACTUALIZAR</Button>
</Col>
        </Row> : false
    }

    const modalCotizacion = () => {

        const ordenes = idsQuotatinos.map(id => {
            const i = equipos.findIndex(e => e._id === id)
            if(i > -1) return equipos[i]
            return false
        }).filter(e => e)
        return <div>
            <Modal show={showModalQuotation} onHide={()=>setShowModalQuotation(false)} size="lg" centered>
            <Modal.Header closeButton>
                    <Modal.Title><FaPrint /> Cotiza las órdenes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Las siguientes órdenes aún no tienen una tarifa confirmada</p>
                    <Table >
                        <thead>
                            <tr>
                                <th>REF</th>
                                <th>DESTINATARIO</th>
                                <th>TELÉFONO</th>
                                <th>EMAIL</th>
                                <th>DIRECCIÓN</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            ordenes.map(orden => {
                                
                                return <tr key={`q-${orden._id}`}>
                                    <th>{orden.pedido}</th>
                                    <th>{getNombreDestinatario(orden)}</th>
                                    <th>{getTelefono(orden)}</th>
                                    <th>{getEmail(orden)}</th>
                                    <th>{getDireccion1(orden)}</th>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                    <CotizadorOrdenes records={ordenes} />
                </Modal.Body>
            </Modal>
        </div>

    }

    const modalEtiquetas = () => {
        return <div>
            <Modal show={showModalEtiquetas} onHide={()=>setShowModalEtiquetas(false)} size="lg" centered>
            <Modal.Header closeButton>
                    <Modal.Title><FaPrint /> Imprimir Etiquetas</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </div>
    }

    const headerBox = () => {
        if(hideHeader === true ) return false
        return  <Filtros onFilter={(filtro, tipo) => onFilter(filtro, tipo)} />
    }

    const tituloPagina = hide_title === true ? false : <h4>{titulo} { showAddButton === true ? <Link to="/rutas/crear" className='delpa-nueva-orden'><i className="fa-solid fa-circle-plus"></i></Link> : false } </h4>
 
    return <div>
        <HelperModal tipo="ordenes" style="modal" />
        <ErrorBoundary message={error}/>
        {modalFiltro()}
        {modalBusquedaAvanzada()}
        {/* {headerBox()} */}
        {tituloPagina}
        {listadoOrdenes(equipos)}
        {modalEtiquetas()}
        {modalCotizacion()}
    </div>

}

export default OrdenesListadoV3