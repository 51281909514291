import React, { useState } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const ConditionalEditor = () => {
  const [state, setState] = useState({
    default_value: '',
    conditions: [],
  });

  const [ showModal, setShowModal] = useState(false);
  const [ show, setShow ] = useState(false);
  const [currentConditionIndex, setCurrentConditionIndex] = useState(null);
  const [tempCompareValue, setTempCompareValue] = useState('');

  const equals = {
    equals: "Igual a",
    not_equals: "Diferente a",
    greater_than: "Mayor que",
    less_than: "Menor que",
  };

  const addCondition = () => {
    setState((prevState) => ({
      ...prevState,
      conditions: [
        ...prevState.conditions,
        { originValue: '', operator: '', compareValues: [], action: '', textValue: '' },
      ],
    }));
  };

  const updateCondition = (index, field, value) => {
    const updatedConditions = [...state.conditions];
    if (updatedConditions[index]) {
      updatedConditions[index][field] = value;
      setState((prevState) => ({
        ...prevState,
        conditions: updatedConditions,
      }));
    }
  };

  const removeCondition = (index) => {
    setState((prevState) => ({
      ...prevState,
      conditions: prevState.conditions.filter((_, i) => i !== index),
    }));
  };

  const handleDefaultValueChange = (e) => {
    setState({ ...state, default_value: e.target.value });
  };

  const openModal = (index) => {
    if (state.conditions[index]) {
      setCurrentConditionIndex(index);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentConditionIndex(null);
    setTempCompareValue('');
  };

  const addCompareValue = () => {
    if (tempCompareValue.trim() === '') return;
    const updatedConditions = [...state.conditions];
    if (currentConditionIndex !== null && updatedConditions[currentConditionIndex]) {
      updatedConditions[currentConditionIndex].compareValues.push(tempCompareValue.trim());
      setState((prevState) => ({
        ...prevState,
        conditions: updatedConditions,
      }));
    }
    setTempCompareValue('');
  };

  const removeCompareValue = (conditionIndex, valueIndex) => {
    const updatedConditions = [...state.conditions];
    if (updatedConditions[conditionIndex]) {
      updatedConditions[conditionIndex].compareValues = updatedConditions[
        conditionIndex
      ].compareValues.filter((_, i) => i !== valueIndex);
      setState((prevState) => ({
        ...prevState,
        conditions: updatedConditions,
      }));
    }
  };

  const mostrarCondiciones = () => {
    if(!state.conditions) return null
    if(state.conditions.length < 1) return null
    return  <div className="mt-4 shadow p-3 card">
    {state.conditions.map((condition, index) => (
      <div key={index} className="mb-3">
        { index > 0 && <hr /> }
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Operador</Form.Label>
              <select
                className="form-control"
                value={condition.operator}
                onChange={(e) => updateCondition(index, 'operator', e.target.value)}
              >
                <option value="">Selecciona un operador</option>
                <option value="equals">{equals["equals"]}</option>
                <option value="not_equals">{equals["not_equals"]}</option>
                <option value="greater_than">{equals["greater_than"]}</option>
                <option value="less_than">{equals["less_than"]}</option>
              </select>
            </Form.Group>
          </Col>
          <Col>
          <Form.Group>
              <Form.Label>Valor</Form.Label>
            <Button
              variant="secondary"
              className="w-100"
              onClick={() => openModal(index)}
            >
              DEFINIR
            </Button>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Acción</Form.Label>
              <select
                className="form-control"
                value={condition.action}
                onChange={(e) => updateCondition(index, 'action', e.target.value)}
              >
                <option value="">Acción</option>
                <option value="change_value">Cambiar valor</option>
                <option value="stop_action">Detener acción</option>
              </select>
            </Form.Group>
          </Col>
          {condition.action === 'change_value' && (
            <Col>
              <Form.Group>
                <Form.Label>Nuevo Valor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="New Value"
                  value={condition.textValue}
                  onChange={(e) => updateCondition(index, 'textValue', e.target.value)}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        <Button
              variant="outline-danger"
              size="sm"
              className="mt-1 mb-0"
              onClick={() => removeCondition(index)}
            >
              Eliminar Condición
            </Button>
      </div>
    ))}
  </div>
  }

  const mostrarFormulario = () => {
    if(!show) return null

    return <div>
      <Form>
        <Form.Group controlId="defaultValue" className='mt-3' >
          <Form.Label>Valor por defecto</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingresa un valor por defecto en caso de que no venga en el origen de los datos"
            value={state.default_value}
            onChange={handleDefaultValueChange}
          />
        </Form.Group>
        <div className="mt-3">
          <Button onClick={addCondition} variant="primary">
            AGREGAR CONDICIÓN
          </Button>
        </div>
      </Form>

     {mostrarCondiciones()}

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Valores de Comparación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentConditionIndex !== null && (
            <>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Agregar valor"
                  value={tempCompareValue}
                  onChange={(e) => setTempCompareValue(e.target.value)}
                />
              </Col>
              <Col xs={4}>
              <Button className="w-100" onClick={addCompareValue}>
                AGREGAR
              </Button>
              </Col>
            </Row>
              
            
            <Row className='mt-3'>
            {state.conditions[currentConditionIndex]?.compareValues.map((value, valueIndex) => (
              <Col key={valueIndex} xs={12} className="mb-2">
                <Row >
                  <Col xs={8}>
                    {value}
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => removeCompareValue(currentConditionIndex, valueIndex)}
                    >
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  }

  return (
    <div>
      <h6 className='hover' onClick={() => setShow(!show)}>{ show ? <FaChevronUp /> : <FaChevronDown /> } <b>{ show ? "OCULTAR CONDICIONALES" : "VER CONDICIONALES" }</b></h6>
      {mostrarFormulario()}
    </div>
  );
};

export default ConditionalEditor;
