import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import ErrorBoundary from '../errorsHandler/errorBoundary';

const BuscadorVehiculos = (props) => {
    const {
        extraOptions,
        hideLabel,
        defaultValue,
        placeholderText
    } = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [conductores, setConductores] = useState([])
    const [conductor, setConductor] = useState(defaultValue)
    const [opciones, setOpciones] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const isMulti = props.isMulti ? props.isMulti : false
    const placeholder = placeholderText ? placeholderText : "Seleccionar vehículo..."
    const extra_options = extraOptions ? extraOptions : []
    const dispatch = useDispatch()
    const refSelector = useRef()

    const obtenerProveedores = async () => {
        setLoading(true)
        return fetch(`${urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                // setError(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setOpciones(res.map(c => ({ value: c._id, label: `${c.patente ? c.patente.toUpperCase() : "SIN PATENTE"} · ${c.modelo ? c.modelo.toUpperCase() : "SIN MODELO"}` })))
                setConductores(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerProveedores()

    }, [])


    const handleChangeSelectConductor = (e) => {
        let extra_iterado = extra_options.map(val => ({ _id: val.value, label: val.label  }))
        if(isMulti === false){
            const i = [ ...conductores, ...extra_iterado ].findIndex(c => c._id === e.value)
            if(i > -1){
                if(props.onChange) props.onChange(conductores[i])
            }
        } else {
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            const registros = [ ...conductores, ...extra_iterado ].filter(c => opciones.includes(c._id))
            if(props.onChange) props.onChange(registros)
        }
        setConductor(e)
    }

    // if(defaultValue){
    //     if(isMulti){
    //         const valores = []
    //         defaultValue.map(d => {
    //             const i = opciones.findIndex(o => o.value === d?._id)
    //             if(i > -1) valores.push(opciones[i])
    //         })
    //         refSelector.current?.setValue(valores)
    //     }
    // }

    let default_value = Array.isArray(conductor) 
    ? conductor.map(c => ({ 
        value: c._id, 
        label: `${c.patente ? c.patente.toUpperCase() : "SIN PATENTE"} · ${c.modelo ? c.modelo.toUpperCase() : "SIN MODELO"}`
    })) 
    : []

    
    return <div>
        <ErrorBoundary message={error} />
        {/* {JSON.stringify(conductor)} */}
        { hideLabel === true ? false : <label className='form-control-label d-block' style={{fontWeight:700}}>Selecciona el vehículo</label>}
        <Select
                        ref={refSelector}
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectConductor}
                        isLoading={loading}
                        defaultValue={default_value}
                        isMulti={isMulti}
                        options={[...opciones, ...extra_options ]}
                        placeholder={placeholder}
                        noOptionsMessage={()=>'Sin opciones'}
                        className="mb-0 shadow-sm"
                        />
    </div>
}

export default BuscadorVehiculos