import { useEffect, useState } from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import data from '../../lib/backend/data'
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch } from "react-redux"
import { FaCircle } from "react-icons/fa"
import ErrorBoundary from "../errorsHandler/errorBoundary"

const ContadorEstadoRuta = (props) => {
    const {
        id_ruta
    } = props
    const [ error, setError ] = useState(false)
    const condicion = props.condicion_default ? props.condicion_default : {}
    const [ conteos, setConteos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ estados, setEstados ] = useState(props.estados ? props.estados : [])
    const [ tiposServicio, setTiposServicio ] = useState([])
    const token = props.token ? props.token : false
    const dispatch = useDispatch()

    const consultarEstados = () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/conteo-estadocarga-ruta?id=${id_ruta}`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setLoading(false)
                return setError('Sin datos')
            } else if(res.errorMessage){
                setLoading(false)
                return setError(res.errorMessage)
            } else if( res.estados){
                
                if(Array.isArray(res.estados) !== false){
                    setEstados(res.estados)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            setError(error.message)
            return setLoading(false)
        })
    }

    useEffect(() => {
        consultarEstados()
    },[])

    if(loading) return <div>
        <Spinner animation="border" />
        <h6>Cargando estados</h6>
    </div>

    if(estados.length < 1) return false

    return <div className="mb-3">
        <ErrorBoundary message={error} />
            {
                estados.map(est => {
                    let estados_asociados = est.estados ? est.estados.filter(e => e) : []
                    if(!Array.isArray(estados_asociados)) estados_asociados = []
                    if(estados_asociados.length < 1) return false
                    return <div key={est._id} className="mb-2">
                        <h5>{est.valor}</h5>
                        
                        <Row>
                            {
                                estados_asociados.map((est_asoc, i) => {
                                    let color = 'gray'
                                    if(est_asoc.color) color = est_asoc.color
                                    return <Col key={`${est.nombre}-${i}`}>
                                        <p className="mb-0" style={{ fontWeight: "bold", fontSize: 14 }}><FaCircle color={color} style={{ verticalAlign:"middle" }} /> {est_asoc.titulo ? est_asoc.titulo : "Sin gestión"}</p>
                                        <h4 className="mb-0">{est_asoc.cantidad}</h4>
                                    </Col>
                                })
                            }
                        </Row>
                    </div>
                })
            }
            
    </div>
}

export default ContadorEstadoRuta