import { useState } from "react"
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip, Modal, Badge } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import CargaImagenes from "../general/carga_imagenes";
import { LuMousePointerClick } from "react-icons/lu";
import { IoWarning } from "react-icons/io5";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import { MdOpenInNew } from "react-icons/md";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [showMessageInput, setShowMessageInput] = useState(false);
    const [message, setMessage] = useState('');
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [rating, setRating] = useState(proveedor.rating ? proveedor.rating : 0)
    const [showModal, setShowModal] = useState(false)
    const [ archivos, setArchivos ] = useState([])
    const dispatch = useDispatch()
    

    const sinInformacion = () => {
        return                 <Row className="mx-5 align-items-end mb-3">
                        <Col md={2} className="text-right">
                        <IoWarning className="text-warning" style={{fontSize:50}} />
                        </Col>
                        <Col md={10}>
                        <div className='text-center d-flex'>
                            <h5><LuMousePointerClick className='text-dark' style={{fontSize:25}}/> Seleccione una calificación para mostrar más información.</h5>
                        </div>
                        </Col>
                        </Row>
    }

    const View = () => {
        if(!proveedor) return sinInformacion()
        return  <div className="">
        <div className="p-4" style={{textAlign: 'center' }}>
        <h3>Orden <b>{proveedor.orden?.pedido}</b></h3>
        <Link to={`/${rutas.ordenes.slug}/${proveedor.orden?._id}`} target="_blank"><Button size="sm" variant="light"><MdOpenInNew /> VER ORDEN</Button> </Link>
        <hr/>
        { proveedor.rating > 0 ? <>
            {/* <label  className="form-control-label"  style={{fontSize: '20px'}}>Rating: {proveedor.rating} / {proveedor?.cantidad_estrellas_esquema}</label> */}
            {
                <div className="d-flex justify-content-center m-0 p-0">
                     {[...Array(proveedor.cantidad_estrellas_esquema)].map((_, index) => (
                <span
                key={index}
                style={{
                    cursor: 'pointer',
                    color: index < rating ? '#ffd700' : '#ccc',
                    fontSize: '3rem'
                }}>
                    ★
                </span>
            ))}

                </div>
            }
            </> : false  }

         { proveedor.categorias && proveedor.categorias.length > 0 ? <div >
            {proveedor.categorias.map((el, i) => {
                return <>
                <Badge key={i} className="form-control-label mt-3" >{el.titulo}</Badge>
                <h4><b>{el.value}</b></h4>
                </>
            })}
              </div> 
                : false
         }
         { proveedor.comentario ? <div style={{textAlign: 'center'}} className="mb-4 mt-3">
            <Badge className="f orm-control-label mt-2">Comentario</Badge>
            <p>{proveedor.comentario}</p>
        </div> 
            : false
        }
        { proveedor.documentos.length > 0 ? <>
        <span>Aqui se muestran los documentos cargados...</span>
        </> 
            : false
        }
        </div>
        </div>
       
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull