import { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { estilo_last_mile } from '../../lib/global/styles';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes';
import { BiMenu } from 'react-icons/bi';
import { modos_sistema, url_images } from '../../lib/global/data';
import { FaArrowLeft } from 'react-icons/fa';
import TopBar from './topbar';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { AiFillCar, AiOutlineCode, AiOutlineHome, AiOutlineProfile } from 'react-icons/ai';
import { MdOutlineBusinessCenter, MdOutlineMonitorHeart, MdOutlineNotificationsActive } from 'react-icons/md';
import { FaRegStar } from "react-icons/fa";
import { RiShutDownLine } from 'react-icons/ri';
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { Button } from 'react-bootstrap';
import { HiOutlineTruck } from "react-icons/hi2";
import { IoTicketOutline } from 'react-icons/io5';
import { TbRoute } from 'react-icons/tb';
import { IoMdBusiness, IoMdNotificationsOutline, IoMdSettings } from 'react-icons/io';

  const Structure = (props) => {
    const {
      router
    } = props
    const [collapsed, setCollapsed] = useState(false);
    const icons_style = { maxWidth: 25 }
    const esquema_default = useSelector(state => state.esquema_actual)
    const [ esquema, setEsquema ] = useState(esquema_default)
    const dispatch = useDispatch()
    const findAlias = (slug, title) => {
        if(!esquema[slug]) return title
        if(esquema[slug].alias) return esquema[slug].alias
        return title
    }
    
    const findActiveness = (slug) => {
        if(!esquema[slug]) return true
        return esquema[slug].active === true
    }
    const modoUsuario = useSelector(state => state.modo)

    const menuItemStyleClient = {
        root: {
            fontSize: 14,
            fontWeight: 700,
            color: 'black',
            // backgroundImage: `url(${url_images}/background.webp)`,
            // backgroundSize: "cover"
            backgroundColor: "white"
        },
        button: {
            '&:hover': {
                backgroundColor: "white"
            },
        },
        SubMenuExpandIcon: {
            color: 'black',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? "#f1f1f1" : '#f1f1f1', // Cambia el color de fondo del submenú aquí
        })
    }

    const menuStyleProvider = {
        root: {
            fontSize: 14,
            fontWeight: 700,
            color: 'white', // Ajusta el color del texto a blanco,
            backgroundColor: "transparent"
        },
        button: {
            '&:hover': {
                backgroundColor: "transparent"
            }
        },
        SubMenuExpandIcon: {
            color: "white",
        },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? estilo_last_mile.color_primary_dark_ : 'transparent', // Cambia el color de fondo del submenú aquí
        })
    }
      
      const estructura_cliente = [
        {
          type: "submenu",
          slug: "actividad",
          title: "Viajes",
          icon: <MdOutlineMonitorHeart size={28} />,
          options: [
            {
              slug: "entregas",
              title: "Torre de control Envios",
              route: rutas.actividad.slug
            },
            {
              slug: "ordenes",
              title: "Reportes y Pedidos Envios",
              route: rutas.ordenes.slug
            },
            {
              slug: "rutas",
              title: "Rutas asignadas",
              route: rutas.rutas.slug
            },
            // {
            //   slug: "estados_carga",
            //   title: "Eventos en ruta",
            //   route: rutas.actividad_estados.slug
            // },
            {
              slug: "auditoria_app_movil",
              title: "Auditoria por pedido",
              route: rutas.actividad_auditoria.slug
            },
            {
              slug: "auditoria_vehiculos",
              title: "Auditoria por vehículo",
              route: rutas.auditoria_conductores.slug
            },
            {
              slug: "rendimiento_proveedores",
              title: "OTIF proveedores",
              route: rutas.rendimiento_proveedores.slug
            },
            {
              slug: "calificaciones",
              title: "Calificaciones",
              route: rutas.calificaciones.slug
            }
          ]
        },
        {
          type: "submenu",
          slug: "notificaciones",
          title: "Notificaciones",
          icon: <IoMdNotificationsOutline size={28} />,
          options: [
            {
              type: "menuitem",
              slug: rutas.whatsapp.slug,
              title: "Whatsapp",
              route: rutas.whatsapp.slug
            },
            {
              type: "menuitem",
              slug: rutas.notificaciones.slug,
              title: "Configuración",
              route: rutas.notificaciones.slug
            },
          ]
        },
        {
          type: "submenu",
          slug: "tickets",
          title: "Tickets",
          icon: <IoTicketOutline size={28} />,
          options: [
            {
              type: "menuitem",
              slug: "tickets-todos",
              title: "Tickets enviados",
              // icon: <IoTicketOutline size={28} />,
              route: rutas.tickets.slug
            },
            // {
            //   type: "menuitem",
            //   slug: "tickets-pendientes",
            //   title: "Tickets pendientes",
            //   // icon: <IoTicketOutline size={28} />,
            //   route: rutas.tickets_pending.slug
            // },
            {
              type: "menuitem",
              slug: "tickets-proveedor",
              title: "Tickets recibidos",
              // icon: <IoTicketOutline size={28} />,
              route: rutas.tickets_business.slug
            },
          ]
        },
        {
          type: "submenu",
          slug: "tarifas",
          title: "Tarifas",
          icon: <MdOutlineBusinessCenter size={28} />,
          // route: rutas.logistic_providers_pricing.slug
          options: [
            {
              slug: "tarifarios",
              title: "Tarifarios",
              route: rutas.logistic_providers_pricing_tarifarios.slug
            },
            {
              slug: "contratos",
              title: "Contratos",
              route: rutas.logistic_providers_pricing_contratos.slug
            }]
        },

        {
          type: "submenu",
          slug: "flota",
          title: "Flota",
          icon: <AiFillCar size={28} />,
          options: [
            {
              slug: "mi_flota",
              title: "Vehículos",
              route: rutas.vehiculos.slug
            },
            {
              slug: "conductores",
              title: "Conductores",
              route: rutas.drivers.slug
            },
            {
              slug: "monitoreo_flota",
              title: "Monitoreo flota",
              route: rutas.actividad_flota.slug
            },
            {
              slug: "informes_rastreo_conductor",
              title: "Informes de rastreo",
              route: rutas.estadisticas_tracking_conductor.slug
            },
            {
              slug: "automatizaciones",
              title: "Alertas y notificaciones",
              route: `${rutas.zones.slug}/new`
            },
            {
              slug: "auditoria_conductores",
              title: "Reporte App Conductor",
              route: `${rutas.auditoria_conductores.slug}`
            },
            {
              slug: "expedicioness",
              title: "Expediciones",
              route: rutas.bodega_expediciones.slug
            },
            {
              slug: "invitaciones",
              title: "Invitaciones de rutas",
              route: rutas.rutas_asignaciones.slug
            },
            {
              slug: "horarios-logistica",
              title: "Horarios de logística",
              route: rutas.horarios_logistica.slug
            },
            {
              slug: "capacidades-especiales",
              title: "Capacidades especiales",
              route: rutas.capacidades_especiales.slug
            },
          ]
        },
        {
          type: "submenu",
          slug: "ruteo_simple",
          title: "Planificador",
          icon: <TbRoute size={28} />,
          options: [
            {
              type: "menuitem",
              slug: "ruteo_simple",
              title: "Planificar visitas",
              route: rutas.ruteo_simple.slug
            },
            {
              type: "menuitem",
              slug: "ruteo_avanzado",
              title: "Planificar entregas",
              route: rutas.ruteo_avanzado.slug
            }
          ]
          
        },
        {
            type: "menuitem",
            slug: "facturacion",
            title: "Facturación",
            icon: <FaMoneyCheckDollar size={28} />,
            route: rutas.facturacion_cliente.slug
          },
        {
          type: "submenu",
          slug: "configuracion",
          title: "Mi empresa",
          icon: <IoMdBusiness size={22} />,
          options: [
            {
              type: "menuitem",
              slug: "account",
              title: "Mi cuenta y usuarios",
              route: rutas.usuarios.slug
            },
            {
              type: "menuitem",
              slug: "protocolos",
              title: "Protocolo y notificaciones de Envíos",
              route: rutas.protocolos.slug
            },
            {
              type: "menuitem",
              slug: "mis-bodegas",
              title: "Mis bodegas y contactos",
              route: rutas.bodegas_contactos.slug
            },
            {
              type: "menuitem",
              slug: "configuraciones-courriers",
              title: "Configuraciones de Courrier",
              route: rutas.configuracion_courriers.slug
            },
            {
              type: "menuitem",
              slug: "otras-configuraciones",
              title: "Configuración general",
              route: "configuracion"
            },
          ]
        },
        {
          type: "submenu",
          slug: "productos",
          title: "Productos",
          icon: <HiOutlineTruck  size={28} />,
          
          options: [
            {
              type: "menuitem",
              slug: "maestro",
              title: "Maestro de productos",
              route: rutas.productos.slug,
            },
            {
              type: "menuitem",
              slug: "lista_precios",
              title: "Listas de precios",
              route: rutas.lista_precios.slug,
            },
            {
              type: "menuitem",
              slug: "maestro",
              title: "Inventarios",
              route: rutas.inventarios.slug,
            },
          ]
        },
        {
          type: "submenu",
          slug: "clientes",
          title: "Clientes",
          icon: <IoMdSettings size={22} />,
          options: [
            {
              type: "menuitem",
              slug: "mis-clientes",
              title: "Mis Clientes",
              // icon: <FaClipboardList size={22} />,
              route: rutas.destinatarios.slug
            },
            {
              type: "menuitem",
              slug: "tarifas-clientes",
              title: "Tarifas",
              route: rutas.clientes.slug,
              // icon: <FaRegHandshake size={28} />
            },
            {
              type: "menuitem",
              slug: "clientes-bodegas",
              title: "Bodegas",
              route: rutas.destinatarios_bodegas_general.slug
            },
            {
              type: "menuitem",
              slug: "contactos",
              title: "Contactos",
              route: rutas.destinatarios_contactos_general.slug
            },
          ]
        },
        {
            type: "submenu",
            slug: "integracioness",
            title: "Integraciones",
            icon: <AiOutlineCode size={28} />,
            options: [
              {
                type: "menuitem",
                slug: "integra_web",
                title: "Integra tu web",
                route: rutas.integra_web.slug
              },
              {
                type: "menuitem",
                slug: "integraciones-configuracion",
                title: "Avanzado",
                route: rutas.integraciones.slug
              },
            ]
          }
      ];


      const renderMenuItem = item => {
        const activo = findActiveness(item.slug)
        if(!activo) return false
        return <MenuItem icon={item.icon} component={<Link to={item.route} />}>
          {findAlias(item.slug, item.title)}
        </MenuItem>
      }
      
      const renderButton = item => {
        const activo = findActiveness(item.slug)
        if(!activo) return false
        return <MenuItem icon={item.icon} component={<Link to={item.route} />}>
        <Button className='w-100' >{findAlias(item.slug, item.title)}</Button>
        </MenuItem>
      }
      
      const renderSubMenu = submenu => {
        const activo = findActiveness(submenu.slug)
        if(!activo) return false
        return <SubMenu icon={submenu.icon} label={findAlias(submenu.slug, submenu.title)}>
        {submenu.options.map(option => renderMenuItem(option))}
      </SubMenu>
      }
      
      const transformarAHTML = estructura => estructura.map(item =>{
        if(item.type === "submenu"){
          return renderSubMenu(item)
        } else if(item.type === "menuitem"){
          return renderMenuItem(item)
        } else if(item.type === "button"){
          return renderButton(item)
        }
      }).filter(me => me)
      
      const elementosHTML_CLIENTE = transformarAHTML(estructura_cliente);

      const mostrarSegunModulo = () => {
        return elementosHTML_CLIENTE
      }

      const mostrarEstiloSegunModulo = () => {
        return menuItemStyleClient
        return modoUsuario === modos_sistema.proveedor.slug ? menuStyleProvider : menuItemStyleClient
      }
      
      const color_primario = "white"
      const color_secundario = estilo_last_mile.color_primary

    return <div style={{ height: "100%" }}>
        <TopBar />
        <div style={{ display: 'flex', height: '100%', minHeight: '100%' }}>

            <Sidebar collapsed={collapsed} backgroundColor={color_primario} style={{zIndex:100}}>
                <div style={{ padding: "20px 20px 0px 20px" }}>
                    <div className='hover' style={{ textAlign: collapsed ? "center" : "right", }} onClick={() => setCollapsed(!collapsed)}>
                        {!collapsed ? <div><FaArrowLeft size={20} color={color_secundario} /></div> : <BiMenu size={25} style={{ color: color_primario, marginLeft:6, backgroundColor: "black", padding: "1px 1px", borderRadius: 5 }} />}
                    </div>
                </div>
                <Menu style={{ paddingLeft: '1px' }} menuItemStyles={mostrarEstiloSegunModulo()}>
                <MenuItem icon={<AiOutlineHome size={28} /> } component={<Link to={`/`} />}>Inicio</MenuItem>
                {mostrarSegunModulo()}
                </Menu>

                <Menu menuItemStyles={mostrarEstiloSegunModulo()}>
                    <MenuItem icon={<RiShutDownLine size={28} />} component={<div onClick={() => dispatch(cerrarSesion())} />}>Cerrar sesión</MenuItem>
                </Menu>
            </Sidebar>
            <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>{router}</div>
        </div>
    </div>
}

export default Structure