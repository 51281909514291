import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import PantallaCargando from "../general/pantalla_cargando"
import { IoStarHalf } from "react-icons/io5"

const { Row, Card, Button } = require("react-bootstrap")
const { default: CargaImagenes } = require("../../components/Tracking/carga_imagenes")

const CalificarRegistro = (props) => {
    const {
        objeto,
        id_target
    } = props
    const [ error, setError ] = useState("")
    const [ calificado, setCalificado ] = useState(props.calificado === true)
    const [ esquema, setEsquema ] = useState(objeto ? objeto : null)
    const [ rating, setRating ] = useState(0)
    const [ loading, setLoading ] = useState(true)
    const [ sending, setSending ] = useState(false)

    const validarCalificacion = async () => {
        setLoading(true)
        return fetch(`${urlapi}/ordenes/calificar?id=${id_target}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                // 'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            // if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError("Sin respuesta del servidor")
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){
                setCalificado(true)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError(error.message)
            setLoading(false)
        })
      
    }

    useEffect(() => {
        validarCalificacion()
    }, [])
    const enviarCalificacion = async () => {

        const enviar = {
            id_target,
            rating: esquema.rating,
            cantidad_estrellas_esquema: objeto.cantidad_estrellas ?? 0,
            categorias: esquema.categorias,
            comentario: esquema.comentario,
            documentos: Array.isArray(esquema.archivos) ? esquema.archivos : [],
            propietario: esquema.propietario
        }
        setSending(true)
        return fetch(`${urlapi}/ordenes/calificar`,{
            method:'POST',
            body: JSON.stringify({
                calificacion: enviar
            }),
            headers: {
                'Content-Type':'application/json',
                // 'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            // if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError("Sin respuesta del servidor")
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){
                setCalificado(true)
            }
            return setSending(false)
        })
        .catch(error => {
            setError(error.message)
            setSending(false)
        })
      
    }

    const handleRating = (rate) => {
        setRating(rate)
        setEsquema(prev => {
            let actual = { ...prev }
            actual.rating = rate
            return actual
        })
    }

    const handleChangeSubcategoria = (i, value) => {
        return setEsquema(prev => {
            let actual = {...prev}
            actual.categorias[i].value = value
            return actual
        })
    }

    const handleChange = (key, value) => {
        setEsquema(prev => {
            let actual = { ...prev }
            actual[key] = value
            return actual
        })
    }

    if(loading) return <PantallaCargando />
    if(!esquema) return false

    if(calificado) return <div className="p-3 text-center">
        <IoStarHalf />
        <h4>Gracias por calificar</h4>
        <p>Tu opinión es valiosa para construir experiencias que se adapten a tus estándares de calidad.</p>
    </div>

    return <div>
                <h2 className="mb-0"><b>{objeto?.titulo}</b></h2>
                <p className="mb-3">{objeto?.descripcion}</p>
                { objeto.casilla_estrellas && objeto.casilla_estrellas  !== false &&  objeto.casilla_estrellas  !== "false" ? <>
                    { objeto.cantidad_estrellas > 0 ? <>
                    {
                        <div>
                            <h4 className="text-center" style={{ marginBottom:-20}}>Califica con estrellas</h4>
                        <div className="d-flex justify-content-center m-0 p-0">
                             {[...Array(objeto.cantidad_estrellas)].map((_, index) => (
                        <span
                        key={index}
                        onClick={() => handleRating(index + 1)}
                        style={{
                            cursor: 'pointer',
                            color: index < rating ? '#ffd700' : '#ccc',
                            fontSize: '4rem'
                        }}>★</span>
                    ))}
                        </div>
                        </div>
                    }                    
                    </> : false  }
                </> : false
                }
                 { objeto.categorias && objeto.categorias.length > 0 ? <div className="mb-3">
                    {objeto.categorias.map((el, i) => {
                        return <>
                        <label key={i} className="form-control-label mt-2"><b>{i+1}</b> {el.titulo}</label>
                       
                            {el.subCategoria.length > 0 ? <div>
                                <select name={el.titulo} className='form-control' onChange={(e) => handleChangeSubcategoria(i, e.target.value)}>
                                <option key={i} value={""}>Selecciona</option>
                               { el.subCategoria.map((sub, i) => {
                                    return <option key={i} value={sub.titulo}>{sub.titulo}</option>
                                })
                            }  </select></div> : false }
                           
                        </>
                    })}
                    
                </div> 
                    : false
                }
                 { objeto.casilla_comentario && objeto.casilla_comentario  !== false &&  objeto.casilla_comentario  !== "false" ? <div style={{}} className="mb-3 ">
                    <p className="" >Dejanos tus comentarios:</p>
                    <input className="form-control" onChange={(e) => handleChange('comentario', e.target.value)} />
                </div> 
                    : false
                }
                { objeto.carga_documentos && objeto.carga_documentos  !== false &&  objeto.carga_documentos  !== "false" ? <>
                    <CargaImagenes onUploaded={(url,name) => console.log(url,name)} />
                </> 
                    : false
                }
                <Button className="w-100" variant="success" disabled={sending} onClick={() => enviarCalificacion()} >ENVIAR</Button>
    </div>
}

export default CalificarRegistro