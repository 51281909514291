import { ReactSortable } from "react-sortablejs"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import ErrorBoundary from "../errorsHandler/errorBoundary"
import { MdDragIndicator, MdPlace } from "react-icons/md"
import { set } from "date-fns"
import { debounce } from "@mui/material"
import { GrUpdate } from "react-icons/gr"

const { useState, useEffect, useCallback } = require("react")
const { useDispatch, useSelector } = require("react-redux")
const { default: CargandoGeneral } = require("../general/cargando_general")
const { Row, Col, Card, Spinner, Button } = require("react-bootstrap")

const ReordenarAppConductor = (props) => {
    const {
        id_conductor
    } = props
    const [ error, setError ] = useState("")
    const [ saving, setSaving ] = useState(false)
    const [ pedidos, setPedidos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const guardarAutomatico = async (data) => {
        setSaving(true)
        setError(false)
        return fetch(`${urlapi}/ordenes/conductor/order`,{
            method:'PUT',
            body: JSON.stringify(data.map(orden => ({ posicion: orden.posicion, _id: orden._id }))),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
                .then(res => {
                    if(res.status === 401) return dispatch(cerrarSesion())
                    return res.json()
                })
                .then(res => {
                    console.log(res)
                    if(!res){
                        setError('Sin datos')
                    } else if(res.errorMessage){
                        setError(res.errorMessage)
                    } else if(Array.isArray(res) !== false){

                    }
                    return setSaving(false)
                })
                .catch(error => {
                    setError("Error al consultar la información, intente nuevamente")
                    return setSaving(false)
                })
    }

    const obtenerDatos = async () => {
        setLoading(true)
        setError(false)
        return fetch(`${urlapi}/ordenes/conductor/${id_conductor}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
                .then(res => {
                    if(res.status === 401) return dispatch(cerrarSesion())
                    return res.json()
                })
                .then(res => {
                    console.log(res)
                    if(!res){
                        setError('Sin datos')
                    } else if(res.errorMessage){
                        setError(res.errorMessage)
                    } else if(Array.isArray(res) !== false){
                        setPedidos(res)
                    }
                    return setLoading(false)
                })
                .catch(error => {
                    setError("Error al consultar la información, intente nuevamente")
                    return setLoading(false)
                })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 500), []);
    
    const handleSort = (d) => {
        const nuevos = d.map((orden,pos) => {
            orden.posicion = pos+1
            return orden
        })
        setPedidos(prev => {
            actualizar(nuevos)
            return nuevos
        })
    }

    const mostrarInformacion = () => {
        if(loading) return <CargandoGeneral />

        return <div>
            <ReactSortable list={pedidos} setList={(d) => handleSort(d)}>
            {
                pedidos.map((orden,pos) => {

                    let titulo_estado           = ''
                    let titulo_sub_estado       = ''
                    let color_estado            = '#bebebe'
    
                    if(orden.estado_entrega){
                        if(typeof orden.estado_entrega === 'object'){
                            if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                            if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                        }
                    }
                
                    if(orden.sub_estado_entrega){
                        if(typeof orden.sub_estado_entrega === 'object'){
                            if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                        }
                    }
    
                    let string_estado = `${titulo_estado} ${titulo_sub_estado}`
                    if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"
    
                    
                    return <div key={`pedido-${orden._id}`}>
                        <Card className="p-2 mb-2 hover">
                            <Row>
                                <Col><h6 className="mb-0" style={{ textTransform: "uppercase" }}><MdDragIndicator className="hover mr-1" color="gray" size={20} style={{ verticalAlign: "top" }} /> {pos+1} <b>Orden {orden.pedido}</b></h6></Col>
                                <Col md={3} className="text-center"><p style={{ fontSize: 9, fontWeight: 'normal', borderRadius: 2, backgroundColor: color_estado, padding: 6, color: 'white' }} className="mb-2">{string_estado.toUpperCase()}</p></Col>
                            </Row>
                            <hr className="mb-1 mt-1" />
                            <p className="mb-0"><MdPlace /> {orden?.orden?.billing?.address_1}</p>
                        </Card>
                    </div>
                })
            }
            </ReactSortable>
        </div>
    }

    const cargando = () => saving ? <Spinner animation="border" /> : <h5 className="text-success"><b>GUARDADO</b></h5>

    return <div>
        <ErrorBoundary message={error} />
        <Row className="mb-3">
            <Col>{cargando()}</Col>
            <Col className="text-right"><Button size="sm" variant="light" onClick={() => obtenerDatos()}><GrUpdate /> ACTUALIZAR</Button> </Col>
        </Row>
        {mostrarInformacion()}
    </div>
}

export default ReordenarAppConductor