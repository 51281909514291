import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Form, Modal, Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data, { urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import * as XLSX from 'xlsx'
import { corregirTelefono, sliceIntoChunks, validateEmail } from '../../../lib/helpers/helpers';
import { host, url_images } from '../../../lib/global/data';
import { Button as Boton } from '@mui/material';
import { PiWarningCircleBold } from "react-icons/pi"
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../../lib/helpers/dates';
import CargandoFlotante from '../../general/cargando_flotante'
import BuscadorRutas from '../buscador';
import BuscadorExcel from '../../excel-formats/selector';
import { esquema_ordenes } from '../../../lib/esquemas';
import SelectorTipoAsignacion from '../../general/selector_tipo_asignacion';
import ProveedoresListadoProveedores from '../../proveedores_transporte/enlace/listado_proveedores';
import BuscadorVehiculos from '../../conductores/buscador';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import SelectorBodega from '../../bodega/bodegas/selector_bodega';
import SelectorServicios from '../../servicios/selector';
import { dimensionesMinimasArreglo, formatoMoneda } from '../../../lib/helpers/main';
import { cerrarSesion } from '../../../redux/actions/session';
import { calcularPesoVolumetrico, getDireccion1, obtenerGeoDatosGoogle } from '../../../lib/helpers/pedidos/pedidos';
import { AiOutlineFileExcel } from 'react-icons/ai';
import HelperIntegraciones from '../../general/helperIntegraciones';
import TiposServicioSelector from '../../ordenes/tipos_servicio/selector';
import CamposPersonalizados from '../../general/campos_personalizados';
import SelectorRangoFechas from '../../general/selector_rango_fechas';
import RegistrosVacios from '../../general/registros_vacios';
import { estilo_last_mile } from '../../../lib/global/styles';
import { Bar } from 'react-chartjs-2';
import TablaCotizacion from './tabla_cotizacion';
import { FaFileUpload, FaRegFileAlt, FaRegStar } from 'react-icons/fa';
import TablaRegistros from './tabla_registros';
import { MdVerified } from 'react-icons/md';
import ReglasCotizacion from '../../proveedores_transporte/cotizacion/reglas_cotizacion';
import { IoIosHelpCircle } from 'react-icons/io';
import { default_proveedores, encontrarLogoPorCodigoProveedor, encontrarMenorTotal } from '../../proveedores_transporte/data';
import RecoleccionRapida from '../../recolecciones/recolectar_rapido';
import Saldo from '../../financiero/saldo';
import ModalWrapper from '../../general/modal_wrapper';
import ErrorBoundary from '../../errorsHandler/errorBoundary';

const CotizadorOrdenes = (props) => {
    const {
        records
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ error, setError ] = useState(false)
    const dispatch = useDispatch()
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ loadingRecords, setLoadingRecords ] = useState(false)
    const [ loadingCamposPersonalizados, setLoadingCamposPersonalizados ] = useState(true)
    const [ confirmando, setConfirmando ] = useState(false)
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([])
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ servicio, setServicio] = useState(false)
    const [ reglaCentinela, setReglaCentinela ] = useState(false)
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema_ordenes)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(false)
    const [ showModalConfirmacion, setShowModalConfirmacion ] = useState(false)
    const [courriers, setCourriers] = useState([])
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(false)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ tipoServicio, setTipoServicio] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ codigoProveedor, setCodigoProveedor ] = useState("")
    const [ tipoAsignacion, setTipoAsignacion ] = useState('proveedor')
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ showModalTarifas, setShowModalTarifas ] = useState(false)
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const [ idRutaImportar, setIdRutaImportar ] = useState(false)
    const [ bodegaOrigen, seleccionarBodegaOrigen ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const [ selectionRangeBusqueda, setSelectionRangeBusqueda ] = useState(initialDate)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const [ ordenes, setOrdenes ] = useState([])
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const token = session.tokenSession
    const [ statusBoton, setStatusBoton ] = useState(false)
    
    const helpComponent = <OverlayTrigger placement={'top'} overlay={
        <Tooltip>Seleccionando una ruta a continuación se ignorará la creación de nuevas rutas y se asignarán las órdenes a la ruta seleccionada</Tooltip>
        }><IoIosHelpCircle /></OverlayTrigger>
    const internal_provider = <OverlayTrigger placement={'top'} overlay={
            <Tooltip>Este es un proveedor verificado</Tooltip>
            }><MdVerified color="#0070ff" /></OverlayTrigger>
    
    useEffect(() => {
        obtenerCamposPersonalizados()
        if(records) if(Array.isArray(records)) if(records.length > 0) obtenerDatosOrdenes(records)
    }, [ records ]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkForAvailability = (nombre) => {
        if(loadingCarga) return true
        if(!["delpa","99minutos","chilexpress","pedidosya","pullman","bluexpress","correos de chile"].includes(nombre.toString().toLowerCase())) return true
        return false
    }

    const handleChangeAsignacion = (e) => {
        const { value } = e.target
        setCodigoProveedor('')
        if(value === "vehiculo") setIdRutaImportar(false)
        return setTipoAsignacion(value)
    }

    const cargandoScreen = () => {
        return <Row>
            <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
            </Row>
    }

    const obtenerCamposPersonalizados = () => {
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCamposPersonalizados(res)
            }
            return setLoadingCamposPersonalizados(false)
        })
        .catch(error => {
            setLoadingCamposPersonalizados(false)
            return setError(error.message)
        })
    }

    const obtenerDatosOrdenes = (data) => {
        const ids = data.map(d => d._id)
        setLoadingRecords(true)
        return fetch(`${urlapi}/ordenes/full-data-masive`,{
            method:'POST',
            body: JSON.stringify({ids}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(Array.isArray(res) !== false){

                const homologados = res.map((re,ir) => {
                    
                    const objeto = {
                        _id: re._id,
                        referencia: re.pedido,
                        vehiculo: 'DELPA1',
                        origen_direccion_excel: '',
                        productos: re.productos,
                        identificador_contacto: '',
                        nombre_contacto: '',
                        telefono: '',
                        email_contacto: "",
                        direccion: re.orden?.billing?.address_1,
                        observaciones_direccion: '',
                        nota: '',
                        fecha_min_entrega: '',
                        fecha_max_entrega: '',
                        meta_data: []
                        }
                    return objeto
                })

                setRegistros(homologados)
                
            }
            return setLoadingRecords(false)
        })
        .catch(error => {
            setLoadingRecords(false)
            return setError(error.message)
        })
    }

    const iterarTarifasOrden = (orders, codigo_courrier) => {

        let propietario = codigo_courrier === "delpa" ? "760690872" : codigo_courrier

        const iterar = orders.map(next => {
            if(next.tarifa) if(Array.isArray(next.tarifa)){
                const i = next.tarifa.findIndex(ta => ta.provider === codigo_courrier)
                if( i > -1 ){
                    if( next.tarifa[i].tarifas ) if(Array.isArray(next.tarifa[i].tarifas)) if(next.tarifa[i].tarifas.length > 0 ){
                        next.tarifa_cotizacion = {
                            _id: '',
                            confirmation_details: next.tarifa[i].tarifas[0].confirmation_details,
                            tarifa: next.tarifa[i].tarifas[0].price,
                            propietario
                        }
                    }
                }
            }
            delete next.tarifa
            return next
        })
        return iterar
    }

    const subirDatos = async (idruta, datos, codigo_courrier) => {
        const progreso = procesados + datos.length

        const agregar_bodega = bodegaOrigen ? datos.map(o => {

            const geo_informacion = obtenerGeoDatosGoogle(bodegaOrigen.geodata)

            if(!o.origen) o.origen = {
                address_1: bodegaOrigen.direccion,
                first_name: bodegaOrigen.titulo,
                ...geo_informacion
            }

            if(!o.geo_datos_origen) o.geo_datos_origen = {
                level1: geo_informacion.level1,
                level2: geo_informacion.level2,
                pais: geo_informacion.pais,
                level3: geo_informacion.level3,
                address_1: bodegaOrigen.direccion
            }

            if(bodegaOrigen.id_cliente) o.id_cliente = bodegaOrigen.id_cliente
            return o
        }) : datos

        const iterarTarifas = iterarTarifasOrden(agregar_bodega, codigo_courrier)
        const agregar_tipo_servicio = iterarTarifas.map((ped => {
            ped.tiposervicio = tipoServicio?._id
            return ped
        }))

        let payload_enviar = {
            idruta,
            create: agregar_tipo_servicio,
            codigo_courrier,
            regla_centinela: reglaCentinela
        }

        return fetch(`${data.urlapi}/ordenes/confirm/batch`,{
            method:'POST',
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return setError('Sin datos')
            } else if(res.errorMessage){
                return setError(res.errorMessage)
            }
            if(Array.isArray(res.invalidos) !== false){
                for( const error of res.invalidos ){
                        errores.push(error)
                }
                setErrores(prev => [...[], ...errores])
            }
            return setProcesados(progreso)
        })
        .catch(error => {
            setError(error.message)
            return setProcesados(progreso)
        })
    }

    const validarPatentes = async (patentes) => {
        const data_default = patentes.map(pa => ({ patente: pa, valido: false }))
        return fetch(`${data.urlapi}/conductores/validar/patentes`,{
            method:'POST',
            body: JSON.stringify({
                patentes
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return false
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            setError(error.message)
            return data_default
        })
    }
    
    const end_point_validar_referencia = async (referencias) => {
        const data_default = referencias.map(pa => ({ referencia: pa, valido: false }))
        return fetch(`${data.urlapi}/ordenes/validar/referencia`,{
            method:'POST',
            body: JSON.stringify({
                referencias
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return false
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            setError(error.message)
            return data_default
        })
    }

    const obtenerUrlCreacionBasadoEnTipoAsignacion = () => {
        if( tipoAsignacion === "vehiculo") return `${data.urlapi}/rutas/por-patente`
        if( tipoAsignacion === "proveedor") return `${data.urlapi}/rutas`
        return ''
    }

    const crearRuta = async (id_conductor) => {
        if(idRutaImportar) return idRutaImportar
        const nuevo = {
            id_conductor,
            tipo_asignacion: tipoAsignacion,
            codigo_proveedor: codigoProveedor,
            fecha_despacho: selectionRange.startDate,
            fecha_asignacion: new Date(),
            entrega_desde: selectionRange.startDate,
            entrega_hasta: selectionRange.endDate
        }
        const url_creacion = obtenerUrlCreacionBasadoEnTipoAsignacion()
        if(!url_creacion) return false
        return fetch(url_creacion,{
            method:'POST',
            body: JSON.stringify(nuevo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return false
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return false
            } else if(res._id){
                return res._id
            }
            return false
        })
        .catch(error => {
            setError(error.message)
            return false
        })
    }

    const importacionExitosa = () => {
        if(props.onDatosImportados) return props.onDatosImportados()
        return true
    }

    const obtenerRegistrosParaCreacion = (datos, patente_vehiculo) => {
        if(tipoAsignacion === "vehiculo"){
            if(conductorSeleccionado) return datos
            const filtrados = datos.filter(reg => reg.vehiculo === patente_vehiculo)
            return filtrados
        } else if( tipoAsignacion === "proveedor"){
            return datos
        }

    }

    const subirRegistros = async (codigo_courrier) => {
        if(registros.length < 1) return setError("No hay registros para importar")
        setErrores([])
        let vehiculos = []
        
        if( tipoAsignacion === "vehiculo"){

            registros.map(re => {
                const i = vehiculos.filter(v => v === re.vehiculo)
                if(i.length > 0) return 
                vehiculos.push(re.vehiculo)
                return
            })
            
            if(conductorSeleccionado) vehiculos = [conductorSeleccionado.patente]

            const validacion = await validarPatentes(vehiculos)
            // return setLoadingCarga(false)
            const invalidos = validacion.filter(pa => pa.valido === false).map(pa => pa.patente)
            if(invalidos.length > 0){
                setLoadingCarga(false)
                return setError(`Los siguientes vehículos no existen, ${invalidos.join(', ')}`)
            }

        } else if( tipoAsignacion === "proveedor") {
            if(!codigo_courrier) return setError(`Selecciona un transporte primero`)
            vehiculos.push(codigo_courrier)

            const pendientes_cotizar = validarCotizacionCompleta(codigo_courrier)
            if(pendientes_cotizar) if(pendientes_cotizar.length > 0){
                setLoadingCarga(false)
                return setError("Debes cotizar el total de las órdenes que estás importando")
            }

        }

        
        setLoadingCarga(true)
        // setMensajeLoading('Validando referencias')
        // const validar_referencias = await validarReferencias(registros)

        // if(Array.isArray(validar_referencias) !== true ){
        //     setLoadingCarga(false)
        //     return
        // }
        
        // if(validar_referencias.length > 0){
        //     if(omitirEstados === true){
        //         for( const orden of validar_referencias ){
        //             const { referencia } = orden
        //             const i = registros.findIndex(r => r.referencia === referencia)
        //             if( i > -1) registros.splice(i,1)
        //         }
        //         setRegistros(prev => [...[], ...registros])
        //     } else {
        //         setLoadingCarga(false)
        //         return
        //     }
        // } 

        setMensajeLoading('Confirmando órdenes')
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        let total_procesadas = 0
        let ultima_ruta_creada = ''
        for( const vehiculo of vehiculos ){
            const ruta = '1' //await crearRuta(vehiculo)
            if(!ruta){
                continue
            }
            ultima_ruta_creada = ruta
            const filtrados = obtenerRegistrosParaCreacion(registros, vehiculo)
//            const filtrados = registros.filter(reg => reg.vehiculo === vehiculo)
            const dividir = sliceIntoChunks(filtrados, 10)
            for( const array of dividir ){
                await subirDatos(ruta, array, codigo_courrier)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }
        }
        setTimeout(() => {
            setLoadingMaster(false)
            setLoadingCarga(false)
            toast.success("Progreso finalizado")
            importacionExitosa()
            // window.location.reload()
            // window.location = `/rutas?id=${ultima_ruta_creada}`
        }, 4000);
        return setRegistros([])
    }
    const handleCloseErrores = () => {
        setShowModalConfirmacion(false)
        setCodigoProveedor('')
        return setShowModalErrores(false)
    }

    const mostrarModalConfirmacion = () => {

        const i = courriers.findIndex(c => c.name === codigoProveedor)
        if(i < 0) return false

        const instancia_courriers = JSON.parse(JSON.stringify(courriers))

        instancia_courriers.map((cou,i) => {
                            const filtrado = registros.filter(re => {
                                if(!re.tarifa) return false
                                if(!Array.isArray(re.tarifa)) return false
                                return re.tarifa.filter(ta => ta.provider === cou.name)
                            })
        
                            const calcular_total = filtrado.reduce((prev,next) => {
                                let sumar = 0
                                const encontrar = next.tarifa.findIndex(ta => ta.provider === cou.name)
                                if(encontrar > -1 ){
                                    const tarifas_encontradas = next.tarifa[encontrar].tarifas
                                    if(tarifas_encontradas.length > 0){
                                        if(tarifas_encontradas[0].price) sumar = tarifas_encontradas[0].price
                                    }
                                }
                                return prev + sumar
                            }, 0)
                            instancia_courriers[i].total = calcular_total
                        })
        
                        const mas_economico = encontrarMenorTotal(instancia_courriers)

        const cou = courriers[i]

        const filtrado = registros.filter(re => {
            if(!re.tarifa) return false
            if(!Array.isArray(re.tarifa)) return false
            return re.tarifa.filter(ta => ta.provider === cou.name)
        })

        const calcular_total = filtrado.reduce((prev,next) => {
            let sumar = 0
            const encontrar = next.tarifa.findIndex(ta => ta.provider === cou.name)
            if(encontrar > -1 ){
                const tarifas_encontradas = next.tarifa[encontrar].tarifas
                if(tarifas_encontradas.length > 0){
                    if(tarifas_encontradas[0].price) sumar = tarifas_encontradas[0].price
                }
            }
            return prev + sumar
        }, 0)

        const logo = encontrarLogoPorCodigoProveedor(cou.name, default_proveedores )

        const componente_titulo = <div>
            { logo ? <img style={{ height: 20 }} src={logo} /> : <h6 className="mb-0">{`${cou.name ? cou.name.toUpperCase() : ''}`}</h6>}
            {cou.name === mas_economico ? <span className="mt-1 d-block text-center" style={{ fontWeight: "bold", color: "white", padding: "2px 4px", borderRadius: 3, background: "#28a745", fontSize: 9 }}>MÁS ECONÓMICO</span> : false }
        </div>

        return <div>
            <Modal show={showModalConfirmacion} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Confirmación de órdenes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 style={{ color: "gray", fontSize:11 }} className='mb-0'>COURRIER {cou.internal === true ? internal_provider : false }</h6>
                    {componente_titulo}
                    {/* <div className='mt-3'><RecoleccionRapida /></div> */}
                    <Saldo ammount={calcular_total} />
                    <Card className='p-3 mb-3 text-center'>
                    <p className='mb-0'>Se confirmarán <b style={{ fontWeight: "bold" }}>{registros.length} órdenes</b></p>
                    <h4 className='mb-3'>${formatoMoneda(calcular_total)} <b style={{ color: "gray", fontWeight: "lighter" }}>+ IVA</b></h4>
                    <Button size="sm" className='w-100' variant="success" onClick={() => subirRegistros(cou.name)}>CONFIRMAR</Button>
                    {/* <Button size="sm" className='w-100' variant="success" disabled={{loadingCarga, statusBoton}} onClick={() => subirRegistros(cou.name)}>CONFIRMAR</Button> */}
                    </Card>
                </Modal.Body>
            </Modal>
        </div>
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const tablaRegistros = () => {
        if(registros.length < 1) return false

        return <div>
            <Table>
                <thead>
                    <tr>
                        <th>REFERENCIA</th>
                        <th>VEHÍCULO</th>
                        <th>DESTINATARIO</th>
                        <th>DIRECCION</th>
                        <th>ITEMS</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.referencia}</th>
                            <th>{reg.vehiculo}</th>
                            <th>{reg.nombre_contacto}</th>
                            <th>{reg.direccion}</th>
                            <th>{reg.productos.length}</th>
                        </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const validarReferencias = async (pedidos) => {
        let referencias = []
        pedidos.map(pedido => referencias.push(pedido.referencia))
    
        setLoadingMaster(true)
        setLoadingOperaciones(pedidos.length)
        setOperacionesListas(0)
        setErrores([])
        const arraydividido = sliceIntoChunks(referencias, 10)
        let cantidad_procesada = 0
        let registros_duplicados = []
        for( const datos of arraydividido){
            const response = await end_point_validar_referencia(datos)
            const duplicados = response.filter(da => da.valido === false)
            if(duplicados.length > 0) duplicados.map(ped => errores.push(`Referencia ${ped.referencia} ya existe`))
            cantidad_procesada = cantidad_procesada + datos.length
            setOperacionesListas(cantidad_procesada)
            setErrores(prev => [...[], ...errores])
            setDuplicados(prev => [...prev, ...duplicados])
            registros_duplicados = [...registros_duplicados, ...duplicados]
        }

        setLoadingMaster(false)
        setLoadingOperaciones(0)
        setOperacionesListas(0)
        return registros_duplicados
    }

    

    const onChangeServicio = (e) => {
        setServicio(e.value)
    }

    const handleCloseTarifas = () => {
        return setShowModalTarifas(false)
    }

    // const analizarResultadosCotizacion = (orders) => {
    //     for( const order of orders ){
    //         const i = registros.findIndex(o => o.referencia === order.referencia && o.direccion === order.direccion)
    //         if(i < 0) continue
    //         if(order.geo_datos) registros[i].geo_datos = order.geo_datos 
    //         if(typeof order.tarifa === "object"){
    //             if(!showModalTarifas) setShowModalTarifas(true)
    //             registros[i].tarifa = order.tarifa
    //         } else {
    //             registros[i].tarifa = false
    //         }
    //     }
    //     setRegistros(prev => [...[], ...registros])
    // }

    const analizarTarifa = (tarifas) => {
        let registros_courriers = []
        if(Array.isArray(tarifas) !== true) return false
        for( const tarifa of tarifas ){
            if(!tarifa.provider) continue
            console.log(tarifa.provider)
            // const i = todos_courriers.findIndex(co => co.name === tarifa.provider)
            registros_courriers.push({
                name: tarifa.provider,
                internal: tarifa.internal === true
            })
        }
        return registros_courriers
    }

    const analizarResultadosCotizacion = (orders) => {

        let registros_courriers = []
        setRegistros(prev => {
            let actual = [...prev]
            for( const order of orders ){
                const i = actual.findIndex(o => o.referencia === order.referencia)
                if(i < 0) continue
                if(order.original_geo_datos) actual[i].original_geo_datos = order.original_geo_datos 
                if(order.tarifa) actual[i].tarifa = order.tarifa
            }
            return actual
        })

        for( const order of orders ){
            if(Array.isArray(order.tarifa)){
                const datos_analizados = analizarTarifa(order.tarifa)
                registros_courriers = [...registros_courriers, ...datos_analizados]
            }
        }

        return registros_courriers
    }

    const cotizarOnline = async (orders)=>{

        let origen = null
        if(bodegaOrigen) if(typeof bodegaOrigen === "object") if(bodegaOrigen.geodata) if(typeof bodegaOrigen.geodata === "object"){
            origen = bodegaOrigen.geodata
        }

        const iterar_ordenes = orders.map(orden => {
            if(orden._id){
                orden.orden = {
                    billing: {
                        address_1: orden.direccion
                    },
                    }
                }
            
            const calculo_medidas = calcularPesoVolumetrico(orden.productos)
            const peso = calculo_medidas.default_weight
            let medidas = dimensionesMinimasArreglo(orden.productos)
            medidas.peso = peso * 1000 // LLEVAR A GRAMOS
            let datos_medidas = {
                volumen: calculo_medidas.default_vol
            }
            Object.keys(medidas).map(key => {
                datos_medidas[key] = parseFloat(medidas[key])
                return true
            })
            orden.medidas = datos_medidas
            return orden
        })

        return fetch(`${urlapi}/pricing/quotation/external/batch`,{
            method:'POST',
            body: JSON.stringify({
                orders: iterar_ordenes,
                codigo_proveedor: codigoProveedor,
                servicio,
                origen
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(Array.isArray(res) !== false){
                return res
            }
            return []
        })
        .catch(error => [])
    }

    const confirmarOnline = async (orders, codigo)=>{

        return fetch(`${urlapi}/pricing/quotation/confirm`,{
            method:'POST',
            body: JSON.stringify({
                orders,
                codigo_proveedor: codigo,
                servicio,
                meta_data: { tipo: "logistica", subtipo: "courrier", identificador: codigo }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(Array.isArray(res) !== false){
                return res
            }
            return []
        })
        .catch(error => [])
    }


    const cotizarOrdenes = async () => {
        if(!bodegaOrigen) return setError("Selecciona un origen")
        // if(!servicio) return setError("Selecciona un tipo de servicio para la cotización")
        const pedidos = JSON.parse( JSON.stringify( registros ) )

        const filtrar_sin_tarifa = pedidos //.filter(pe => !pe.tarifa || parseInt(pe.tarifa) < 1)
        //if(filtrar_sin_tarifa.length < 1) return setError('No hay pedidos pendientes de cotizar')
        
        // this.setState({ cotizando: true, operaciones_loading: filtrar_sin_tarifa.length, operaciones_loading_done:0, operaciones_mensajes: [] })
        setMensajeLoading('Cotizando')
        setLoadingMaster(true)
        setLoadingOperaciones(pedidos.length)
        setOperacionesListas(0)
        let total_procesadas = 0
        let proveedores = []
        const dividir = sliceIntoChunks(filtrar_sin_tarifa, 10)
            for( const array of dividir ){
                const resultados = await cotizarOnline(array)
                const courriers_ = analizarResultadosCotizacion(resultados)

                for( const provider of courriers_ ){
                    const i = proveedores.findIndex(pro => pro.name === provider.name)
                    if(i < 0) proveedores.push(provider)
                }
                
                // analizarResultadosCotizacion(resultados)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
        }

        setCourriers([...[], ...proveedores])
        setLoadingMaster(false)
        toast.success("Progreso finalizado")

    }

    const onChangeOrden = (data,i) => {
        setRegistros(prev => {
            let actual = [...prev]
            actual[i] = data
            return [...[], ...actual]
        })
    }

    const cotizacionesAcciones = () => {
        if(tipoAsignacion !== "proveedor") return false

        return <div >
            <Card className='p-3'>
            <Row>
                <Col md={3}>
                <h4 className='mb-0'>Cotizar órdenes</h4>
                { courriers.length > 0 ? <Button disabled={loadingMaster} size='sm' className='mb-3' onClick={() => cotizarOrdenes()} variant='secondary' >COTIZAR</Button> : false }
                </Col>
                <Col md={9} className='mb-3 text-right'><ReglasCotizacion /></Col>
                <Col md={12}>{mostrarTarifas()}</Col>
            </Row>
            </Card>
            </div>
    }

    const onClickEmpresa = (data) => {
        setCodigoProveedor(data.codigo)
    }

    const handleCheckReferencias = (e) => {
        const valor = e.target.checked
        return setOmitirEstados(valor)
    }

    const onChangeCentinela = (e) => {
        setReglaCentinela(e?.value)
    }

    const accionesCarga = () => {
//         if(errores.length > 0) return false NO ESTOY SEGURO DE SI BORRAR
        if(registros.length < 1) return false
        let vehiculos = []
        registros.map(re => {
            const i = vehiculos.filter(v => v === re.vehiculo)
            if(i.length > 0) return 
            vehiculos.push(re.vehiculo)
            return
        })


        return <div className='mb-3'>
            {
                loadingCarga === true ? <div>
                    <Spinner animation='border' />
                    <h5>Espere un momento</h5>
                    </div> : <div>
                    <SelectorBodega onChange={(data) => seleccionarBodegaOrigen(data)} />
                    {/* <Card className='p-3'>
                    <Row>
                    <Col md={6}></Col>
                        <Col md={3}> <SelectorTipoAsignacion onChange={(e) => handleChangeAsignacion(e)} /></Col>
                        <Col md={3}>
                        <label className='form-control-label'>El campo referencia solo aceptará referencias nunca antes usadas</label>
                        <OverlayTrigger placement="top-start" overlay={
                                                <Tooltip>Activando esta opción, el sistema te permitirá cargar las órdenes aunque hayas cargado referencias repetidas, estas no se crearán pero el resto si.</Tooltip>
                                                }>
                                                    <Form.Group className="mb-0" controlId="omitir_referencias">
                                            <Form.Check type="switch" label="Omitir referencias duplicadas" onChange={handleCheckReferencias} />
                                            </Form.Group>
                                            </OverlayTrigger>
                        </Col>
                        <Col md={6} className="mb-3">
                        <label className='form-control-label d-block'>Tipo de servicio asignado para estas cargas</label>
                        <TiposServicioSelector defaultValue={tipoServicio} onChangeValue={(data) => setTipoServicio(data)} />
                        </Col>
                        { tipoAsignacion === "vehiculo" ? <Col md={6} className="mb-3"><BuscadorRutas helpComponent={helpComponent} labelTitle="Asignar estas órdenes a una ruta" token={token} onChangeValue={(data) => onChangeRuta(data)} /></Col> : false }
                    </Row>
                    </Card> */}

                    {/* <Row>
                        <Col md={12}>
                        <div className='mb-3'><SelectorRangoFechas titulo_label="Rango de entrega" default_range_dates={selectionRange} onChange={(data) => cambiarRangoFecha(data)} /></div>
                        
                       

                        <p className='mb-0'>Has click para crear <b className='text-primary'>{vehiculos.length} rutas</b> para <b className='text-primary'>{vehiculos.join(', ')}</b>, puedes asignar varios vehículos en un mismo excel, se creará una ruta por cada vehículo respectivamente.</p>    
                        
                        <ModalSelectorReglas onChange={(data) => onChangeCentinela(data)} />

                        <Row className='mt-3'>
                            {showAsignementBox()}
                            { tipoAsignacion === "vehiculo" ? <Col className='mb-3'><Button variant="success" onClick={()=>subirRegistros()} disabled={statusBoton}>CREAR {vehiculos.length} RUTAS</Button></Col> : false }
                            <Col> </Col>
                        </Row>

                        </Col>
                    </Row> */}
                        {cotizacionesAcciones()}
                    </div>
            }
        </div>
    }

    const onChangeRuta = (data) => {
        return setIdRutaImportar(data.value)
    }

    const onChangeOrdenCotizacion = (data) => {
        setRegistros(prev => {
            let actual = [...prev]
            const i = actual.findIndex(pe => pe.referencia === data.referencia)
            if(i > -1 ){
                actual[i] = data
                actual[i].tarifa = []
            }
            return [...[], ...actual]
        })
    }

    const preConfirmacion = (name) => {
        setCodigoProveedor(name)
        setShowModalConfirmacion(true)
    }

    const validarCotizacionCompleta = (codigo_proveedor) => {
        const total_cotizacion = registros.filter((next) => {
            if(next.tarifa) if(Array.isArray(next.tarifa)){
                const i = next.tarifa.findIndex(ta => ta.provider === codigo_proveedor)
                if( i > -1 ){
                    if( next.tarifa[i].tarifas ) if(Array.isArray(next.tarifa[i].tarifas)) if(next.tarifa[i].tarifas.length > 0 ) return false
                }
            }
            return true
        })
        return total_cotizacion
    }

    const cotizarBox = () => {
        return <div className='p-4'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
            <h3 className='mb-0 text-primary' style={{ fontWeight: "bold" }}>Cotiza tu carga en un click</h3>
            <img className='d-block' src={`${url_images}/animations/Pricing Strategy.gif`} style={{ width: 250, maxWidth: "80%", margin: "0 auto" }} />
            <p>Cotiza en los courriers nacionales más populares, confirma, imprime tus etiquetas y espera por la recolección</p>
            <Button disabled={loadingMaster}  onClick={() => cotizarOrdenes()} variant='secondary' >COTIZAR</Button>
            </Col>
        </Row>
        
        </div>
    }

    const mostrarTarifas = () => {
        const filter = registros.filter(o => o.tarifa)
        if(filter.length < 1) return cotizarBox()

        const otros_registros = registros.filter(re => {
            if(!re.tarifa) return true
            if(!Array.isArray(re.tarifa)) return true
            if(re.tarifa.length < 1) return true
            return false
        })

        const data = courriers.map((cour,ic) => {

            const filtrado = registros.filter(re => {
                if(!re.tarifa) return false
                if(!Array.isArray(re.tarifa)) return false
                return re.tarifa.filter(ta => ta.provider === cour.name).length > 0 ? true : false
            })

            const calcular = filtrado.reduce((prev,next) => {
                let sumar = 0
                const encontrar = next.tarifa.findIndex(ta => ta.provider === cour.name)
                if(encontrar > -1 ){
                    const tarifas_encontradas = next.tarifa[encontrar].tarifas
                    if(tarifas_encontradas.length > 0){
                        if(tarifas_encontradas[0].price) sumar = tarifas_encontradas[0].price
                    }
                }
                return prev + sumar
            }, 0)

            return {
                label: cour.name,
                total: calcular
            }
        })

        const data_graph = {
            labels: data.map(da => da.label.toUpperCase()),
            datasets: [
            {
                label: 'Monto cotización',
                data: data.map(da => da.total),
                backgroundColor: [
                estilo_last_mile.color_primary,
                estilo_last_mile.color_secondary,
                estilo_last_mile.color_third,
                ]
            },
            ],
        }

        const options_graph = {
            responsive: true
        }

        const instancia_courriers = JSON.parse(JSON.stringify(courriers))


                instancia_courriers.map((cou,i) => {
                    const filtrado = registros.filter(re => {
                        if(!re.tarifa) return false
                        if(!Array.isArray(re.tarifa)) return false
                        return re.tarifa.filter(ta => ta.provider === cou.name)
                    })

                    const calcular_total = filtrado.reduce((prev,next) => {
                        let sumar = 0
                        const encontrar = next.tarifa.findIndex(ta => ta.provider === cou.name)
                        if(encontrar > -1 ){
                            const tarifas_encontradas = next.tarifa[encontrar].tarifas
                            if(tarifas_encontradas.length > 0){
                                if(tarifas_encontradas[0].price) sumar = tarifas_encontradas[0].price
                            }
                        }
                        return prev + sumar
                    }, 0)
                    instancia_courriers[i].total = calcular_total
                })

                const mas_economico = encontrarMenorTotal(instancia_courriers)

        return <div>

            {filter.length !== registros.length ? <div>
                <h6 className="mb-0 text-warning"><PiWarningCircleBold size={20}  /> La cotización fue parcial</h6>
            </div> : false}

            <Row>
            
            <Col md={12}>
            
            <Row className='mb-3'>
                <Col><TablaCotizacion onChange={(data) => onChangeOrdenCotizacion(data)} courriers={courriers} registros={registros} otros_registros={otros_registros} options_graph={options_graph} data_graph={data_graph} /></Col>
                <Col><Card className='p-2 shadow'><p className='mb-0 text-center'><FaRegFileAlt /> {filter.length} ÓRDENES COTIZADAS DE {registros.length}</p></Card> </Col>
            </Row>
            </Col>
            <Col md={12}>
            <Row>
                    {
                        courriers.map((cour,ic) => {

                            const filtrado = registros.filter(re => {
                                if(!re.tarifa) return false
                                if(!Array.isArray(re.tarifa)) return false
                                const i = re.tarifa.findIndex(ta => ta.provider === cour.name)
                                if(i < 0) return false
                                return re.tarifa[i].tarifas.length > 0 ? true : false

                            })

                            const calcular = filtrado.reduce((prev,next) => {
                                let sumar = 0
                                const encontrar = next.tarifa.findIndex(ta => ta.provider === cour.name)
                                if(encontrar > -1 ){
                                    const tarifas_encontradas = next.tarifa[encontrar].tarifas
                                    if(tarifas_encontradas.length > 0){
                                        if(tarifas_encontradas[0].price) sumar = tarifas_encontradas[0].price
                                    }
                                }
                                return prev + sumar
                            }, 0)

                            const logo = encontrarLogoPorCodigoProveedor(cour.name, default_proveedores )

                            const componente_titulo = <div>
                                { logo ? <img style={{ height: 20 }} src={logo} /> : <h6 className="mb-0">{`${cour.name ? cour.name.toUpperCase() : ''}`}</h6>}
                                {cour.name === mas_economico ? <span className="mt-1 d-block text-center" style={{ fontWeight: "bold", color: "white", padding: "2px 4px", borderRadius: 3, background: "#28a745", fontSize: 9 }}>MÁS ECONÓMICO</span> : false }
                            </div>

                            const no_disponible = checkForAvailability(cour.name)
                            return <Col key={`cou-${ic}`} md={12} className="mb-2">
                            <Card className="p-3">
                                <Row>
                                    <Col md={2}>
                                        <h6 style={{ color: "gray", fontSize:11 }} className='mb-0'>COURRIER {cour.internal === true ? internal_provider : false }</h6>
                                        {componente_titulo}
                                        {/* <h6 style={{ textTransform: "uppercase" }} className="mb-0">{cour.internal === true ? internal_provider : false } {cour.name} </h6> */}
                                    </Col>
                                    <Col md={3}>
                                        <h6 style={{ color: "gray", fontSize:11 }} className='mb-0'>TIPO DE RECOLECCIÓN</h6>
                                        <h6 style={{ textTransform: "uppercase" }} className="mb-0">POR DELPA</h6>
                                    </Col>
                                    <Col md={4}>
                                        <h6 style={{ color: "gray", fontSize:11 }} className='mb-0'>TARIFAS ENCONTRADAS</h6>
                                        <h6 style={{ textTransform: "uppercase" }} className="mb-0">{filtrado.length} · <b className='text-success'>${formatoMoneda(calcular)}</b></h6>
                                    </Col>
                                    <Col md={3} className='text-right'>
                                        <Button size="sm" disabled={no_disponible} variant={ no_disponible ? "secondary" : "success"} onClick={() => preConfirmacion(cour.name)}>{ !no_disponible ? "CONFIRMAR" : "NO DISPONIBLE"}</Button>
                                        {/* <Button size="sm" disabled={{no_disponible, statusBoton}} variant={ no_disponible ? "secondary" : "success"} onClick={() => preConfirmacion(cour.name)}>{ !no_disponible ? "CONFIRMAR" : "NO DISPONIBLE"}</Button> */}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        })
                    }
                </Row>
            </Col>
            </Row>
        </div>
    }

    const mostrarRegistros = () => {
        if(loadingRecords) return cargandoScreen()
        if(loadingExcel === true ) return cargandoScreen()
        return <div>
            {mostrarErrores()}
            {mostrarModalConfirmacion()}
            {accionesCarga()}
        </div>
    }

    const reemplazarLlave = (key) => {
        if(!formatoSeleccionado) return key
        const i = formatoSeleccionado.columnas.findIndex(e => e.target === key)
        if(i > -1) return formatoSeleccionado.columnas[i].key
        return key
    }

    const handleInputChange = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return setError('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return setError('No se encontraron datos')
            if(hojas[0].length < 1) return setError('No se encontraron datos')
            if(!hojas[0][0]) return setError('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return setError(errores.join(', ')) // SI HAY ERRORES DETENER

            let columnas_buscar = columnasrequeridas

            if(formatoSeleccionado){
                if(formatoSeleccionado.columnas){
                    if( Array.isArray(formatoSeleccionado.columnas) !== false ){
                        for( const col of formatoSeleccionado.columnas ){
                            const { target } = col
                            const pos = columnas_buscar.findIndex(c => c === target)
                            if(pos > -1) columnas_buscar[pos] = col.key
                        }
                    }
                }
            }

            hojas[0].map((row,irow) => {
                
                let campos_faltantes = []
                columnas_buscar.map(nombre => {
                    if(tipoAsignacion === "proveedor"){
                        if(nombre !== "vehiculo") if(!row[nombre]) campos_faltantes.push(nombre)
                    } else {
                        if(!row[nombre]) campos_faltantes.push(nombre)
                    }
                })

                if(campos_faltantes.length > 0){
                    errores.push(`Fila ${irow+2} le faltan campos obligatorios. ${campos_faltantes.join(', ')}`)
                    return false
                }

                const email = validateEmail(row[reemplazarLlave("email_contacto")] ? row[reemplazarLlave("email_contacto")].toString().trim() : '')
                if(!email){
                    console.log({ email, excel_email: row[reemplazarLlave("email_contacto")]  })
                    errores.push(`Fila ${irow+2} tiene un email inválido.`)
                    return false
                }

                const i_existe = registros.findIndex(re => re.referencia === row[reemplazarLlave("referencia")].toString().trim())
                let cantidad = parseInt(row[reemplazarLlave("cantidad")])
                if(isNaN(cantidad)) cantidad = 1

                if(i_existe > -1){
                    const i_producto_existe = registros[i_existe].productos.findIndex(pro => pro.codigo_item === row[reemplazarLlave("codigo_item")].toString())
                    if(i_producto_existe > -1){
                        registros[i_existe].productos[i_producto_existe].quantity = registros[i_existe].productos[i_producto_existe].quantity + cantidad
                    } else {
                        registros[i_existe].productos.push({ 
                            description: row[reemplazarLlave('item')] ? row[reemplazarLlave('item')].toString() : '',
                            quantity: cantidad,
                            codigo_item: row[reemplazarLlave('codigo_item')] ? row[reemplazarLlave('codigo_item')].toString() : '',
                            peso: row[reemplazarLlave("peso")] ? parseInt(row[reemplazarLlave("peso")]) : 0,
                            alto: row[reemplazarLlave("alto")] ? parseInt(row[reemplazarLlave("alto")]) : 0,
                            ancho: row[reemplazarLlave("ancho")] ? parseInt(row[reemplazarLlave("ancho")]) : 0,
                            largo: row[reemplazarLlave("largo")] ? parseInt(row[reemplazarLlave("largo")]) : 0,
                            price: row[reemplazarLlave("precio_item")] ? parseInt(row[reemplazarLlave("precio_item")]) : 0,
                        })
                    }
                    return
                }

                let nuevo_registro = {
                    referencia: row[reemplazarLlave("referencia")] ? row[reemplazarLlave("referencia")].toString().trim() : '',
                    vehiculo: row[reemplazarLlave("vehiculo")] ? row[reemplazarLlave("vehiculo")].toString() : '',
                    origen_direccion_excel: row[reemplazarLlave("origen")] ? row[reemplazarLlave("origen")].toString() : '',
                    productos: [
                        { 
                            description: row[reemplazarLlave("item")] ? row[reemplazarLlave("item")].toString() : '',
                            peso: row[reemplazarLlave("peso")] ? parseInt(row[reemplazarLlave("peso")]) : 0,
                            alto: row[reemplazarLlave("alto")] ? parseInt(row[reemplazarLlave("alto")]) : 0,
                            ancho: row[reemplazarLlave("ancho")] ? parseInt(row[reemplazarLlave("ancho")]) : 0,
                            largo: row[reemplazarLlave("largo")] ? parseInt(row[reemplazarLlave("largo")]) : 0,
                            quantity: cantidad,
                            codigo_item: row[reemplazarLlave("codigo_item")] ? row[reemplazarLlave("codigo_item")].toString() : '',
                            price: row[reemplazarLlave("precio_item")] ? parseInt(row[reemplazarLlave("precio_item")]) : 0,
                        }
                    ],
                    identificador_contacto: row[reemplazarLlave("identificador_contacto")] ? row[reemplazarLlave("identificador_contacto")].toString() : '',
                    nombre_contacto: row[reemplazarLlave("nombre_contacto")] ? row[reemplazarLlave("nombre_contacto")].toString() : '',
                    telefono: row[reemplazarLlave("telefono")] ? corregirTelefono(row[reemplazarLlave("telefono")].toString()) : '',
                    email_contacto: row[reemplazarLlave("email_contacto")].toString().trim(),
                    direccion: row[reemplazarLlave("direccion")] ? row[reemplazarLlave("direccion")].toString() : '',
                    observaciones_direccion: row[reemplazarLlave("observaciones_direccion")] ? row[reemplazarLlave("observaciones_direccion")].toString() : '',
                    nota: row[reemplazarLlave("nota")] ? row[reemplazarLlave("nota")].toString() : '',
                    fecha_min_entrega: row[reemplazarLlave("fecha_min_entrega")] ? row[reemplazarLlave("fecha_min_entrega")] : '',
                    fecha_max_entrega: row[reemplazarLlave("fecha_max_entrega")] ? row[reemplazarLlave("fecha_max_entrega")] : '',
                    codigo_destino: row[reemplazarLlave("codigo_destino")] ? row[reemplazarLlave("codigo_destino")] : '',
                    meta_data: []
                }
                
                if(camposPersonalizados.length > 0){
                    for( const meta of camposPersonalizados ){

                        let slug = meta.slug.toLowerCase()
                        let indexado = {}
                        Object.keys(row).map(key => {
                            const llave_ = key.toLowerCase()
                            indexado[llave_] = row[key]
                        })

                        if(indexado[slug]){
                            nuevo_registro.meta_data.push({
                                key: slug,
                                value: indexado[slug]
                            })
                        }
                    }
                }

                // VERIFICAR CONCATENACIONES

                if(formatoSeleccionado){
                    if(formatoSeleccionado.columnas){
                        if(Array.isArray(formatoSeleccionado.columnas) !== false){
                            const filtradas_concatenaciones = formatoSeleccionado.columnas.filter(c => {
                                if(!c.concatenar) return false
                                if(Array.isArray(c.concatenar) !== true) return false
                                if(c.concatenar.length < 1) return false
                                return true
                            })
                            console.log({filtradas_concatenaciones})
                            if(filtradas_concatenaciones.length > 0){
                                for( const filtrada of filtradas_concatenaciones ){
                                    for( const concatenacion of filtrada.concatenar ){
                                        if(!concatenacion.columna) continue
                                        let valor = nuevo_registro[filtrada.target]
                                        if(concatenacion.texto) valor = `${valor}${concatenacion.texto}`
                                        if(row[concatenacion.columna]) valor = `${valor}${row[concatenacion.columna]}`
                                        nuevo_registro[filtrada.target] = valor
                                    }
                                }
                            }
                        }
                    }
                }
                console.log(nuevo_registro)
                registros.push(nuevo_registro)
            })

            let direcciones_unicas = []

            for( const reg of registros ){
                const i = direcciones_unicas.findIndex(direccion => direccion === reg.origen_direccion_excel)
                if( i > -1 ) continue
                direcciones_unicas.push(reg.origen_direccion_excel)
            }

            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return setError(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }
            setLoadingExcel(false)
            setRegistros(registros)

            geolocalizarDireccionesOrigen(direcciones_unicas)
          }
        }
    }

    const geocodeBackend = async (direcciones) => {
        return fetch(`${urlapi}/ordenes/geocode`,{
            method:'POST',
            body: JSON.stringify({
                direcciones
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(Array.isArray(res) !== false){
                return res
            }
            return false
        })
        .catch(error => {
            return false
        })

    }

    const geolocalizarDireccionesOrigen = async (direcciones) => {

        let respuestas = []
        

        setLoadingMaster(true)
        setLoadingCarga(true)
        setMensajeLoading('Validando orígenes')
        setLoadingOperaciones(direcciones.length)
        setOperacionesListas(0)

        let total_procesadas = 0

            const dividir = sliceIntoChunks(direcciones, 1)
            for( const array of dividir ){
                const res = await geocodeBackend(array)
                respuestas = [ ...respuestas, ...res ]
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }

        setLoadingCarga(false)
        setLoadingMaster(false)
        toast.success("Origenes validados")

        const direcciones_normalizadas = respuestas.map(re => {
            const geo_informacion = obtenerGeoDatosGoogle(re)
            return {...geo_informacion, direccion: re.direccion, original_geo: re }
        })

        
        setRegistros(prev => {
            let actual = [...prev]

            const datos_agregados = actual.map(re => {
                if(re.origen_direccion_excel){
                    const i = direcciones_normalizadas.findIndex(dire => dire.direccion === re.origen_direccion_excel)
                    if(i > -1){

                        const geo_informacion = direcciones_normalizadas[i]

                        re.origen = {
                            address_1: re.origen_direccion_excel,
                            first_name: "",
                            ...geo_informacion
                        }
            
                        re.geo_datos_origen = {
                            level1: geo_informacion?.level1,
                            level2: geo_informacion?.level2,
                            pais: geo_informacion?.pais,
                            level3: geo_informacion?.level3,
                            address_1: re.origen_direccion_excel
                        }

                        re.original_geo = geo_informacion?.original_geo
                    }
                }
                return re
            })
            
            console.log({datos_agregados})
            return [ ...[], ...datos_agregados ]

        })

    }

    const mostrarCamposPersonalizados = () => {
        return <CamposPersonalizados />
    }

    const onChangeFecha = (e) => {
        setSelectionRangeBusqueda(e)
    }

    const onChangeTipoBusqueda = (e) => {
        setTipoFechaBusqueda(e)
    }

    const obtenerOrdenes = async (cond)=>{
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/ordenes/pedidos-custom-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion: cond,
                campos: { 
                    estado_entrega: true, 
                    sub_estado_entrega: true,
                    pedido: true
                }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
                return setLoadingOrdenes(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingOrdenes(false)
            }
            if(Array.isArray(res) !== false){
                setOrdenes(res)
                // setTotal(res.total)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingOrdenes(false)
        })
    }

    const buscarOrdenesFiltradas = async () => {
        const desde = selectionRangeBusqueda.startDate
        const hasta = addMoreDays(selectionRangeBusqueda.endDate,1)

        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            const condicion = { fecha_max_entrega: { $gte: new Date( formatDateHoy(selectionRange.startDate)), $lte: new Date(hasta.getTime()-1) } }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        }
    }

    const onChangeValueExcel = (e) => {
        setFormatoSeleccionado(e)
    }

    const boxCargaExcel = () => {
        if(loadingRecords) return
        if( registros.length > 0 ) return false //  <div className='mb-3'><TablaRegistros typeForm="advance" datos={registros} onChange={(data,i) => onChangeOrden(data,i) } /></div>

        const imgExcel = `${url_images}/animations/Man transfer data from cloud.gif`
        return <div>
            <Card className='p-3'>
            <Row>
                <Col md={3}><h4 className='d-block'><AiOutlineFileExcel size={30} color='#2d9d57' /> Subir archivo excel</h4></Col>
                <Col md={9} className='text-right'>
                    <Row>
                        <Col><a href={`${host}/models/carga-modelo.xlsx`}><Card className="p-2 text-center hover"><h6 className="p-0 m-0 uppercase"><AiOutlineFileExcel  /> DESCARGAR MODELO</h6></Card></a></Col>
                        <Col>{mostrarCamposPersonalizados()}</Col>
                        <Col><ModalWrapper label="EXCEL PERSONALIZADO" title="Usa un formato personalizado de excel" component={<BuscadorExcel defaultValue={formatoSeleccionado?._id} onChangeValue={(e) => onChangeValueExcel(e)} token={token} />} /></Col>
                    </Row>
                </Col>
            </Row>
            
            <div className='p-4'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <img className='d-block' src={imgExcel} style={{ width: 300, maxWidth: "80%", margin: "0 auto" }} />
                <Boton component="label" color="success" variant="contained" disabled={statusBoton} startIcon={<FaFileUpload />}>
                SUBIR EXCEL AQUÍ
                <input
                    type="file" 
                    hidden
                    name="file" 
                    id="file" 
                    className="form-control mb-2"
                    onChange={handleInputChange} 
                    placeholder="Archivo de excel" 
                />
                </Boton>
                </Col>
            </Row>
            
            </div>
            
                {/* {
                    loadingExcel ? <Spinner animation='border' /> : <input
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control mb-2"
                    onChange={handleInputChange} 
                    placeholder="Archivo de excel" 
                />
                } */}

            </Card>
        </div>
    }

    const showAsignementBox = () => {
        if(tipoAsignacion === "vehiculo"){
            return <Col md={12} className='mb-3'>
                    <BuscadorVehiculos onChange={data => setConductorSeleccinoado(data)} token={token} />
            </Col>
        }
        
        // else if( tipoAsignacion === "proveedor"){
        //     return <div>
        //         <ProveedoresListadoProveedores hightLightCodigo={codigoProveedor} onClick={(data) => onClickEmpresa(data)} displayAll={true} condicion_default={{ propietario_cliente: session.data.propietario }} />
        //     </div>
        // }
      }

    return <div >
        <ErrorBoundary message={error} />
        {/* <HelperIntegraciones align="left" style="modal" tipo="importar" />
        <h4><i className="far fa-file-excel"></i> Importar datos excel</h4>
        <p className='mb-3'>Puedes importar un archivo excel para crear rutas de despacho</p> */}
        {boxCargaExcel()}
        <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
        {/* {
            registros ? <VerifyModelBusiness ruta={true} excelRutas={registros.length} token={token} handleChangeBoton={(status) => setStatusBoton(status)} />
            : <Spinner animation='border' />
        } */}
        {mostrarRegistros()}
    </div>

}

export default CotizadorOrdenes