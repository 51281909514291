import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { fechaATextoSimple } from '../../lib/helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import ErrorBoundary from '../errorsHandler/errorBoundary';

const SelectorZonas = (props) => {
    const {
        fetchGeojson,
        titulo,
        hideLabel,
        condicion_default
    } = props
    const [error, setError] = useState(false)
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const pais = useSelector(state => state.pais)
    const session = useSelector(state => state.miusuario)
    const titulo_label = titulo ? titulo : "Buscar por zona"
    const dispatch = useDispatch()
    
    const listadoInicial = async (condicion, page) => {
        const { pais_code } = condicion
        setLoading(true)
        const country = pais_code ? pais_code : pais
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
            method: 'POST',
            body: JSON.stringify({
                condicion,
                geo_json: fetchGeojson,
                pagina: page
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': country
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (!res) {
                    setError('Sin datos')
                    return setLoading(false)
                } else if (res.errorMessage) {
                    setError(res.errorMessage)
                    return setLoading(false)
                } else if (Array.isArray(res) !== false) {
                    if (props.onFetchedData) props.onFetchedData(res)
                    setOptions(res)
                }
                return setLoading(false)
            })
            .catch(error => {
                setError("Error al consultar la información, intente nuevamente")
                return setLoading(false)
            })
    }

    useEffect(() => {
        const cond = condicion_default ? condicion_default : {}
        listadoInicial(cond, 1)
    }, [ condicion_default ])

    const buscar = async (condicion, page) => {
        if (!session.tokenSession) return []
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
            method: 'POST',
            body: JSON.stringify({
                condicion,
                geo_json: fetchGeojson,
                pagina: page
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return []
            } else if(res.errorMessage){
                return []
            }
            setOptions(res)
            return res.length > 0 ? res.map(zona => ({ value: zona._id, label: `${zona.titulo} · ${zona.local_name}` })) : []
        })
        .catch(error => {
            setError(error.message)
            return false
        })
    }

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve) => {
            setTimeout(async () => {
                const busqueda = await buscar({ $text: { $search: inputValue } }, 1)
                resolve(busqueda);
            }, 500)
        })
    }

    const handleChange = (e) => {
        const i = options.findIndex(zona => zona._id === e?.value)
        if (i > -1) {
            console.log(options[i])
            if (props.onChangeValue) props.onChangeValue(options[i])
        } else {
            if (props.onChangeValue) props.onChangeValue(false)
        }
    }

    return <div className=''>
        <ErrorBoundary message={error} />
        {hideLabel === true ? false : <label className='form-control-label d-block' style={{fontWeight:700}} >{titulo_label}</label>}
        <div class="d-flex input-group">
            {/* <div className="input-group-text shadow-sm bg-primary" id="basic-addon1"><i class="fa-solid fa-location-dot text-white"></i></div> */}
            <div className="flex-grow-1">
                <Select
                    isClearable
                    placeholder="Localidad"
                    options={options.map(zona => ({ value: zona._id, label: `${zona.titulo} · ${zona.local_name}` }))}
                    isLoading={loading}
                    noOptionsMessage={() => "Sin opciones"}
                    loadingMessage={() => "Cargando información..."}
                    onChange={handleChange}
                    className="shadow-sm"
                />
            </div>
        </div>
        { /* <AsyncSelect 
                    cacheOptions  
                    placeholder="Buscar zona..."
                    defaultOptions={options.map(zona => ({ value: zona._id, label: `${zona.titulo} · ${zona.local_name}` }))}
                    isLoading={loading}
                    noOptionsMessage={()=>"Sin opciones"}
                    loadingMessage={()=>"Cargando información..."}
                    loadOptions={promiseOptions} 
                    onChange={handleChange}
                    className="mb-2"
/> */}
    </div>
}

export default SelectorZonas