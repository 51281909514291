const ErrorBoundary = (props) => {
    const {
        message
    } = props

    if (!message) {
        return null;
    }

    return (
        <div 
        className='px-2 py-0 mb-2'
        style={{ 
            color: 'red', 
            fontSize: '13px', 
            backgroundColor: '#f8d7da', 
            border: '1px solid #f5c6cb', 
            borderRadius: '5px',
        }}>
        {message}
        </div>
    );
    
};

export default ErrorBoundary;