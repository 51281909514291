import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";
import { toast } from "react-toastify";
import Etiquetas from "../../etiquetas";
import { TbRoute } from "react-icons/tb";
import { urlapi } from "../../../lib/backend/data";
import { FaBarcode } from "react-icons/fa";
import { estilo_last_mile } from "../../../lib/global/styles";
import ErrorBoundary from "../../errorsHandler/errorBoundary";

const GeneradorEtiquetasPersonalizado = (props) => {
    const {
        ids_orders
    } = props
    const [error, setError] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [ loadingEtiquetas, setLoadingEtiquetas ] = useState(false)
    const [dataResponse, setDataResponse] = useState(null);
    const [ loading, setLoading] = useState(false)
    const [ etiquetas, setEtiquetas ] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()

    const generarEtiquetas = () => {
        setLoadingEtiquetas(true)
        if(!ids_orders) return setLoadingEtiquetas(false)
        if(!Array.isArray(ids_orders)) return setLoadingEtiquetas(false)
        if(ids_orders.length === 0) return setLoadingEtiquetas(false)
        return fetch(`${urlapi}/bodega/etiquetas/personalizado`,{
          method:'POST',
          body: JSON.stringify({
              condicion: { _id: { $in: ids_orders } }
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(res => {
        console.log(res)
        if(!res){
          setError("Sin respuesta")
        } else if(res.errorMessage){
          setError(res.errorMessage)
        } else if(Array.isArray(res) !== false){
            setShowModal(true)
            setEtiquetas(res)
        }
        return setLoadingEtiquetas(false)
      })
      .catch(error => {
        setError(error.message)
        return setLoadingEtiquetas(false)
      })
      }
  
    
    return <div>
        <ErrorBoundary message={error} />
        <Button variant="outline-secondary" 
                disabled={loadingEtiquetas}
                className="shadow-sm mb-2 mr-2 mt-0 py-1" 
                onClick={() => { 
                    generarEtiquetas()
                }}><FaBarcode style={estilo_last_mile.icon} /> <b>{ loadingEtiquetas ? "GENERANDO..." : "GENERAR ETIQUETAS DE BULTOS"}</b></Button>
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton className="pb-0">
                    <Modal.Title className="text-primary text-center"><FaBarcode style={estilo_last_mile.icon} /> Impresión de etiquetas de bultos
                    <p className="text-muted">Seleccionaste {ids_orders.length} órdenes</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-auto border-top border">
                { etiquetas.length > 0 ? <div><Etiquetas etiquetas={etiquetas} token={token}/></div> : false }
                </Modal.Body>
            </Modal>
        
    </div>
}

export default GeneradorEtiquetasPersonalizado