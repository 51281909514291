import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import { Badge, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"

const CamposPersonalizadosEsquema = (props) => {
    const {
        viewType,
        condicion_default
    } = props
    const [ campos, setCampos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const condicion = condicion_default ? condicion_default : { }
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
        obtenerDatos()
    }, [])
    
    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/campos-personalizados/esquema`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setCampos(res)
                if(props.onFetched) props.onFetched(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = campos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        campos[i].value = value
        console.log(campos)
        return setCampos([...[], ...campos])
    }
    const eliminar = (pos) => {
        if(props.onDelete) props.onDelete(pos)
    }

    const guardarCambios = async () => {
        setLoadingGuardando(true)
        const enviar = campos.filter(c => c.value).map(campo => {
            return campo
        })
        return fetch(`${urlapi}/campos-personalizados`,{
            method:'PUT',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.success){
                toast.success(res.success)
            }
            return setLoadingGuardando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingGuardando(false)
        })
    }

    const mostrarCampos = () => {
        if(loading) return <Skeleton height={50} />

        return <div>
            <h4>Campos personalizados</h4>
            {   
                campos.map((item,i) => {
                    return <Card className="p-2 mb-2" key={`ca-${item._id}`}>
                    <p className='mb-0'>{item.nombre} <Badge variant='light'>OPCIONAL</Badge></p>
                    {item.info ? <div><p className='mb-0' style={{ fontWeight: "bold" }}>{item.info}</p> </div> : false}
                    </Card>
                })
            }
        </div>
    }

    return <div>
        {mostrarCampos()}
    </div>
}

export default CamposPersonalizadosEsquema