import { useState } from "react"
import { Accordion, Button, Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"
import { getDireccion, getDireccion1, getDireccion2 } from "../../lib/helpers/pedidos/pedidos"
import MostrarGeoDatos from "./box_geo_datos"
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch } from "react-redux"
import ErrorBoundary from "../errorsHandler/errorBoundary"

const BoxEditarGeoDatosOrdenIndividual = (props) => {
    const [ error, setError ] = useState(null)
    const [ pedido, setPedido ] = useState(props.pedido ? props.pedido : false)
    const token = props.token ? props.token : false
    const [ idLoading, setIdLoading ] = useState(false)
    const [ notificado, setNotificado ] = useState(false)
    const dispatch = useDispatch()

    if(!pedido) return false

    const submitChanges = (e,id) => {
        e.preventDefault()
        geolocalizar(id)
    }

    const geolocalizar = async (id) => {
        if(!token) return false
        setIdLoading(id)
        return fetch(`${urlapi}/ordenes/orden/re-geolocalizar`,{
            method:'POST',
            body: JSON.stringify({
                id: pedido._id,
                direccion: pedido.orden.billing.address_1,
                direccion2: pedido.orden.billing.address_2,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
          console.log(res)
          if(!res){
            setError("Sin respuesta")
          } else if(res.errorMessage){
            setError(res.errorMessage)
          } else if(res._id){
                if(res.orden){
                    if(typeof res.orden === "object"){
                        if(res.orden.geo_datos){
                            if(typeof res.orden.geo_datos === "object"){
                                if(!res.orden.geo_datos.level2){
                                    toast.warn("Se obtuvieron datos geográficos parciales,")
                                } else {
                                    toast.success("Datos geográficos obtenidos")
                                }
                                pedido.orden.geo_datos = res.orden.geo_datos
                                if(props.onSucessChange) props.onSucessChange({
                                    direccion: pedido.orden.billing.address_1,
                                    direccion2: pedido.orden.billing.address_2
                                })
                                setPedido(prev => ({...{}, ...pedido}))
                            }
                        } else {
                            setError("No se obtuvieron datos geográficos, revisa que estén correctos")
                        }
                    }
                }
          }
          return setIdLoading(false)
        })
        .catch(error => {
          setError(error.message)
          return setIdLoading(false)
        })
    }

    const handleChange = (e) => {
        const { value } = e.target
        pedido.orden.billing.address_1 = value
        if(!notificado) toast.info("No olvides guardar la dirección al terminar de editarla en el botón GUARDAR o presionando ENTER")
        setNotificado(true)
        setPedido(prev => ({...{}, ...pedido}))
    }
    
    const handleChange2 = (e) => {
        const { value } = e.target
        pedido.orden.billing.address_2 = value
        return setPedido(prev => ({...{}, ...pedido}))
    }
    
    let direccion = getDireccion1(pedido)
    let direccion2 = getDireccion2(pedido)

    return <div>
        <ErrorBoundary message={error} />
        <Accordion>
                        <Card className="p-0 mb-3 text-primary shadow-sm"  >
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'#eceef1' }} eventKey={`estado`}>
                            <div className='p-1 pl-3 pr-3'>
                            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>
                                Toca para editar la dirección
                              </Tooltip>
                            }
                            >
                               <div>
                                <div className='d-flex justify-content-between'>
                                <p className="d-block mb-0" style={{fontWeight:900}}>Dirección</p>
                                <i className="fa-solid fa-caret-down"></i>
                                </div>
                               <h6 className='hover p-0 mb-0'>{direccion} {direccion2}</h6>
                               </div>
                            </OverlayTrigger>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`estado`}>
                            <Card.Body className="py-1">
                                <div>
                                        <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                        <Tooltip>
                                            Edita la dirección y presiona enter para cambiarla
                                        </Tooltip>
                                        }
                                        >
                                            {
                                                idLoading === pedido._id ? <Spinner animation="border" /> : <form onSubmit={(e) => submitChanges(e,pedido._id)}>
                                                    <label className='form-control-label' style={{fontWeight:700}}>Dirección</label>
                                                    <input name="direccion1" className='form-control mb-2 shadow-sm' value={direccion} onChange={handleChange} />
                                                    <label className='form-control-label' style={{fontWeight:700}}>Observaciones de la dirección</label>
                                                    <input name="direccion2" className='form-control mb-2 shadow-sm' value={direccion2} onChange={handleChange2} />
                                                    <Button size="sm" variant="primary" type="submit" className='w-100 shadow-sm'><b>Guardar cambios en Dirección</b></Button>
                                                </form>
                                            }
                                        </OverlayTrigger>
                                        <MostrarGeoDatos pedido={pedido} />
                                    </div>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
        </Accordion>
    </div>
}

export default BoxEditarGeoDatosOrdenIndividual