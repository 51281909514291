import Map, { FullscreenControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import Pin from '../../subComponents/mapas/pin';
import { Badge } from 'react-bootstrap';
import polyline from '@mapbox/polyline';
import { url_images } from '../../lib/global/data';

const MapaDetallePedidoTrackingPage = (props) => {
    const {
        height,
        markers,
        routesConductor,
        ubicacionConductor
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const [ routes, setRoutes ] = useState([]) 
    const pin_truck = <img style={{ height: 40, margin: "0 auto", display: "block"}} src={`${url_images}/truck-up-view.png`} />
    const [ focusVisualization, setFocusVisualization ] = useState(false)
    let defaultViewState = { longitude: -70.670112, latitude: -33.447471, zoom: 10 }
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const mostrarUbicacionConductor = () => {
        if(!ubicacionConductor) return false
        if(!ubicacionConductor.location) return false
        if(!ubicacionConductor.location.coordinates) return false
        if(ubicacionConductor.location.coordinates.length < 2) return false
        return <Marker
            key={"ubicacion-conductor"}
            longitude={ubicacionConductor.location.coordinates[0]}
            latitude={ubicacionConductor.location.coordinates[1]}
            >
            <div style={{ textAlign: "center" }}>
                {pin_truck}
            { ubicacionConductor.patente ? <Badge color='primary'>{ubicacionConductor.patente}</Badge> : false }
            </div>
        </Marker>
    }


    const handleMouseEnter = (key) => {
        setFocusVisualization(key)
    }

    const handleMouseLeave = () => {
        setFocusVisualization(false)
    }

    

    const mostrarTrayecto = () => {
        if(focusVisualization) if(focusVisualization !== "analisis") return false
        if(!routesConductor) return
        return routesConductor.map((ruta,ir) => {

            let pintar = ruta.selected === true
            const decodedCoords = polyline.decode(ruta.geometry)
            if(ruta.checked !== true){
                const hay_seleccionado = routes.filter(r => r.selected === true).length
                if(hay_seleccionado > 0) if(ruta.selected !== true) return false
            } else {
                pintar = true
            }

            const color = "blue"
            
            return <Source
                id={`route-${ir}-conductor`}
                type='geojson'
                data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: decodedCoords.map(co => [co[1], co[0]])
                    }
                }}
            >
                <Layer
                id={`route-layer-${ir}-conductor`}
                type="line"
                layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                }}                
                paint={{
                    'line-color': color,
                    'line-width': 2,
                    'line-opacity': 0.75,
                    'line-dasharray': [2, 2]
                }}
            />
            </Source>
        })
        
    }
    if(!marcadores) return false
    if(Array.isArray(marcadores) !== true) return false
    if(marcadores.length < 1) return false

    defaultViewState.longitude = marcadores[0].lng
    defaultViewState.latitude = marcadores[0].lat

    return <Map
    ref={mapRef}
    {...defaultViewState}
    scrollZoom={false}
    dragPan={false}
    // onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 500}}
    pitch={25}
    mapStyle="mapbox://styles/mapbox/light-v9"
    mapboxAccessToken={mapbox_token}
  >
    <FullscreenControl position="bottom-right" />
    <NavigationControl position="bottom-right" />
    {mostrarTrayecto()}
    {
        marcadores.map(marker => {
            const content = (
                <div>
                    <p>{marker.fecha_}</p>
                </div>
            )
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div>
                    <Pin />
                </div>
            </Marker>
        })
    }
    {mostrarUbicacionConductor()}
  </Map>
}

export default MapaDetallePedidoTrackingPage