import Moment from 'react-moment'
import 'moment/locale/es';
import { Badge, Button, Card, Col, Dropdown, DropdownButton, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useEffect, useState } from "react";
import { canalEstadosString, getDireccion, getEmail, getNombreDestinatario, getTelefono, obtenerGeoDatosGoogle } from "../../lib/helpers/pedidos/pedidos";
import { dateToYMDFormat, fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import BoxConductor from "../conductores/box_conductor";
import TablaProductos from './tabla_productos'
import EmitirEstadoModal from '../estados_carga/emitir_modal'
import AccionesModal from "./acciones_modal";
import LogsListado from '../integraciones/logs/listado';
import { centerMapCoordinates } from '../../lib/helpers/maps';
import GeneradorEtiquetas from '../etiquetas/generador_etiquetas';
import BoxEditarGeoDatosOrdenIndividual from './box_editar_geodata_individual';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import MapaDetallePedido from '../../components/Mapas/mapa_detalle_pedido';
import SinDatos from '../general/sin_registros';
import { formatDateHoy } from '../../lib/helpers/opcionesfecha';
import MetaDatosOrden from './metadata/metadatos_orden';
import { AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai'
import { Esquema } from '../../lib/esquemas/tablas';
import { cerrarSesion } from '../../redux/actions/session';
import OrdenZonaListado from './ordenes_zonas/list';
import LoadingOrders from '../general/loadingAnimations/loadingOrders';
import SelectorBodega from '../bodega/bodegas/selector_bodega';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { confirmAlert } from 'react-confirm-alert';
import { esNumero } from '../../lib/helpers/main';
import TiposServicioSelector from './tipos_servicio/selector';
import IngresoCourrier from './courriers/ingreso_courrier';
import { rutas } from '../../lib/routes/routes';
import { FaAngleLeft, FaRegFileAlt } from 'react-icons/fa';
import EtiquetaCourrier from './courriers/etiqueta_courriers';
import OrdenTicketsListado from './ordenes_tickets/list';
import MediosBox from '../general/mediosbox';
import NotificacionesRelacionador from '../notificaciones/notificaciones_adicionales';
import CompartirWhatsapp from './whatsapp_share';
import { DateTime } from 'luxon';
import { MdOpenInNew } from 'react-icons/md';


// import SelectorBodegaEditarOrden from '../bodega/bodegas/selector_bodega_editar_orden';

const DetallesPedido = (props) => {
    const [ pedido, setPedido ] = useState(false)
    const [ pedidoEditar, setPedidoEditar ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(true)
    const [ estados, setEstados ] = useState([])
    const [ rutasAsociadas, setRutasAsociadas ] = useState([])
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ marcadores, setMarcadores ] = useState([])
    const [ lineaTiempo, setLineaTiempo ] = useState([])
    const [ cambiosOrden, setCambiosOrden ] = useState({})
    const [ eliminandoEstado, setEliminandoEstado ] = useState(false)
    const [ loadingFicha, setLoadingFicha ] = useState(false)
    const [ instruccionesNotificacion, setInstruccionesNotificacion ] = useState([])
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const token = session.tokenSession
    const [ mostrarBodegas, setMostrarBodegas ] = useState(false)
    const { id } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)

    console.log("PEDIDO:", pedido)

    // console.log("BODEGA:", bodegaOrigen)

    const obtenerPedido = async () => {
        if(!id) return setLoadingDetalle(false)
        setLoadingDetalle(true)
        return fetch(`${urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
              if(res.rutas_asociadas) setRutasAsociadas(res.rutas_asociadas)
              if(res.notificaciones_especiales) if(Array.isArray(res.notificaciones_especiales)) setInstruccionesNotificacion(res.notificaciones_especiales)
                if(res.orden) {
                    if(res.orden?.linea_tiempo){
                      setLineaTiempo(res.orden?.linea_tiempo)
                      
                    }
                    setPedido(res.orden)
                    setPedidoEditar(res.orden)
                    setEtiquetas(res.etiquetas)
                  }
                  let puntos = []
                  if(res.estados) setEstados(res.estados)
                  if(res.orden){
                    if(res.orden.location){
                      if(Array.isArray(res.orden.location.coordinates)){
                          if(res.orden.location.coordinates.length > 0){
                            puntos.push({
                              lat: res.orden.location.coordinates[1],
                              lng: res.orden.location.coordinates[0],
                              ref: res.orden.pedido,
                              destinatario: getNombreDestinatario(res.orden)
                            })
                            setMarcadores(puntos)
                          }
                      }
                    }
                  }
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }
    
    useEffect(() => {
      obtenerPedido()
    },[])

    console.log("pedidoEditar:", pedidoEditar)                     


    const showComponentByTipoFormulario = (campo) =>{
      // console.log("CAMPO:", campo)
        switch (campo.tipo_accion) {
          case 'campo-texto':

            if(!campo.valor) return false
            const ocultar = esNumero(campo.valor)
            if(ocultar === true) return false

            return <div className="mb-3 text-primary">
              <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':

            if(!campo.valor) return false
            const ocultar_ = esNumero(campo.valor)
            if(ocultar_ === true) return false

            return <div className="mb-3 text-primary">
              <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div className="mb-3 text-primary">
              <h6 className='mb-2' style={{fontWeight:900}}>{campo.titulo}</h6>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <a href={foto.url} target="_blank" className='pr-2'>
                    <img src={foto.url} style={{  height: 70 }} ></img>
                    </a>
                    </div>
                }) : <Col md={12}><p className='text-danger' style={{fontWeight:700}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin información</p></Col>
              }
            </div>
          case 'firma-digital':
            if(!campo.valor) return false
            const isNumero = esNumero(campo.valor)
            if(isNumero === true) return false
    
            return <div className="mb-3 text-primary">
                <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
                <p className='mb-0'>
                    {campo.valor 
                        ? <img src={campo.valor} alt="Firma digital" style={{maxWidth: '25%'}} /> 
                        : 'Sin información'
                    }
                </p>                  
            </div>
          default:
            break;
        }
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {
      if(props.actualizarEstadoListado) return props.actualizarEstadoListado(estado_entrega, sub_estado_entrega)
    }

    const actualizarEstadoActual = (data) => {
        const actualizar_estado = {
          codigo_estado: data.codigo_estado,
          titulo: data.estado,
          color: data.estado_color ? data.estado_color : '#babbbb',
        }
        
        const actualizar_sub_estado = {
            codigo_estado: data.codigo_subestado,
            titulo: data.subestado,
            color: data.subestado_color ? data.subestado_color : '#babbbb',
        }
        actualizarEstadoListado(actualizar_estado, actualizar_sub_estado)
        return setEstados(prev => [ ...prev, ...[data] ])
    }

    const onSucessChange = (data) => {
      const { direccion, direccion2 } = data
      if(!direccion || !direccion2) return false
      pedido.orden.billing.address_1 = direccion
      pedido.orden.billing.address_2 = direccion2
      return setPedido(prev => ({...{}, ...pedido}))
    }

    const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {

          const mostrar = showComponentByTipoFormulario(meta)
          if(!mostrar) return false
          return <div key={`meta-${i}`}>
            <hr />
            {mostrar}
          </div>
        })
      }

      const mostrarCamposPersonalizados = () => {
        if(!pedido.meta_data) return <SinDatos />
        if(Array.isArray(pedido.meta_data) !== true ) return <SinDatos />
        if(pedido.meta_data.length < 1) return <SinDatos />
        return <div>
            <Row>
            {
                pedido.meta_data.map((val,pos) => {
                    return <Col className='mb-3' md={3} xs={6} key={`campo-${pos}`}>
                        <label className="form-control-label mb-0 d-block" style={{ fontSize: 14}}><b>{val.key ? val.key.toUpperCase() : "SIN INFORMACIÓN"}</b></label>
                        <input className='form-control' defaultValue={val.value} />
                    </Col>
                  })
            }
            </Row>
        </div>
      }

      const seleccionarBodegaOrigen = async (data) => {
        const geo_informacion = await obtenerGeoDatosGoogle(data ? data.geodata : [] )
        console.log("geoinformacion:", geo_informacion)
        console.log("DATA FUNCION Bodega: ", data)
        let bodegaOrigen = data
        let target = JSON.parse( JSON.stringify(pedidoEditar) )
        target.orden.origen = {
          address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : "",
          first_name: bodegaOrigen.titulo ? bodegaOrigen.titulo : "",
          ...geo_informacion
      }
        target.orden.geo_datos_origen = {
          level1: geo_informacion.level1 ?  geo_informacion.level1 : "",
          level2: geo_informacion.level2 ?  geo_informacion.level2 : "",
          pais: geo_informacion.pais ? geo_informacion.pais : "",
          level3: geo_informacion.level3 ? geo_informacion.level3 : "",
          address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : ""}
          
                            
        return setPedidoEditar(target)

      }



      const handleChangeOrdenBilling = (e) => {
        const { name, value } = e.target
        let target = JSON.parse( JSON.stringify(pedidoEditar) )
        return setPedidoEditar(prev => {
          let target = {...prev}
          target.orden.billing[name] = value
          return target
        })
      }

      const handleChangeOrden = (e) => {
        const { name, value } = e.target
        console.log(value)
        // let target = JSON.parse( JSON.stringify(pedidoEditar) )
        // target[name] = value
        return setPedidoEditar(prev => {
          let target = {...prev}
          target[name] = value
          // if(target.fecha_finalizado === "") target.finalizado = false
          // if(name === "fecha_finalizado") if(value) target[name] = DateTime.fromISO(value).toUTC().toISO()
          return target
        })
      }

      const guardarCambiosFicha = async () => {
        setLoadingFicha(id)
        setLoading(true)
        return fetch(`${urlapi}/ordenes`,{
            method:'PUT',
            body: JSON.stringify(pedidoEditar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
          console.log(res)
          if(!res){
            toast.error("Sin respuesta")
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
            setPedido(res)  
            toast.success("Edición Exitosa!")          
          }
          
          setLoading(false)
          setMostrarBodegas(false)
          return setLoadingFicha(false)
        })
        .catch(error => {
          toast.error(error.message)
          return setLoadingFicha(false)
        })

      }
      
    const agregarProductoCrear = () => {
      const id_orden = pedido._id
        setLoading(true)
        return fetch(`${urlapi}/ordenes/productos/orden`,{
            method:'POST',
            body: JSON.stringify({
                id_orden
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if((res) !== false){
                     pedido.productos.push(res)           
           toast.success("Creado con exito")
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const eliminarEstado = async (idestado) => {
      setEliminandoEstado(true)
      return fetch(`${urlapi}/estadoscarga?id=${idestado}`, {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer: ${token}`
       }
      })
      .then(pros => pros.json())
      .then(async pros => {
          if(!pros){
            toast.error("Sin datos del servidor")
          } else if(pros.errorMessage){
            toast.error(pros.errorMessage)
          } else if(pros._id){
            toast.success('Borrado exitosamente')

            setPedido(prev => {
              let actual = {...prev}
              if(typeof pros.estado_entrega !== "undefined" || typeof pros.sub_estado_entrega !== "undefined"){
                actual.estado_entrega = pros.estado_entrega
                actual.sub_estado_entrega = pros.sub_estado_entrega
              }
              return {...{}, ...actual}
            })

            setEstados(prev => {
              let actual = [...prev]
              const i = actual.findIndex(e => e._id === idestado)
              if(i > -1) actual.splice(i,1)
              return [...[], ...actual]
            })
            
          }
          return setEliminandoEstado(false)
      })
      .catch(async error => {
        setEliminandoEstado(false)
        return toast.error("No pudimos consultar la información, intente de nuevo recargando la página")
      })
    }

      const agregarCampoPerzonalizado = () => {
        if(!pedido.meta_data) pedido.meta_data = []
        pedido.meta_data.push({})
        return setPedido({ ...{}, ...pedido })
      }

      const onDelete = (pos) => {
        pedido.productos.splice(pos,1)

        return setPedido({...{}, ...pedido})
      }

      const onCreate = () => {
        pedido.productos.push()
        return setPedido({...{}, ...pedido})
      }

      const solicitarEliminarEstado = (idestado) => {
        return confirmAlert({
          title: '¿Confirmas esta acción?',
          message: `Estás a punto de eliminar este estado de carga, esta acción no se puede deshacer`,
          buttons: [
            {
              label: 'CONFIRMAR',
              onClick: () => eliminarEstado(idestado)
            },
            {
              label: 'CANCELAR',
              onClick: () => false
            },
          ],
        })
      }

      const mostrarLineaTiempo = () => {
        if(!lineaTiempo) return false
        if(!lineaTiempo.hitos) return false
      

        return <div className='py-2 mb-3 text-center p-3'>
            <Row>
            {
                lineaTiempo.hitos.map((evento,i) => {
                  const emitidos = lineaTiempo.hitos.filter((hito,pos) => hito.choosen === true && pos > i).length > 0
                    // if(evento.opcional_linea_tiempo === true && !evento.emitido) return false
                    return <Col key={evento._id}>
                        {evento.choosen === true || emitidos ? <i className="fa-solid fa-circle-check text-success" style={{fontSize:20}}></i> : <i className="fa-solid fa-clock text-secondary" style={{fontSize:20}}></i>}
                        <h6 className='mb-0' style={{fontWeight:900}}>{evento.titulo}</h6>
                    </Col>
                })
            }
            </Row>
            </div>
      }

      const mostrarInstruccionesNotificacion = () => {
        if(instruccionesNotificacion.length < 1) return false
        return <Card className='p-3 mt-3'>
          <h4><b>Notificaciones adicionales configuradas</b></h4>
          {
            instruccionesNotificacion.map((instruccion,i) => {
              return <div key={`instr-${i}`}>
                <h6>Tipo de notificación <Badge variant='dark'>{instruccion.type_target}</Badge></h6>
                {
                  instruccion.targets.map((target, itarget) => {
                    let item = ''
                    if(instruccion.type_target === "email"){
                      item = target.email
                    }
                    return <div key={`${i}-${itarget}-notificacion`}>{item}</div>
                  })
                }
                { instruccionesNotificacion.length === (i+1) ? false :  <hr className='mt-3 mb-3' /> }
              </div>
            })
          }
        </Card>
      }

      const mostrarRutas = () => {
        if(rutasAsociadas.length < 1) return false
        return <div>
          <h5 className='mb-0'><FaRegFileAlt /> <b>Rutas asociadas</b></h5>
          {
            rutasAsociadas.map(e => <Link to={`/${rutas.rutas.slug}?id=${e._id}`} key={e._id}><Badge variant="dark">RUTA {e.id} <MdOpenInNew /></Badge></Link>)
          }
        </div>
      }
      
      const mostrarInformacion = () => {

 

        if(loadingDetalle) return <LoadingOrders />

        if(!pedido) return <div className="container-fluid">
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
            <img src="images/pedidos.png" style={{ width: "50%" }} />
            <h4>No hay suficientes datos para mostrar esta sección</h4>
            </Col>
        </Row>
        </div>

        let defaultAccordion = false

        if(estados.length > 0) defaultAccordion = `estado-${estados.length}`

        let titulo_estado           = ''
        let titulo_sub_estado       = ''
        let color_estado            = '#bebebe'

        if(pedido.estado_entrega){
            if(typeof pedido.estado_entrega === 'object'){
                if(pedido.estado_entrega.titulo) titulo_estado = pedido.estado_entrega.titulo
                if(pedido.estado_entrega.color) color_estado = pedido.estado_entrega.color
            }
        }
    
        if(pedido.sub_estado_entrega){
            if(typeof pedido.sub_estado_entrega === 'object'){
                if(pedido.sub_estado_entrega.titulo) titulo_sub_estado = pedido.sub_estado_entrega.titulo
            }
        }

        let string_estado = `${titulo_estado} ${titulo_sub_estado}`
        if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"


        return <div className='text-primary'>
          <Link to={`/${rutas.ordenes.slug}`}><FaAngleLeft /> Volver a órdenes</Link>
                <h4 style={{fontWeight:900}}>ORDEN {pedido.pedido}</h4>
                <Badge variant='dark' style={{ textTransform: "uppercase" }}>CREADO {fechaATexto(pedido.createdAt)}</Badge>
                <div style={{ fontSize:9, textTransform: "uppercase", display: "inline-block", fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado}</div>
                
                {/* <div className="container-fluid" style={{ position: "relative", zIndex: 9 }}> */}
                    
            <Row>
            <Col md={12} className='mb-3 text-primary'>
            {/* <Card className='p-2 shadow-sm ' > */}
                <Row>
                <Col md={12}>
                <Card className='p-2 text-center shadow-sm'>
                <MapaDetallePedido markers={marcadores} />
                <AccionesModal token={token} pedido={pedido} />
                <Col md={12} xs={12} className='p-0 mt-3'>{mostrarLineaTiempo()}</Col>
                <div className='py-2 mb-3 text-center'>
                <div className='d-flex justify-content-between px-4'>
                {/* {mostrarLineaTiempo()} */}
                <h5 style={{ fontWeight:900}}><i className="fa-solid fa-clipboard-list"></i> Itinerario y detalles del conductor</h5>
                <EmitirEstadoModal propietario={pedido.propietario} token={token} pedido={pedido} onStatusEmited={(data) => actualizarEstadoActual(data)} />
                </div>

                <Accordion defaultActiveKey={''} className="mb-3">
                {
                    estados.map((estado,i) => {

                      let lat = 0
                      let lng = 0

                      if(estado.location){
                        if(Array.isArray(estado.location.coordinates) !== false){
                          if(estado.location.coordinates.length > 1){
                            lat = estado.location.coordinates[0]
                            lng = estado.location.coordinates[1]
                          }
                        }
                      }

                      let reunir_fotos = []
                      const fotos = estado.metadatos.filter(meta => meta.tipo_accion === 'carga-imagenes').map(meta => {
                        if(meta.valores) if(Array.isArray(meta.valores)){
                          for( const valor of meta.valores ){
                            if(valor.url) reunir_fotos.push(valor.url)
                          }
                        }
                      })

                        return <Card className="p-0 mx-3 shadow-sm text-left">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'rgba(27, 54, 101, 0.1)' }} eventKey={`estado-${i+1}`}>
                            <div className="pl-2 pb-0">
                              <Row>
                              <Col xs={8}>
                                <span style={{ fontWeight: 700 }}>{fechaATexto(estado.fecha)} · <a href={`https://google.com/maps?q=${lng},${lat}`} target="_blank"><i className="fas text-primary fa-map-marker-alt"></i></a></span>
                                <p className="mb-0" style={{fontWeight:900}}><i className="fa-solid fa-circle" style={{ color: estado.estado_color }}></i> <b>{estado.estado}</b> <b>{estado.subestado}</b></p>
                              </Col>
                              <Col xs={4} className='text-right'>
                              { reunir_fotos.map((imagen,ifoto) => <img className='mr-1' src={imagen} style={{ display: "inline", height: 40, borderRadius: 5 }} /> ) }
                              </Col>
                              </Row>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`estado-${i+1}`}>
                            <Card.Body className='text-primary' style={{fontWeight:700}}>
                              <p className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</p>
                              <p className="mb-0"><b>Por :</b> {estado.identificador_creador}</p>
                                {
                                    mostrarMetaDatosSubEstado(estado.metadatos)
                                }
                                {
                                  (i+1) === estados.length ? <div>
                                    {
                                      eliminandoEstado ? <div className="loadingbox">
                                        <Spinner animation='border' />
                                      </div> : <button className='btn btn-sm mt-3 btn-outline-danger' onClick={() => solicitarEliminarEstado(estado._id)}>ELIMINAR ESTADO</button>
                                    }
                                    
                                  </div> : false
                                }
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    })
                }
                </Accordion>
                </div>
                </Card>
                </Col>
                </Row>

                {/* </Card> */}
            </Col>


            <Col md={12} className="pt-0">
            <MediosBox id_destino={id} />
                <Row>
                    <Col md={12}>
                 
                        <Row>
                          <Col md={12} className='text-primary'>

                          <Tabs defaultActiveKey="info" className='border-0'>
                          
                          <Tab eventKey="info" title="Información general" className=''>
                            <div className="shadow-sm p-4 mb-3 border rounded">

                          {  loading === true ? false :

                          <div>
                            <Row>
                              <Col md={6}>
                                <div>
                                    <h5 style={{fontWeight:900}}><i className="fa-solid fa-location-dot"></i> Datos geográficos</h5>

                                <Button  variant='secondary' onClick={() => setMostrarBodegas(!mostrarBodegas)} className='my-2 shadow-sm' >Editar localidad de origen</Button>

                                { mostrarBodegas ?   <Col md={12}><SelectorBodega onChange={(data) => seleccionarBodegaOrigen(data)} pedido={pedido?.orden?.origen?.first_name}/></Col> : false }
                                    <BoxEditarGeoDatosOrdenIndividual pedido={pedido} token={token} onSucessChange={(data) => onSucessChange(data)} />

                                <div className="mb-3 card p-3" style={{ backgroundColor: "#eceef1" }}>
                                <BoxConductor conductor={pedido.conductor} />
                                
                                    </div>
                                    </div>

                                    {mostrarRutas()}
                                </Col>


                                <Col md={6}>
                                <div className="">
                                <h5 style={{fontWeight:900}}><i className="fa-solid fa-table-list"></i> Ficha</h5>
                                <Row>
                                <Col md={12} className='mb-3'>
                                    <TiposServicioSelector defaultValue={pedido.tiposervicio ? { _id: pedido.tiposervicio } : false } onChangeValue={(data) => handleChangeOrden({ target: { name: "tiposervicio", value: data?._id } })} />
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}>Destinatario</label>
                                    <input className='form-control shadow-sm' name="first_name" defaultValue={getNombreDestinatario(pedido)} onChange={handleChangeOrdenBilling} />
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}>Email</label>
                                    <input className='form-control shadow-sm' name="email" defaultValue={getEmail(pedido)} onChange={handleChangeOrdenBilling} />
                                </Col>
                                <Col md={4} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}>Teléfono</label>
                                    <input className='form-control shadow-sm' name="phone" defaultValue={getTelefono(pedido)} onChange={handleChangeOrdenBilling} />
                                </Col>
                                <Col md={12} className='mb-3'>
                                    <label className="mb-0 form-control-label" style={{fontWeight:700}}>Observaciones</label>
                                    <input className='form-control shadow-sm' name="nota" defaultValue={pedido.nota} onChange={handleChangeOrden} />
                                </Col>
                                <Col md={12}>
                                  <label className='form-control-label d-block' style={{fontWeight:700}}>Selección de productos</label>
                                  <select className='form-control mb-3 shadow-sm' defaultValue={pedido.tipo_seleccion} name="tipo_seleccion" onChange={handleChangeOrden}>
                                      <option value="">Seleccione</option>
                                      <option value="simple">Simple</option>
                                      <option value="avanzado">Avanzado</option>
                                  </select>
                              </Col>
                                {/* <Col md={12} className='mb-3'>
                                    <h6><i className="fas fa-clock"></i> Fecha de compromiso</h6>
                                    </Col> */}

                                <Col md={6} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}>Fecha mínima de entrega</label>
                                    <input type='date' className='form-control shadow-sm' name="fecha_min_entrega" defaultValue={formatDateHoy(pedido.fecha_min_entrega)} onChange={handleChangeOrden} />
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}>Fecha tope de entrega</label>
                                    <input type='date' className='form-control shadow-sm' name="fecha_max_entrega" defaultValue={dateToYMDFormat(pedido.fecha_max_entrega)} onChange={handleChangeOrden} />
                                </Col>                   
                                <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Peso en Kilogramos
                              </Tooltip>
                            }
                            >
                            <div>
                            <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}>Peso</label>
                            <input type="number" className='form-control' name="peso" defaultValue={pedido.peso} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Metros Cúbicos
                              </Tooltip>
                            }
                            >
                            <div>
                            <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}><b>Volumen</b></label>
                            <input type="number" className='form-control' name="volumen" defaultValue={pedido.volumen} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Bultos secos
                              </Tooltip>
                            }
                            >
                            <div>
                              <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}><b>Bultos</b></label>
                              <input type="number" className='form-control' name="bultos" defaultValue={pedido.bultos} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Bultos regrigerados
                              </Tooltip>
                            }
                            >
                            <div>
                              <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}><b>Refrigerados</b></label>
                              <input type="number" className='form-control' name="refrigerado" defaultValue={pedido.refrigerado} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                          <Col  className="mb-3">
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >
                                Bultos congelados
                              </Tooltip>
                            }
                            >
                            <div>
                              <label className="mb-0 form-control-label d-block" style={{fontWeight:700}}><b>Congelados</b></label>
                              <input type="number" className='form-control' name="congelado" defaultValue={pedido.congelado} onChange={handleChangeOrden} />
                            </div>
                          </OverlayTrigger>
                          </Col>
                            </Row>
                            </div>
                                </Col>
                                
                  
                            </Row>
                            { loadingFicha ? <Spinner animation='border' /> : <Button className='shadow-sm my-2 w-100' variant="primary" onClick={() => guardarCambiosFicha()} ><b>GUARDAR CAMBIOS DE LA FICHA</b></Button>}

                            <NotificacionesRelacionador id_source={pedido._id} type_source="orden" />
                            {/* {mostrarInstruccionesNotificacion()} */}

                            </div>
      }

                
                            </div>
                          </Tab>
                  
                          <Tab eventKey="products" title="Productos">
                          <div className="shadow-sm p-2 mb-3 border rounded">

                            <Row>
                            
                            </Row>
                            {/* <h6 className='mb-1'>Productos</h6>  */}
                            <Button size='sm' variant='secondary' className='shadow-sm mb-3 mt-3 w-25' onClick={() => agregarProductoCrear()}><b><i className="fa-solid fa-plus"></i> AGREGAR PRODUCTO</b></Button> 
                            {   loading === true ? <Spinner animation='border' className='mt-5 ml-3' /> :
                             <TablaProductos onDelete={pos => onDelete(pos)} productos={pedido.productos} id_orden={id} /> 
                             }
                             </div>
                          </Tab>
                          
                          
                          <Tab eventKey="zonas" title="Ruta de tránsito">
                          <div className="shadow-sm p-2 mb-3 border rounded"><OrdenZonaListado id_orden={pedido._id} /></div>
                          </Tab>
                          
                          <Tab eventKey="tickets" title="Tickets">
                          <div className="shadow-sm p-2 mb-3 border rounded"><OrdenTicketsListado id_orden={pedido._id} /></div>
                          </Tab>


                          <Tab eventKey="home" title="Campos personalizados">
                          <div className="shadow-sm p-2 mb-3 border rounded">

                             <MetaDatosOrden id_orden={id} onDelete={pos => onDelete(pos)} />
                          </div>
                          </Tab>


                          <Tab eventKey="webhooks" title="Webhooks">
                          <div className="shadow-sm p-2 mb-3 border rounded">

                            <LogsListado token={token} condicion_default={{ id_recurso: pedido._id }} />
                          </div>
                          </Tab>


                          <Tab eventKey="etiquetas" title="Etiquetas">
                          <div className="shadow-sm p-2 mb-3 border rounded">
                          <Row>
                                <Col md={3}>
                                    <GeneradorEtiquetas idrecurso={pedido._id} etiquetas={etiquetas} token={token} />
                                </Col>
                            </Row>
                            </div>
                          </Tab>
                          <Tab eventKey="courriers" title="Courriers">
                          <div className="shadow-sm p-2 mb-3 border rounded">
                          <IngresoCourrier id_pedido={pedido._id} />
                            </div>
                          </Tab>
                          <Tab eventKey="etiqueta-courriers" title="Etiqueta Courriers">
                          <div className="shadow-sm p-2 mb-3 border rounded">
                          <EtiquetaCourrier id_pedido={id} />
                            </div>
                          </Tab>
                          <Tab eventKey="whatsapp" title="Compartir tracking">
                          <div className="shadow-sm p-2 mb-3 border rounded">
                            <CompartirWhatsapp id_orden={id} />
                            </div>
                          </Tab>
                        </Tabs>

                            
                          </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
       
        </div>
        // </div>
      }

    return mostrarInformacion()
}

export default DetallesPedido