import Moment from 'react-moment'
import 'moment/locale/es';
import { useEffect, useState } from "react";
import Header from '../../../components/Header';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import PantallaCargando from '../../general/pantalla_cargando';
import SinDatos from '../../general/sin_registros';
import { urlapi } from '../../../lib/backend/data';
import { Accordion, Badge, Button, ButtonGroup, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap';
import socket from '../../../lib/websockets';
import { url_images } from '../../../lib/global/data';
import { AiOutlineDrag } from 'react-icons/ai';
import { BsFileEarmarkCode } from 'react-icons/bs';
import { cerrarSesion } from '../../../redux/actions/session';
import { BiPaste } from 'react-icons/bi';
import { GoProjectSymlink } from 'react-icons/go';
import { FaArrowRight } from 'react-icons/fa';
import { MdContentPasteGo, MdPreview } from 'react-icons/md';
import { IoIosSave } from 'react-icons/io';
import { confirmAlert } from 'react-confirm-alert';
import { rutas } from '../../../lib/routes/routes';
import HelperIntegraciones from '../../general/helperIntegraciones';
import EventosConexionListado from './eventos/listado';
import ConditionalEditor from './campo_acciones_avanzadas';
import FunctionAdder from './campo_funciones';
import ModalAgregarOpciones from './agregar_opcion';
import { IoClose } from 'react-icons/io5';
import Concatenaciones from '../../general/concatenaciones.tsx';

const formularios = {
    crear_orden: {
        direccion: {
            label: "Dirección",
            description: "Aquí va la dirección de destino",
            required: true
        },
        pedido: {
            label: "Referencia única",
            description: "Referencia única de la orden, este campo no se puede repetir",
            required: true
        },
        destinatario: {
            label: "Destinatario",
            description: "Nombre del destinatario de la craga",
            required: true
        },
        direccion_2: {
            label: "Observaciones de la dirección",
            description: "Observaciones de la dirección"
        },
        telefono: {
            label: "Teléfono",
            description: "Teléfono en formato +5612341234"
        },
        bultos: {
            label: "Cantidad de bultos secos",
            description: "Cantidad de bultos secos"
        },
        email: {
            label: "Email",
            description: "Email destinatario",
            required: true
        },
        nota: {
            label: "Notas",
            description: "Notas de la orden"
        },
        peso: {
            label: "Peso",
            description: "Peso en KG"
        },
    },
    crear_producto: {
        description: {
            label: "Título",
            description: "Título del producto",
        },
        codigo_item: {
            label: "Código",
            description: "Código del producto",
        },
        precio: {
            label: "Precio",
            description: "Precio del producto",
        },
        peso: {
            label: "Peso",
            description: "Peso del producto",
        },
        ancho: {
            label: "Ancho",
            description: "Ancho del producto",
        },
        alto: {
            label: "Alto",
            description: "Alto del producto",
        },
        largo: {
            label: "Largo",
            description: "Largo del producto",
        },
        price:{
            label: "Precio",
            description: "Precio del producto",
        }
    },
    editar_producto: {
        description: {
            label: "Título",
            description: "Título del producto",
        },
        codigo_item: {
            label: "Código",
            description: "Código del producto",
        },
        precio: {
            label: "Precio",
            description: "Precio del producto",
        },
        peso: {
            label: "Peso",
            description: "Peso del producto",
        },
        ancho: {
            label: "Ancho",
            description: "Ancho del producto",
        },
        alto: {
            label: "Alto",
            description: "Alto del producto",
        },
        largo: {
            label: "Largo",
            description: "Largo del producto",
        },
        price:{
            label: "Precio",
            description: "Precio del producto",
        }
    }
}

const DetallesApiless = (props) => {
    const [ hook , setHook ] = useState(false)
    const [ esquema, setEsquema ] = useState(false)
    const [ loadingEsquemaDestino, setLoadingEsquemaDestino ] = useState(false)
    const [ campoSeleccionado, setCampoSeleccionado ] = useState(false)
    const [ campoIterable, setCampoIterable ] = useState(false)
    const [ showModalPreview, setShowModalPreview ] = useState(false)
    const [ accion, setAccion ] = useState(false)
    const [ esquemaDestinoEjemplo, setEsquemaDestinoEjemplo ] = useState(false)
    const [ esquemaDestino, setEsquemaDestino ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(true)
    const [ loadingCampos, setLoadingCampos ] = useState(true)
    const [ loadingEliminando, setLoadingEliminando ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        obtenerWebhooks()
        socket.on(id, e => setEsquema(e.body))
        return () => {
            socket?.off(id)
        }
    }, [])
    
    const obtenerCamposPersonalizados = async () => {
        setLoadingCampos(true)
        return fetch(`${urlapi}/campos-personalizados/ordenes/esquema`,{
            method:'POST',
            body: JSON.stringify({
                condicion: {},
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setAccion(prev => {
                    let actual = { ...prev }
                    for( const field of res ){
                        if(!actual[field.slug]){
                            actual[field.slug] = {
                                label: field.nombre,
                                description: "Este es un campo personalizado de la orden", 
                            }
                        }
                    }
                    return actual
                })
            }
            return setLoadingCampos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCampos(false)
        })
    }

    const obtenerWebhooks = () => {
        if(!id) return setLoadingDetalle(false)
        return fetch(`${urlapi}/integraciones/conexiones?id=${id}`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(res => { 
            if(!res){
                toast.error("Sin datos del servidor")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( res._id ){
                setHook(res)
                if(res.evento) setEsquema(res.evento.body)
                if(res.esquema) {
                    const name_accion = res.esquema?.accion_name
                    if(formularios[name_accion]){
                       const llaves = Object.keys(formularios[name_accion])
                       const esquemaActual = res.esquema
                       const llavesEsquemaActual = Object.keys(esquemaActual)
                       const nuevosAgregar = llaves.filter(e => !llavesEsquemaActual.includes(e))
                       for( const llave of  nuevosAgregar ){
                            esquemaActual[llave] = formularios[name_accion][llave]
                       }
                       setAccion(esquemaActual)
                       
                    }
                }
                setTimeout(() => {
                    obtenerCamposPersonalizados()
                }, 200);
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingDetalle(false)
        })
    }

    const handleChangeOrigen = () => {
        return <div>
            
        </div>
    }

    const SinDatos = () => {
        return <div>
            <img src={`${url_images}/animations/App development.gif`} style={{ maxWidth: 300, marginBottom: -30, marginTop: -30 }} />
            <h4 style={{ fontWeight: "bold" }} className='mb-0'>Aquí verás tus datos de prueba</h4>
            <p className='mb-0'>Utiliza un cliente HTTP como POSTMAN para enviar datos a este endpoint</p>
            <p style={{ fontWeight: "bold" }}><Spinner animation='border' size="sm" /> Esperando datos...</p>
        </div>
    }

    const selectorAcciones = () => {
        return <div>
            <select disabled={loadingEsquemaDestino} className='form-control mt-3' onChange={handleChangeAccion} defaultValue={accion ? accion.accion_name : ''} >
                        <option value="">Selecciona una acción final</option>
                        <option value="crear_orden">Crear orden</option>
                        <option value="crear_producto">Crear producto</option>
                        <option value="editar_producto">Modificar producto</option>
            </select>
        </div>
    }

    const SinDatosAccion = () => {
        return <div>
            <img src={`${url_images}/animations/Robotic process automation.gif`} style={{ maxWidth: 300, marginBottom: -30, marginTop: -30 }} />
            <h4 style={{ fontWeight: "bold" }} className='mb-0'>Asocia la información</h4>
            <p className='mb-0'>Selecciona una acción y conecta los campos de tu esquema con los valores que requiere dicha acción</p>
            {selectorAcciones()}
        </div>
    }

    const procesarCampo = (campo) => {
        if(Array.isArray(campo) !== false) return "array"
        const tipo_de_campo = typeof campo
        return tipo_de_campo
    }

    const homologar = (ruta_campo, iterable) => {
        const ruta_campo_ = `*${ruta_campo}*`
        toast.info("Ahora selecciona el campo destino")
        setCampoSeleccionado(ruta_campo_)
        console.log({ iterable })
        setCampoIterable(iterable)
    }

    const mostrarEjemploPorValorFinal = (tipo_campo, value, llaves, iterable) => {

        const campo = llaves.join('.')

        if(value === null) return <p>NULO</p>
        if(tipo_campo === "array"){
            return false
        } else if( tipo_campo === "object"){
            return false
        } else if( tipo_campo === "boolean"){
            return <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                TOCA ESTE CAMPO Y ENLAZALO AL DESTINO
              </Tooltip>
            }
            ><p className='mb-0 hover' style={{ fontSize: 12 }} onClick={() => homologar(campo, iterable) } ><AiOutlineDrag /> Ejemplo <b style={{ color: campoSeleccionado === `*${campo}*` ? "blue" : "#adadad" }}>VERDADERO O FALSO</b></p></OverlayTrigger>
        } else {
            return <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                TOCA ESTE CAMPO Y ENLAZALO AL DESTINO
              </Tooltip>
            }
            ><div>
            <p className='mb-0 hover' style={{ fontSize: 12 }} onClick={() =>  homologar(campo, iterable)} ><AiOutlineDrag /> Ejemplo <b style={{ color: campoSeleccionado === `*${campo}*` ? "blue" : "#adadad" }}>{value}</b></p>
            {/* { iterable === true ? `ITERABLE ${JSON.stringify(value)} · ${campo}` : "NO ITERABLE" } */}
            </div></OverlayTrigger>
        }
    }

    const procesarEsquemaVisualizacion = (campo, key, llaves_anteriores, iterable) => {
        const tipo_campo = procesarCampo(campo)
        let llaves = llaves_anteriores
        if(typeof key !== "undefined" ) llaves.push(key)

        if(campo === null){
            return <div>
            {mostrarEjemploPorValorFinal(tipo_campo, campo, [], iterable)}
            </div>
        }
        
        if( tipo_campo === "array" ){

            if(campo.length > 0){

                let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                
                return <div>
                    {procesarEsquemaVisualizacion(campo[0], undefined, nuevas_llaves, true )}
                </div>
            }
        } else if( tipo_campo === "object"){

            
            return <div>
                {
                    Object.keys(campo).map((d,pos) => {

                        let es_iterable = iterable === true ? true : false
                        
                        let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                        const tipo_anidado = procesarCampo(campo[d])
                        return <div>
                             <Badge variant="dark">{tipo_anidado}</Badge> {d}
                             <div className='ml-4'>
                            {procesarEsquemaVisualizacion(campo[d], d, nuevas_llaves, es_iterable)}
                            { Object.keys(campo).length === (pos+1) ? false : <hr className='hr' /> }
                            </div>
                            </div>
                    })
                }
            </div>
        } else {
            
            return <div>
                {mostrarEjemploPorValorFinal(tipo_campo, campo, llaves, iterable)}
            </div>
        }
    }

    const mostrarMantenedorEsquema = () => {
        if(!esquema) return false
        if( typeof esquema !== "object" ) return false
        return <div>
                    <Accordion defaultActiveKey={''} className="mb-3">
                    {
                        Object.keys(esquema).map((field,i) => {
                            let keys = [  ]
                            const eventKey = `${field}-${i+1}`
                            const tipo_campo = procesarCampo(esquema[field])
                            return <Card key={field} style={{ textTransform: "uppercase" }}>
                                <Accordion.Toggle as={Card.Header} style={{ padding: '8px 10px', backgroundColor:'white' }} eventKey={eventKey}>
                                <Badge variant="light">{tipo_campo ? tipo_campo : 'DESCONOCIDO'}</Badge> {field}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={eventKey}>
                                <Card.Body>
                                    {procesarEsquemaVisualizacion(esquema[field], field, keys, false)}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        })
                    }
                    </Accordion>
        </div>
    }
    const mostrarEvento = () => {
        if(!esquema) return <SinDatos />
        if(!esquema) return <SinDatos />
        if( typeof esquema !== "object") return <SinDatos />
        return <div>
            <h4 className='mt-3'><BsFileEarmarkCode /> Evento de origen</h4>
            {mostrarMantenedorEsquema()}
        </div>
    }

    const handleChangeAccion = (e) => {
        const { name, value } = e.target
        if(!formularios[value]) return false
        return setAccion({...formularios[value], accion_name: value })
    }

    const consultarEsquema = async (tipo)=>{
        setLoadingEsquemaDestino(true)
        return fetch(`${urlapi}/integraciones/funciones/esquema`,{
            method:'POST',
            body: JSON.stringify({
                tipo
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setEsquemaDestino(res)
            }
            return setLoadingEsquemaDestino(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEsquemaDestino(false)
        })
    }

    const getValueFromNestedObject = (obj, path) => {
        if(!path) return ''
        const keys = path.split('.'); // Dividir el string en partes usando el punto como separador
        let value = obj;
      
        let i = 0
        for (const key of keys) {
            i++
          if (value && value.hasOwnProperty(key)) {
            value = value[key]; // Avanzar al siguiente nivel del objeto
          } else {
            if(Array.isArray(value)){
                if(value.length > 0 && value[0].hasOwnProperty(key)){
                    value = value[0][key]
                    if(i === keys.length) return value
                }
            } else {
                if(i === keys.length) return value
            }
        }
        }
      
        return value;
    }

    const setValueInNestedObject = (target_schema, path, newValue) => {
        const keys = path.split('.');
        let value = target_schema;
    
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            let tipo_campo = typeof value[key];
    
            if (!value.hasOwnProperty(key)) {
                if (Array.isArray(value)) {
                    // Si el objeto actual es un arreglo
                    tipo_campo = "array";
                    value.forEach(item => {
                        if (!item.hasOwnProperty(key)) {
                            item[key] = {}; // Crear un objeto vacío en cada elemento del arreglo
                        }
                        value = item[key]; // Avanzar al siguiente nivel del objeto
                    });
                } else if (typeof value[key] !== 'object') {
                    // Si el valor no es un objeto (ni arreglo ni objeto)
                    value[key] = {}; // Crear un objeto vacío si la clave no existe o no es un objeto
                } else {
                    value = value[key]; // Avanzar al siguiente nivel del objeto
                }
            } else {
                value = value[key]; // Avanzar al siguiente nivel del objeto
            }
        }
    
        // Actualizar el valor en el objeto/arreglo final
        const lastKey = keys[keys.length - 1];
        if (Array.isArray(value)) {
            value.forEach(item => {
                item[lastKey] = newValue;
            });
        } else {
            value[lastKey] = newValue;
        }
    
        return target_schema; // Retornar el objeto modificado
}

const handleChangeField = (e) => {
    const { value } = e.target
    const ruta = e.target.getAttribute('ruta')
    const nuevo = setValueInNestedObject(esquemaDestino, ruta, value)
    console.log({nuevo})
    if(typeof esquemaDestino === "object"){
        return setEsquemaDestino({...{}, ...nuevo})
    } else if(Array.isArray(esquemaDestino) !== false){
        return setEsquemaDestino([...[], ...esquemaDestino])
    }
}

    const handleChangeFieldChecked = (e) => {
        const value = e.target.checked
        const ruta = e.target.getAttribute('ruta')
        const nuevo = setValueInNestedObject(esquemaDestino, ruta, value)
        if(typeof esquemaDestino === "object"){
            return setEsquemaDestino({...{}, ...nuevo})
        } else if(Array.isArray(esquemaDestino) !== false){
            return setEsquemaDestino([...[], ...esquemaDestino])
        }
    }

    const mostrarAccionDestino = (iterable, liberar) => {
        if(!campoSeleccionado) return false 
        if(campoIterable === true){
            if(!iterable) return false
            return liberar
        }
        return liberar
    }

    const pegarValor = (ruta) => {
        /*
        try {
            const vall = getValueFromNestedObjectTestArray(esquema, campoSeleccionado.replace('{','').replace('}',''))
            return console.log({vall})
        } catch (error) {
            return console.log(error.message)
        }
        */

        const nuevo = setValueInNestedObject(esquemaDestino, ruta, campoSeleccionado)
        return setTimeout(() => {
            setEsquemaDestino(nuevo)
            const jsonnn = JSON.parse( JSON.stringify( nuevo ))
            console.log(nuevo, jsonnn)
            setCampoIterable(false)
            return setCampoSeleccionado(false)
        }, 300);
    }

    const mostrarEjemploPorValorFinalDestino = (tipo_campo, value, llaves, iterable) => {
        try {

            const campo = llaves.join('.')

            const valor_ejemplo = getValueFromNestedObject(esquemaDestinoEjemplo, campo)
            const valor_actual = getValueFromNestedObject(esquemaDestino, campo)
            const objetoPegar = <p className='d-inline hover' onClick={() => pegarValor(campo)}><BiPaste /> </p>
            const objetoPegarTitulo = <h6 className='mt-2 hover' onClick={() => pegarValor(campo)}>PEGA AQUÍ <BiPaste /></h6>

            if(iterable === true){
                // ES UN ARREGLO. DE STRING PROBABLEMENTE Y POR ESO LLEGÓ AQUI
                return <div>
                    <p className='mb-0 ' style={{ fontSize: 12 }} ><AiOutlineDrag /> Ejemplo <b>{value ? value.toString() : ""}</b> </p>
                    {campoIterable === true ? objetoPegarTitulo : false }
                    {valor_actual}
                </div>
            }

            if(value === null) return <p>NULO</p>

            if(tipo_campo === "array"){
                return typeof value
            } else if( tipo_campo === "object"){
                return typeof value
            } else if( tipo_campo === "boolean"){
                return <div>
                    <OverlayTrigger
                placement={'top'}
                overlay={
                <Tooltip>
                    TOCA ESTE CAMPO Y ENLAZALO AL DESTINO
                </Tooltip>
                }
                ><p className='mb-0' style={{ fontSize: 12 }} ><AiOutlineDrag /> Ejemplo<b >VERDADERO o FALSO {iterable.toString()}</b></p></OverlayTrigger>
                <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="3">
                    <Form.Check type="switch" defaultChecked={valor_ejemplo === true ? true : false} ruta={campo} label="Verdadero o falso" onChange={handleChangeFieldChecked} />
                </Form.Group>

                </div>
                } else {

                    return <div>
                    <p className='mb-0 ' style={{ fontSize: 12 }} ><AiOutlineDrag /> Ejemplo <b>{valor_ejemplo ? valor_ejemplo.toString() : ""}</b> </p>
                    <input className='form-control' placeholder='Escribe un valor, puede ser sintaxis JSON' value={ valor_ejemplo === valor_actual ? '' : valor_actual  } ruta={campo} onChange={handleChangeField} />
                    { campoSeleccionado ?  
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                        <Tooltip>
                            Enlazar el campo {campoSeleccionado.split('.').pop().replace("*","")} del esquema origen 
                        </Tooltip>
                        }
                        ><div style={{ position: "absolute", right: 32, marginTop: "-32px" }} >
                            {mostrarAccionDestino(iterable, objetoPegar)}
                        </div></OverlayTrigger>
                    : false}
                    
                </div>        
            }
            
        } catch (error) {
            return <div>ERROR CRÍTICO AL PROCESAR EL ESQUEMA. Detalles: {error.message}</div>
        }
    }


    const procesarEsquemaVisualizacionDestino = (campo, key, llaves_anteriores, iterable) => {
        const tipo_campo = procesarCampo(campo)
        let llaves = llaves_anteriores
        if(campo === null){
            return <div>
            {mostrarEjemploPorValorFinalDestino(tipo_campo, campo, llaves, false)}
            <Card className='p-3 mt-1'><h6 style={{ color: "gray" }} className='mb-0'><AiOutlineDrag /> {llaves.join('.')}</h6></Card>
            </div>
        }
        if(typeof key !== "undefined" ) llaves.push(key)
        if( tipo_campo === "array" ){
            if(campo.length > 0){

                let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                
                return <div>
                    {procesarEsquemaVisualizacionDestino(campo[0], undefined, nuevas_llaves, true)}
                </div>
            }
            
        } else if( tipo_campo === "object"){
            return <div>
                {
                    Object.keys(campo).map((d,pos) => {
                        
                        let es_iterable = iterable === true ? true : false

                        let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                        const tipo_anidado = procesarCampo(campo[d])
                        return <div>
                             <Badge variant="light">{tipo_anidado}</Badge> {d}
                             <div className='ml-4'>
                            {procesarEsquemaVisualizacionDestino(campo[d], d, nuevas_llaves, es_iterable)}
                            </div>
                            { Object.keys(campo).length === (pos+1) ? false : <hr className='hr' /> }
                            </div>
                    })
                }
            </div>
        } else {
            
            return <div>
            {mostrarEjemploPorValorFinalDestino(tipo_campo, campo, llaves, iterable)}
            </div>
        }
    }

    const mostrarEsquemaDestino = () => {
        if(!esquemaDestino) return false

        return <div>
            <Accordion defaultActiveKey={''} className="mb-3">
                    {
                        Object.keys(esquemaDestino).map((field,i) => {
                            let keys = [  ]
                            const eventKey = `${field}-${i+1}`
                            const tipo_campo = procesarCampo(esquemaDestino[field])
                            return <Card key={field} style={{ textTransform: "uppercase" }} >
                                <Accordion.Toggle as={Card.Header} style={{ padding: '8px 10px', backgroundColor:'white' }} className='hover' eventKey={eventKey}>
                                <Badge variant="light">{tipo_campo ? tipo_campo : 'DESCONOCIDO'}</Badge> {field}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={eventKey}>
                                <Card.Body>
                                    {procesarEsquemaVisualizacionDestino(esquemaDestino[field], field, keys)}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        })
                    }
                    </Accordion>
        </div>
    }

    const asociarCampo = (key) => {
        if(!campoSeleccionado) return toast.error("Debes seleccionar un campo primero para pegar")
        return setAccion(prev => {
            let actual = {...prev}
            actual[key] = campoSeleccionado
            return actual
        })
    }

    const mostrarValor = (val) => {
        return false
        if(!val) return <div>Aún no seleccionado</div>
        return <div className='mt-1'>
            <Row>
                <Col >
                    <p className='mb-0'>Ruta del origen</p>
                    {val.ruta}
                </Col>
            </Row>
        </div>
    }

    const pegarValorDestino = (key) => {

        setAccion(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = {}

            actual[key].valor = actual[key].valor ? {
                ...actual[key].valor,
                ruta: campoSeleccionado
            } : {
                ruta: campoSeleccionado
            }

            return {...{}, ...actual}
        })
        setTimeout(() => {
            setCampoSeleccionado(false)
        }, 500);
    }

    const handleChange = (e, key) => {
        const { value } = e.target
        return setAccion(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = {}
            if(!actual[key].valor) actual[key].valor = {}
            actual[key].valor.ruta = value
            return {...{}, ...actual}
        })
    }

    const onPaste = () => {
        setTimeout(() => {
            setCampoSeleccionado(false)
        }, 500);
    }

    const mostrarAdicionales = (key) => {
        return <div>
            <ConditionalEditor />
            <FunctionAdder />
        </div>
    }

    const mostrarFormularioAccion = () => {
        if(!accion) return <SinDatosAccion />
        return <div>
            <h4 className='mt-3 mb-1'><BsFileEarmarkCode /> Evento destino</h4>
            {selectorAcciones()}
            {
                Object.keys(accion).map((field,i) => {
                    if(field === "accion_name") return 

                    let resultado = ''
                    if(accion[field].valor) if(typeof accion[field].valor === "object") if(accion[field].valor.ruta) resultado = accion[field].valor.ruta

                    return <div key={`f-${i}`} className='mt-2 mb-3'>
                        <Card className='p-2'>
                            <p className='m-0' style={{ textTransform: "uppercase", fontWeight: "bold" }}><FaArrowRight/> {accion[field].label} {accion[field].required === true ? <b className='text-danger' style={{ fontWeight: "bold" }}>OBLIGATORIO</b> : false }</p>
                            <hr className='mb-1 mt-1' />
                            <p style={{ fontSize: 13 }} className='mb-1'>{accion[field].description} </p>
                            <Row>
                                <Col md={4}><Button size="sm" variant='light' className='w-100' disabled={!campoSeleccionado} style={{ fontSize: 13 }} onClick={() => pegarValorDestino(field)}><MdContentPasteGo/> PEGAR AQUÍ</Button></Col>
                                <Col md={8}>
                                <input placeholder='Escribe directamente un valor' className='w-100' style={{ height: 30, padding: "5px 10px" }} value={resultado} onChange={(e) => handleChange(e, field) } />
                                </Col>
                            </Row>
                            <div className='mt-3'><Concatenaciones valToSet={campoSeleccionado} onChange={(e) => {
                                setAccion(prev => {
                                    let actual = { ...prev }
                                    actual[field] = { ...actual[field], ...e }
                                    return actual
                                })
                            }} onPaste={() => onPaste()} registro={accion[field]} /></div>
                            {mostrarValor(accion[field].valor)}
                            {mostrarAdicionales(field)}
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const previsualizar = () => {
        setShowModalPreview(true)
    }

    const guardarCambios = async () => {
        const instancia = JSON.parse( JSON.stringify(hook) )
        instancia.esquema = accion
        setLoadingDetalle(true)
        return fetch(`${urlapi}/integraciones/conexiones`,{
            method:'PUT',
            body: JSON.stringify(instancia),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const confirmarEliminado = async () => {
        setLoadingEliminando(true)
        return fetch(`${urlapi}/integraciones/conexiones?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Eliminado exitosamente')
                return setTimeout(() => {
                    window.location = `/${rutas.integraciones.slug}?tab=apiless`
                }, 1000);
            }
            return setLoadingEliminando(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingEliminando(false)
        })
    }

    const solicitarEliminar = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta orden, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const agregarCabecera = () => {
        return setHook(prev => {
            let actual = {...prev}
            if(!actual.seguridad) actual.seguridad = {}
            if(!actual.seguridad.headers) actual.seguridad.headers = []
            actual.seguridad.headers.unshift({
                key: "",
                value: ""
            })
            return actual
        })
    }

    const handleChangeHeader = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        return setHook(prev => {
            let actual = {...prev}
            actual.seguridad.headers[pos][name] = value
            return actual
        })
    }

    const eliminarCabecera = (i) => {
        return setHook(prev => {
            let actual = {...prev}
            actual.seguridad.headers.splice(i,1)
            return actual
        })
    }

    const mostrarCabeceras = () => {
        const sin_datos = <div style={{ marginTop: 50}} ><HelperIntegraciones tipo="cabeceras"  /></div>
        if(!hook.seguridad) return sin_datos
        if(typeof hook.seguridad !== "object") return sin_datos
        if(!hook.seguridad.headers) return sin_datos
        return <div>
                {
                    hook.seguridad.headers.map((header,i) => {
                        return <Row key={`h-${i}`}>
                            <Col>
                                <label className='form-control-label'>Key</label>
                                <input className='form-control' name="key" value={header.key} pos={i} onChange={handleChangeHeader} />
                            </Col>
                            <Col>
                                <label className='form-control-label'>Value</label>
                                <input className='form-control' pos={i} name="value" value={header.value} onChange={handleChangeHeader}  />
                            </Col>
                            <Col>
                                <label className='form-control-label'>Description</label>
                                <input className='form-control' pos={i} name="description" value={header.description} onChange={handleChangeHeader}  />
                            </Col>
                            <Col xs={12} className='mt-3'><Button size="sm"  onClick={() => eliminarCabecera(i)} >ELIMINAR</Button></Col>
                        </Row>
                    })
                }
        </div>
    }
    
    const mostrarLog = () => {
        return <div>
            <EventosConexionListado condicion_default={{ id_conexion: id }} />
        </div>
    }

    const mostrarSeguridad = () => {
        return <div className='mt-3'>
            <p>Protege este endpoint validando cabeceras en los datos entrantes</p>
            <Button size='sm' variant='light' onClick={() => agregarCabecera()}>AGREGAR CABECERA</Button>
            {mostrarCabeceras()}
        </div>
    }

    const agregarFuncion = (e) => {
        return setAccion(prev => {
            let actual = { ...prev }
            if(!actual.funciones) actual.funciones = []
            const i = actual.funciones.findIndex(f => f.value === e.value)
            if(i === -1) actual.funciones.push(e)
            return actual
        })
    }

    const eliminarFuncion = (i) => {
        return setAccion(prev => {
            let actual = { ...prev }
            actual.funciones.splice(i,1)
            return actual
        })
    }

    const mostrarFunciones = () => {
        if(!accion.funciones) return false
        if(!Array.isArray(accion.funciones)) return false
        return <div>
            {
                accion.funciones.map((f,i) => {
                    return <Badge variant="dark" key={`f-${i}`} className='mr-2'>{f.label} <IoClose className='hover' onClick={() => eliminarFuncion(i)} /></Badge>
                })
            }
        </div>
    }

    const mostrarEventos = () => {
        return <div className='mt-3'>
            <p>Accede a un registro de los eventos recibidos a este endpoint</p>
            {mostrarLog()}
        </div>
    }

    const formularioMantenedor = () => {
        return <div>
            <h4 className="mb-0" style={{ fontWeight: "bold" }}>PUNTO DE CONEXIÓN</h4>
            <p style={{ color: "gray" }} className="mb-0" ><b>{urlapi}/integraciones/conexiones/hook/{hook._id}</b></p>
            <hr />
            <Row>
                <Col>
                    <ButtonGroup>
                        <Button variant='success' size='sm' onClick={() => guardarCambios()}><IoIosSave size={18} /> GUARDAR CAMBIOS</Button>
                        <Button variant='light' size='sm' onClick={() => previsualizar()}><MdPreview size={18} /> PREVISUALIZAR</Button>
                    </ButtonGroup>
                </Col>
                <Col className='text-right'><Button variant='outline-danger' size='sm' disabled={loadingEliminando} onClick={() => solicitarEliminar()}><MdPreview size={18} /> { loadingEliminando ? "ELIMINANDO..." : "ELIMINAR"}</Button></Col>
            </Row>
            
            <hr />
            <Tabs defaultActiveKey="info">
                <Tab eventKey="info" title="Esquema de información">
                    <div className='mt-3'><ModalAgregarOpciones title="AGREGAR FUNCIONES EN COLA" onSelect={e => agregarFuncion(e)} /></div>
                    {mostrarFunciones()}
                    <Row>
                        <Col style={{ height: "70vh", overflowY: "scroll" }}>
                        {mostrarEvento()}
                        </Col>
                        <Col style={{ height: "70vh", overflowY: "scroll" }}>
                            {mostrarFormularioAccion()}
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="seguridad" title="Seguridad">
                    {mostrarSeguridad()}
                </Tab>
                <Tab eventKey="eventos" title="Eventos">
                    {mostrarEventos()}
                </Tab>
            </Tabs>
            
        </div>
    }


    const mostrarInfo = () => {
        if(loadingDetalle) return <PantallaCargando />
        if(!hook) return <SinDatos />

        return <div>
                {formularioMantenedor()}
        </div>
    }

    const handleClose = () => {
        return setShowModalPreview(false)
    }

    const mostrarModal = () => {
        return <div>
            <Modal show={showModalPreview} size="lg" onHide={() => handleClose()} centered >
            <Modal.Header closeButton>
                <h3 className='mb-0' style={{ fontWeight: "bold" }}>Así va a lucir el resultado</h3>
            </Modal.Header>
            <Modal.Body >
                <Row>
            {
                Object.keys(accion).map((field,i) => {
                    if(field==="accion_name") return
                    const ruta = accion[field].valor?.ruta.split('.').map(e => e.replace("*","").replace("*","")).join('.')
                    let valor = getValueFromNestedObject(esquema, ruta)
                    if(Array.isArray(valor) !== false){
                        valor = valor.join(', ')
                    }
                    return <Col md={4} className='mb-2'>
                        <label className='form-control-label mb-0' style={{ fontWeight: "bold", textTransform: "uppercase" }}>{accion[field].label}</label>
                        <p className='mb-0'>{valor ? valor : <Badge variant='light'>Sin información</Badge> }</p>
                    </Col>
                })
            }
            </Row>
            </Modal.Body>
        </Modal>
        </div>
    }

    return <div>
        <div className='p-3'>
        {mostrarInfo()}
        {mostrarModal()}
        </div>
    </div>

}

export default DetallesApiless