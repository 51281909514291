import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import { cerrarSesion } from "../../../redux/actions/session"
import { Button, Col, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap"
import { debounce } from "@mui/material"
import { FaCheckDouble, FaLink } from "react-icons/fa"
import { estilo_last_mile } from "../../../lib/global/styles"
import CargaImagenes from "../../general/carga_imagenes"
import { IoHandLeft } from "react-icons/io5"
import ErrorBoundary from "../../errorsHandler/errorBoundary"

const MetaDatosOrden = (props) => {
    const {
        viewType
    } = props
    const [ error, setError ] = useState(null)
    const { id_orden } = props
    const [ campos, setCampos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const [ archivos, setArchivos ] = useState([])
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
        obtenerDatos()
    }, [])
    const obtenerDatos = async () => {
        console.log("ID_orden:", id_orden)
        if(!id_orden) return setLoading(false)
        setLoading(true)
        return fetch(`${urlapi}/ordenes/campos-personalizados/orden`,{
            method:'POST',
            body: JSON.stringify({
                condicion: { },
                id_orden
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setCampos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const agregarArchivo = (url,name, id) => {
        const e = {target: {name: id, value: url }}
        return handleChange(e)      
    }

    const actualizar = useCallback(debounce((data) => guardarCambios(data), 500), []);

    const handleChange = (e) => {

        const { name, value } = e.target
        const id = name
        return setCampos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(campo => campo._id === id)
            if(i > -1 ) actual[i].value = value
            actualizar(actual)
            return actual
        })
    }
    const eliminar = (pos) => {
        if(props.onDelete) props.onDelete(pos)
    }


    const guardarCambios = async (fields) => {
        setLoadingGuardando(true)
        const enviar = fields.filter(c => c.value).map(campo => {
            campo.id_orden = id_orden
            return campo
        })
        return fetch(`${urlapi}/ordenes/campos-personalizados/orden`,{
            method:'PUT',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res.success){
                if(props.onSave) props.onSave({ id_orden, campos: enviar })
                if(res.updates) if(Array.isArray(res.updates)){
                    setCampos(prev => {
                        let actual = [...prev]
                        actual = actual.map(campo => {
                            const i = res.updates.findIndex(e => e.key === campo.slug)
                            if( i > -1 ) campo.meta_data = res.updates[i].meta_data ? res.updates[i].meta_data : []
                            return campo
                        })
                        return actual
                    })
                }
            }
            return setLoadingGuardando(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingGuardando(false)
        })
    }

    const renderizarSegunTipo = (campo) => {

        if(campo.type === "carga_archivos" )
            return <div>
                <CargaImagenes onUploaded={(url,name) => agregarArchivo(url,name, campo._id)}/>
                    {
                        campo.value ?
                        <img style={{width: 150, height: 150}} src={campo.value} alt="" /> 
                        : 
                        false
                    }
                    
            </div>

        if(campo.type === "select" )
            return <div>
                <select className="form-control d-block" name={campo._id} onChange={handleChange} defaultValue={campo.value} >
                    <option value="">Seleccione</option>
                    {
                        campo.options.map((opt, i) => {
                            return <option key={i} value={opt.value}>{opt.value}</option>
                        })
                    }
                </select>
            </div>
        if(campo.type === "date" )
            return <div>
                <input type="date" className="form-control shadow-sm mb-2" name={campo._id} onChange={handleChange} defaultValue={campo.value}  />
            </div>
        return <div>
            <input className="form-control shadow-sm mb-2" name={campo._id} onChange={handleChange} defaultValue={campo.value}  />
        </div>
    }

    const mostrarOpcionesAutomatizacion = (campo) => {
        if(!campo?.integraciones) return false
        if(!Array.isArray(campo?.integraciones)) return false
        if(campo.integraciones.length < 1) return false

        let eventos = []
        if(campo.meta_data) if(Array.isArray(campo.meta_data)) if(campo.meta_data.length > 0) eventos = campo.meta_data

        return <OverlayTrigger
        key={campo._id}
        placement={'top'}
        overlay={
            <Tooltip id={'right'}>
                Si llenas esta información se enviará a las siguientes integraciones: {campo.integraciones.map(e => `${e.nombre} - ${e.module}`).join(", ")}
            </Tooltip>
        }><div style={{ display: "inline-block" }}><FaLink className="hover" color={campo.value ? estilo_last_mile.color_secondary : "gray" } /> {eventos.length > 0 ? <FaCheckDouble color="green" /> : false }</div></OverlayTrigger>
    }

    const mostrarCampos = () => {
        if(loading) return <Skeleton height={50} />

        if(viewType === "simple") return <div className="table-responsive">
            <Table bordered >
                <thead>
                    <tr>
                        <th>NOMBRE</th>
                        <th>VALOR</th>
                    </tr>
                </thead>
                <tbody>
                {
                    campos.map(campo => {
                        if(!campo.value) return false
                        return <tr md={3} key={campo._id}>
                            <th>{campo.nombre}</th>
                            <th>{campo.value}</th>
                        </tr>
                    })
                }
                </tbody>
            </Table>
        </div>
        return <div>
            <div style={{ position: "absolute", bottom: 15, right: 15, padding: "10px 20px", background: "white" }}>{ loadingGuardando ? <h5 ><Spinner animation="border" /> Guardando...</h5> : <h5 className="text-success"><b>Guardado</b></h5> }</div>
            <Row className="mb-3">
            {
                campos.map(campo => {
                    return <Col md={4} key={campo._id} className="mb-3">
                        <label className="form-control-label d-block mb-3 text-uppercase" style={{fontWeight:700, fontSize:13}}>{campo.nombre} {mostrarOpcionesAutomatizacion(campo)}</label>
                        {renderizarSegunTipo(campo)}
                    </Col>
                })
            }
            </Row>
            {/* { loadingGuardando ? <Spinner animation="border" /> : <Button size="sm" className='shadow-sm w-100' variant="primary" onClick={() => guardarCambios()} ><b>GUARDAR CAMBIOS</b></Button> } */}
        </div>
    }

    return <div>
        <ErrorBoundary message={error} />
        {mostrarCampos()}
    </div>
}

export default MetaDatosOrden