import { useEffect, useRef, useState } from 'react'
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/actions/session';
import ErrorBoundary from '../../errorsHandler/errorBoundary';

const TiposServicioSelector = (props) => {
    const {
        onLoad,
        defaultValue,
        multi,
        key,
        value
    } = props
    const [ error, setError ] = useState(null)
    const default_value = defaultValue ? defaultValue : ''
    const [ tiposServicio, setTiposServicio ] = useState([])
    const [ seleccionado, setSeleccionado ] = useState(null)
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const pais = useSelector(state => state.pais)
    const selectRef = useRef()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const key_input = key ? key : null
    const isMulti = multi === true
    const dispatch = useDispatch()

    const obtenerProveedores = async ()=>{
        setLoading(true)
        return fetch(`${data.urlapi}/tiposservicio/select`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setTiposServicio(res)
                if(onLoad) onLoad(res)
                    setTimeout(() => {
                        const items = res.map(p => ({ value: p._id, label: p.valor }))
                        if(defaultValue){
                            if(selectRef.current){
                                if(Array.isArray(defaultValue)){
                                    const default_values = items.filter(e => defaultValue.includes(e.value))
                                    selectRef.current?.setValue(default_values)
                                } else {
                                    const pos = items.findIndex(e => e.value === defaultValue?._id)
                                    if(pos > -1){
                                        const value_search = items[pos]
                                        selectRef.current?.setValue(value_search)
                                    }
                                }
    
                            }
                        }
                    }, 100);
            }
            return setLoading(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    useEffect(() => {
        obtenerProveedores()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        setSeleccionado(e)
        if(isMulti === true){
            if(props.onChangeValue) props.onChangeValue(e)
        } else {
            const i = tiposServicio.findIndex(zona => zona._id === e?.value)
            if(i > -1 ){
                if(props.onChangeValue) props.onChangeValue(tiposServicio[i])
            }
        }
        return false
    }

    // let default_option = false
    // if(default_value){
    //     const valor_busqueda = default_value?._id ? default_value?._id : default_value
    //     const i = tiposServicio.findIndex(ti => ti._id === valor_busqueda)
    //     if(i > -1) default_option = { value: tiposServicio[i]._id, label: tiposServicio[i].valor }
    // }

    return <div>
        <ErrorBoundary message={error} />
        <Select
            ref={selectRef}
            key={key_input}
            value={value ? value : seleccionado}
            // value={default_option}
            isMulti={isMulti}
            // isClearable
            placeholder="Buscar tipo de servicio..."
            options={tiposServicio.map(obj => ({ value: obj._id, label: obj.valor }))}
            isLoading={loading}
            noOptionsMessage={()=>"Sin opciones"}
            loadingMessage={()=>"Cargando información..."}
            onChange={handleChange}
            className="mb-2"
        />
    </div>

}

export default TiposServicioSelector