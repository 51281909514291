const esquema_ordenes = [
        'referencia',
        // 'vehiculo',
        // 'item',
        // 'cantidad',
        // 'codigo_item',
        'nombre_contacto',
        // 'telefono',
        // 'email_contacto',
        'direccion',
]
const esquema_vehiculos = [
        'nombres',
        'apellidos',
        'rut_conductor',
        'modelo_vehiculo',
        'año_vehiculo',
        'patente_vehiculo',
        'movil_whatsapp'
]

const esquema_productos = [
        'description',
        'codigo_item',
        'peso',
        'alto',
        'ancho',
        'largo',
        'price'
]


const esquema_destinatarios = [
        'nombre_o_razon_social',
        'tipo',
        'rut',
        'direccion_principal',
        'telefono_fijo',
        'telefono_movil'
]

const esquema_ordenes_requeridas = [
        'referencia',
        'vehiculo',
        'item',
        'cantidad',
        'codigo_item',
        'identificador_contacto',
        'nombre_contacto',
        'telefono',
        'email_contacto',
        'direccion',
        'alto',
        'ancho',
        'largo',
        'peso',
        'fecha_min_entrega',
        'fecha_max_entrega'
]

const esquema_formato_base = [
        "labelnumber"
]

module.exports = {
    esquema_ordenes,
    esquema_formato_base,
    esquema_ordenes_requeridas,
    esquema_destinatarios,
    esquema_vehiculos,
    esquema_productos
}