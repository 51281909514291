import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, Tabs, Tab } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { iconSize, key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import UsuariosListado from '../../subComponents/usuarios/listado'
import { cerrarSesion } from '../../redux/actions/session'
import EstadosCarga from '../EstadosCarga'
import Bodegas from "../Bodegas";
import FlujosEstadosCarga from '../../subComponents/estados_carga/flujos'
import { TiFlowSwitch } from "react-icons/ti";
import { BsCardChecklist } from 'react-icons/bs'
import { FaRegUser } from 'react-icons/fa'
import { PiWarehouseBold } from 'react-icons/pi'
import { url_images } from '../../lib/global/data'
import MiCuenta from '../Cuenta';
import { MdAccountCircle } from 'react-icons/md'
import ReglasCotizacion from '../../subComponents/proveedores_transporte/cotizacion/reglas_cotizacion'
import { default_proveedores } from '../../subComponents/proveedores_transporte/data'
import BitacoraEventosListado from '../../subComponents/ordenes/bitacora/list'

class Usuarios extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingUsuarios: true,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        this.getUsuarios(false)
    }

    buscar(){
        const { parametro_busqueda, user } = this.state
        if(!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if(parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length < 1){
                toast.warn('No se encontraron resultados')
                return this.setState({ [loading]: false })
            }
            return this.setState({ usuarios: res, filtro_rol:'', [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getUsuarios(cargarmas){
        const { user, usuarios } = this.state
        let loading = 'loadingUsuarios'
        if(cargarmas===true) loading = 'loadingMore'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/list?skip=${usuarios.length}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = usuarios.concat(res.datos)
                    return this.setState({ usuarios: final_array, total: res.total, [loading]: false })
                } else {
                    return this.setState({ [loading]: false })
                }
            } else {
                this.setState({ usuarios: res.datos, total: res.total, [loading]: false })
            }
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    buscarUsuario(e){
        e.preventDefault()
        return this.buscar()
    }
    
    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <img src={`${url_images}/animations/File selection.gif`} style={{ maxWidth: 200 }} />
                <h4>Aún no tienes usuarios</h4>
                <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
                </Col>
            </Row>
        </div>
    }

    mostrarUsuarios(){
        const { usuarios, loadingUsuarios, user, loadingMore, total, filtro_rol, loadingBusqueda } = this.state
        if(loadingUsuarios) return <PantallaCargando />
        if(usuarios.length < 1) return this.sinRegistros()

        return <div>            
            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activos" title="Activas">
                <UsuariosListado token={user.tokenSession} condicion_default={{ status: 'active' }} />
            </Tab>
            <Tab eventKey="archivados" title="Archivados">
                <UsuariosListado trash={true} token={user.tokenSession} condicion_default={{ status: 'trash' }} />
            </Tab>
            </Tabs>
            
            
        </div>
    }
    
    render(){
        return(
            <div className="fluid">
                {/* <h4>Empresa </h4> */}
                <Tabs defaultActiveKey="cuenta">
                        <Tab eventKey="cuenta" title={<div><h5 className='mb-0'><MdAccountCircle style={{ verticalAlign: "middle" }} size={20} /> Mi cuenta</h5></div>} className='mt-4'><MiCuenta /> </Tab>
                        <Tab eventKey="usuarios" title={<div><h5 className='mb-0'><FaRegUser style={{ verticalAlign: "middle" }} size={20} /> Usuarios</h5></div>} className='mt-4'>
                            <Link to="/usuarios/nuevo"><Button size="sm">CREAR USUARIO</Button></Link>
                            {this.mostrarUsuarios()}</Tab>
                            <Tab eventKey="bitacora" title={<div><h5 className='mb-0'><MdAccountCircle style={{ verticalAlign: "middle" }} size={20} /> Bitácora</h5></div>} className='mt-4'><Tab.Pane eventKey="bitacora" >
                <BitacoraEventosListado description={"Aquí podrás acceder a toda la actividad de tus usuarios"} title="Bitácora de eventos" condicion_default={{ }} />
              </Tab.Pane> </Tab>
                        {/* <Tab eventKey="protocolos" title={<div><h5 className='mb-0'><BsCardChecklist style={{ verticalAlign: "middle" }} /> Protocolos y notificaciones</h5></div>} className='mt-4'>
                            
                            <Tabs id="protocolos" className="mb-3" defaultActiveKey="protocols">
                                <Tab eventKey="protocols" title={<div><h5 className='mb-0'><BsCardChecklist style={{ verticalAlign: "middle" }} /> Protocolos</h5></div>}><EstadosCarga /></Tab>
                                <Tab eventKey="flujos" title={<div><h4 className='mb-0'><TiFlowSwitch /> Flujo del protocolo</h4></div>}><FlujosEstadosCarga /> </Tab>
                            </Tabs>
                        </Tab> */}
                        {/* <Tab eventKey="bodegas" title={<div><h5 className='mb-0'><PiWarehouseBold style={{ verticalAlign: "middle" }} size={20} /> Bodegas</h5></div>} className='mt-4'><Bodegas /> </Tab>
                        <Tab eventKey="reglas-cotizacion" title={<div><h5 className='mb-0'><BsCardChecklist style={{ verticalAlign: "middle" }} /> Configuración de courriers</h5></div>} className='mt-4'>
                        <div className='p-5 bg-white text-primary'>
                            <Row className="justify-content-md-center">
                                <Col md={6} className="text-center">
                                    <h1><BsCardChecklist style={{ verticalAlign: "middle" }} /> <b>Courriers</b></h1>
                                    <Row>
                                    {
                                        default_proveedores.map((proveedor, index) => {
                                            return <Col xs={2} key={index}>
                                                <img style={{ maxHeight: 100, maxWidth: "100%" }} className="mb-3" src={proveedor.logo} />
                                            </Col>
                                        })
                                    }
                                    </Row>
                                    <img src={`${url_images}/animations/Professional movers.gif`} style={{ width: "70%" }} />
                                    <p>Configura la forma en la cual cotizas tarifas terrestres</p>
                                    <ReglasCotizacion custom_component={<Button size="sm" className='mb-3'>CONFIGURAR</Button>} />
                                </Col>
                            </Row>
                        </div>
                                                      
                        </Tab> */}
                    </Tabs>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);