import { useCallback, useEffect, useState } from "react"
import { Badge, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import CargaImagenes from "../general/carga_imagenes"
import PreviewEmail from "./preview_email"

const PersonalizarEmailEstado = (props) => {
    const components = {
        logotipo: "",
        "linea-tiempo": "",
        imagen_cabecera: "",
        resumen: "",
        titulo: "",
        mapa: "",
        evento: "",
        general: "",
        logo_footer: "",
        footer: ""
    }
    const estructura = {
        modulos: [
            {
                nombre: "Logotipo",
                slug: "logotipo",
                extraInfo: "Se recomienda tamaño de imagen de: 271 × 56 pixeles",
                activo: true,
                onlyRead: true,
                allowPhoto: true
            },
            {
                nombre: "Línea de tiempo",
                slug: "linea-tiempo",
                activo: true,
                onlyRead: true,
                allowPhoto: false
            },
            {
                nombre:"Imagen cabecera",
                slug: "imagen_cabecera",
                extraInfo: "Se recomienda tamaño de imagen de: 592 × 774 pixeles",
                activo: true,
                onlyRead: true,
                allowPhoto: true
            },
            {
                nombre: "Resumen",
                slug: "resumen",
                activo: true,
                onlyRead: true
            },
            {
                nombre: "Título del estado",
                slug: "titulo",
                activo: true
            },
            {
                nombre: "Mapa de ubicación",
                slug: "mapa",
                activo: true
            },
            {
                nombre: "Detalle del evento",
                slug: "evento",
                activo: true
            },
            {
                nombre: "Información general",
                slug: "general",
                activo: true
            },
            {
                nombre: "Logotipo pie de página",
                slug: "logo_footer",
                activo: true,
                onlyRead: true,
                allowPhoto: true
            },
            {
                nombre: "Pie de página",
                slug: "footer",
                activo: true,
                onlyRead: true
            },
        ],
        campos: [
            {
                nombre: "Destinatario",
                slug: "destinatario",
                activo: true
            },
            {
                nombre: "Origen",
                slug: "origen",
                activo: true
            },
            {
                nombre: "Destino",
                slug: "destino",
                activo: true
            },
            {
                nombre: "Transportador",
                slug: "transportador",
                activo: true
            },
            {
                nombre: "Datos del conductor",
                slug: "conductor",
                activo: true
            },
        ]
    }

    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'
    const sub_tipo = "estado-email"

    useEffect(() => {
        obtenerDatos(sub_tipo)
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "personalizacion",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                for( const modulo of estructura.modulos){
                    const i = res.detalles.modulos.findIndex(mod => mod.slug === modulo.slug)
                    if(i > -1){
                        let crear_modulo = res.detalles.modulos[i]
                        Object.keys(modulo).forEach(key => {
                            if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                        })
                        console.log(crear_modulo)
                        res.detalles.modulos[i] = crear_modulo
                    } else {
                        res.detalles.modulos.push(modulo)
                    }
                }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const handleChangeExtra = (e, type, campo) => {
        const valor = e.target.value
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 setPendienteGuardar(true)
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].extra = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const handleCheck = (e, type, campo) => {
        const valor = e.target.checked
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].onlyRead === true) return toast.warn("Este módulo no puede ser desactivado")
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        } else if(type === "campos"){
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            if( i_ > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.campos[i_].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const subirFoto = (url, name, type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].imagen = url
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const opcionesImagen = (type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    return <div className="pr-3 pl-3 pt-3 mb-0 pb-0">
                        <label className="form-control-label">Imagen</label>
                        <CargaImagenes onUploaded={(url, name) => subirFoto(url, name, type, campo)} />
                        { estructuraActual.modulos[i].imagen ? <div className="mt-3" style={{ backgroundImage: `url(${estructuraActual.modulos[i].imagen})`, display: "inline-block", width: 80, height: 80, borderRadius: 10, verticalAlign: "middle", backgroundPosition: "center center", backgroundSize: 'cover' }} ></div> : false }
                    </div>
                }
            }
        }
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            const i_estructura = estructura.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            let extraInfo = ""


            if(i_estructura > -1) {
                extraInfo = estructura.modulos[i_estructura].extraInfo
            }

            let onlyRead = false
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                onlyRead    = estructuraActual.modulos[i].onlyRead === true
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }
            return <div>
                <Row>
                    <Col><h6 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }} className="mb-0">{titulo}</h6>
                    </Col>
                    <Col className="text-right">
                    <Form.Group key={`${type}-${campo}`} className="mb-1" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" disabled={onlyRead} checked={activo} label={ activo === true ? "Activo" : "Inactivo"} onChange={(e) => handleCheck(e,type,campo)} />
                    </Form.Group>
                    </Col>
                    {extraInfo ? <Col xs={12}><Card className="p-1 bg-dark text-white" style={{ fontSize: 13 }}>{extraInfo}</Card></Col> : false}
                    <Col xs={12}>
                        <label className="form-control-label">Contenido extra al final del módulo</label>
                        <textarea className="form-control" value={extra} onChange={(e) => handleChangeExtra(e,type, campo)}></textarea>
                    </Col>
                    {opcionesImagen(type, campo)}
                </Row>
                
                
            </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            let onlyRead = false
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                onlyRead = estructuraActual.campos[i_].onlyRead === true
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" disabled={onlyRead} checked={activo} label={titulo} onChange={(e) => handleCheck(e,type,campo)} />
                </Form.Group>
            </div>
        }
    }

    if(loading) return <PantallaCargando />

    return <div>
        <PreviewEmail estructuraActual={estructuraActual} components={components} />
        <Row>
            <Col><p>Actualiza la visualización por módulo y por campos </p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        
        <Row>
            <Col xs={5}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">
                    {mostrarBoton('modulo',"logotipo")}
                </Card>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3 mb-4">
                    {mostrarBoton('modulo',"resumen")}
                    {/* <hr className="mt-0 mb-2" />
                    {mostrarBoton('campos',"destinatario")}
                    {mostrarBoton('campos',"email")}
                    {mostrarBoton('campos',"movil")}
                    {mostrarBoton('campos',"direccion")}
                    {mostrarBoton('campos',"bultos")}
                    {mostrarBoton('campos',"bultos_refrigerados")}
                    {mostrarBoton('campos',"bultos_congelados")}
                    {mostrarBoton('campos',"notas")} */}
                </Card>
            </Col>
            <Col xs={7}>
                <Card style={{ border: "none", height: "90%", backgroundColor: gris }} className="p-3  mb-4">
                    {mostrarBoton('modulo',"imagen_cabecera")}
                </Card>
            </Col>
            <Col xs={12}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"linea-tiempo")}</Card>
            </Col>
            <Col xs={12}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"titulo")}</Card>
            </Col>
            <Col xs={12}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"mapa")}</Card>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"evento")}</Card>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"general")}</Card>
            </Col>
            <Col xs={12}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"logo_footer")}</Card>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"footer")}</Card>
            </Col>
        </Row>
        
    </div>
}

export default PersonalizarEmailEstado