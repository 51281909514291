import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Form, Modal } from 'react-bootstrap'
import data, { urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import * as XLSX from 'xlsx'
import { corregirTelefono, sliceIntoChunks, validateEmail } from '../../../lib/helpers/helpers';
import { host, url_images } from '../../../lib/global/data';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../../lib/helpers/dates';
import CargandoFlotante from '../../general/cargando_flotante'
import BuscadorRutas from '../buscador';
import BuscadorExcel from '../../excel-formats/selector';
import { esquema_vehiculos } from '../../../lib/esquemas';
import SelectorTipoAsignacion from '../../general/selector_tipo_asignacion';
import ProveedoresListadoProveedores from '../../proveedores_transporte/enlace/listado_proveedores';
import BuscadorVehiculos from '../../conductores/buscador';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import SelectorBodega from '../../bodega/bodegas/selector_bodega';
import SelectorServicios from '../../servicios/selector';
import { formatoMoneda } from '../../../lib/helpers/main';
import { cerrarSesion } from '../../../redux/actions/session';
import { obtenerGeoDatosGoogle } from '../../../lib/helpers/pedidos/pedidos';
import { AiOutlineFileExcel } from 'react-icons/ai';
import VerifyModelBusiness from '../../model_business/verify_model_business';

const VehiculosImportador = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ loadingCamposPersonalizados, setLoadingCamposPersonalizados ] = useState(true)
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([])
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ servicio, setServicio] = useState(false)
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema_vehiculos)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(false)
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(false)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ codigoProveedor, setCodigoProveedor ] = useState("")
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ showModalTarifas, setShowModalTarifas ] = useState(false)
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const [ idRutaImportar, setIdRutaImportar ] = useState(false)
    const [ bodegaOrigen, seleccionarBodegaOrigen ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const [ selectionRangeBusqueda, setSelectionRangeBusqueda ] = useState(initialDate)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const [ ordenes, setOrdenes ] = useState([])
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const token = session.tokenSession
    const [ statusBoton, setStatusBoton ] = useState(false)
    
    useEffect(() => {
        obtenerCamposPersonalizados()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeAsignacion = (e) => {
        const { value } = e.target
        setCodigoProveedor('')
        return setTipoAsignacion(value)
    }

    const cargandoScreen = () => {
        return <Row>
            <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
            </Row>
    }

    const obtenerCamposPersonalizados = () => {
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCamposPersonalizados(res)
            }
            return setLoadingCamposPersonalizados(false)
        })
        .catch(error => {
            setLoadingCamposPersonalizados(false)
            return toast.error(error.message)
        })
    }

    const iterarTarifasOrden = (orders) => {
        const iterar = orders.map(o => {
            if(typeof o.tarifa === "object"){
                if(!isNaN(o.tarifa.tarifa_cotizacion)) o.tarifa_cotizacion = {
                    _id: o.tarifa._id,
                    tarifa: o.tarifa.tarifa_cotizacion,
                    propietario: o.tarifa.propietario
                }
            }
            delete o.tarifa
            return o
        })
        return iterar
    }

    const subirDatos = async (datos) => {
        const progreso = procesados + 1

        return fetch(`${data.urlapi}/conductores`,{
            method:'POST',
            body: JSON.stringify(datos),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return toast.error('Sin datos')
            } else if(res.errorMessage){
                return toast.error(res.errorMessage)
            }
            return setProcesados(progreso)
        })
        .catch(error => {
            toast.error(error.message)
            return setProcesados(progreso)
        })
    }

    const validarPatentes = async (patentes) => {
        const data_default = patentes.map(pa => ({ patente: pa.toLowerCase().trim(), valido: false }))
        return fetch(`${data.urlapi}/conductores/validar/patentes`,{
            method:'POST',
            body: JSON.stringify({
                patentes
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            toast.error(error.message)
            return data_default
        })
    }
    
    // const end_point_validar_referencia = async () => {
    //     const data_default = referencias.map(pa => ({ referencia: pa, valido: false }))
    //     return fetch(`${data.urlapi}/conductores/validar/patentes`,{
    //         method:'POST',
    //         body: JSON.stringify({
    //             patentes
    //         }),
    //         headers: {
    //             'Content-Type':'application/json',
    //             'Authorization': `Bearer: ${token}`
    //         }
    //     })
    //     .then(res => {
    //         if(res.status === 401) return dispatch(cerrarSesion())
    //         return res.json()
    //     })
    //     .then(res => {
    //         if(!res){
    //             toast.error('Sin datos')
    //             return false
    //         } else if(res.errorMessage){
    //             toast.error(res.errorMessage)
    //             return false
    //         } else if(Array.isArray(res) !== false){
    //             console.log("RESPUESTA DE PATENTES", { ... res})
    //             return res
    //         }
    //         return data_default
    //     })
    //     .catch(error => {
    //         toast.error(error.message)
    //         return data_default
    //     })
    // }

    const obtenerUrlCreacionBasadoEnTipoAsignacion = () => {
        if( tipoAsignacion === "vehiculo") return `${data.urlapi}/rutas/por-patente`
        if( tipoAsignacion === "proveedor") return `${data.urlapi}/rutas`
        return ''
    }

    const crearVehiculo = async (registros) => {
        const nuevo = {
          nombres: registros.nombres,
          apellidos: registros.apellidos,
          patente: registros.patente_vehiculo.toLowerCase().trim().replace(/\s/g, ""),
          rut: registros.rut_conductor,
          modelo: registros.modelo_vehiculo,
          ano: parseInt(registros.ano_vehiculo),
          phone: registros.movil_whatsapp
        }
        
        return fetch(`${data.urlapi}/conductores`,{
            method:'POST',
            body: JSON.stringify(nuevo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res._id){
                return res._id
            }
            return false
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const importacionExitosa = () => {
        if(props.onDatosImportados) return props.onDatosImportados()
        return true
    }

    const obtenerRegistrosParaCreacion = (datos, patente_vehiculo) => {
        if(tipoAsignacion === "vehiculo"){
            if(conductorSeleccionado) return datos
            const filtrados = datos.filter(reg => reg.vehiculo === patente_vehiculo)
            return filtrados
        } else if( tipoAsignacion === "proveedor"){
            return datos
        }

    }

    const subirRegistros = async () => {
        if(registros.length < 1) return toast.error("No hay registros para importar")
        setErrores([])
        let vehiculos = []
        setLoadingCarga(true)

        // setMensajeLoading('Validando patentes')
        // const validar_referencias = await validarReferencias(registros.patente_vehiculo)

        // if(Array.isArray(validar_referencias) !== true ){
        //     setLoadingCarga(false)
        //     if (validar_referencias[0].valido) return toast.error("esta patente ya existe") 
        //     return 
        // }
        
        // if(validar_referencias.length > 0){
        //     if(omitirEstados === true){
        //         for( const orden of validar_referencias ){
        //             const { referencia } = orden
        //             const i = registros.findIndex(r => r.referencia === referencia)
        //             if( i > -1) registros.splice(i,1)
        //         }
        //         setRegistros(prev => [...[], ...registros])
        //     } else {
        //         setLoadingCarga(false)
        //         return
        //     }
        // } 

        setMensajeLoading('Creando Vehiculos')
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        
        let total_procesadas = 0
        for( const vehiculo of registros ){
                await crearVehiculo(vehiculo)
                total_procesadas = total_procesadas + 1
                setOperacionesListas(total_procesadas)
        }
        setLoadingMaster(false)
        setLoadingCarga(false)
        toast.success("Progreso finalizado")
        importacionExitosa()
        setTimeout(() => {
            window.location = '/vehiculos'
        }, 200);
        return setRegistros([])
    }
    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const tablaRegistros = () => {
        if(registros.length < 1) return false

        return <div>
            <Table>
                <thead>
                    <tr>
                        <th>NOMBRES</th>
                        <th>APELLIDOS</th>
                        <th>RUT CONDUCTOR</th>
                        <th>MODELO VEHÍCULO</th>
                        <th>AÑO VEHÍCULO</th>
                        <th>PATENTE VEHICULO</th>
                        <th>MOVIL WHATSAPP</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.nombres}</th>
                            <th>{reg.apellidos}</th>
                            <th>{reg.rut_conductor}</th>
                            <th>{reg.modelo_vehiculo}</th>
                            <th>{reg.ano_vehiculo}</th>
                            <th>{reg.patente_vehiculo}</th>
                            <th>{reg.movil_whatsapp}</th>

                        </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    // const validarReferencias = async (pedidos) => {
    //     let referencias = []
    //     pedidos.map(pedido => referencias.push(pedido.patente_vehiculo))
    
    //     setLoadingMaster(true)
    //     setLoadingOperaciones(pedidos.length)
    //     setOperacionesListas(0)
    //     setErrores([])
    //     const arraydividido = sliceIntoChunks(referencias, 10)
    //     let cantidad_procesada = 0
    //     let registros_duplicados = []
    //     for( const datos of arraydividido){
    //         const response = await end_point_validar_referencia(datos)
    //         const duplicados = response.filter(da => da.valido === false)
    //         if(duplicados.length > 0) duplicados.map(ped => errores.push(`Patente ${ped.referencia} ya existe`))
    //         cantidad_procesada = cantidad_procesada + datos.length
    //         setOperacionesListas(cantidad_procesada)
    //         setErrores(prev => [...[], ...errores])
    //         setDuplicados(prev => [...prev, ...duplicados])
    //         registros_duplicados = [...registros_duplicados, ...duplicados]
    //     }

    //     setLoadingMaster(false)
    //     setLoadingOperaciones(0)
    //     setOperacionesListas(0)
    //     return registros_duplicados
    // }

    const onChangeServicio = (e) => {
        setServicio(e.value)
    }

    const handleCloseTarifas = () => {
        return setShowModalTarifas(false)
    }

    const analizarResultadosCotizacion = (orders) => {
        for( const order of orders ){
            const i = registros.findIndex(o => o.referencia === order.referencia && o.direccion === order.direccion)
            if(i < 0) continue
            if(order.geo_datos) registros[i].geo_datos = order.geo_datos 
            if(typeof order.tarifa === "object"){
                if(!showModalTarifas) setShowModalTarifas(true)
                registros[i].tarifa = order.tarifa
            } else {
                registros[i].tarifa = false
            }
        }
        setRegistros(prev => [...[], ...registros])
    }

    const cotizarOnline = async (orders)=>{
        return fetch(`${urlapi}/pricing/quotation/batch`,{
            method:'POST',
            body: JSON.stringify({
                orders,
                codigo_proveedor: codigoProveedor,
                servicio
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(Array.isArray(res) !== false){
                return res
            }
            return []
        })
        .catch(error => [])
    }

    const cotizarOrdenes = async () => {
        if(!servicio) return toast.error("Selecciona un tipo de servicio para la cotización")
        const pedidos = JSON.parse( JSON.stringify( registros ) )

        const filtrar_sin_tarifa = pedidos //.filter(pe => !pe.tarifa || parseInt(pe.tarifa) < 1)
        //if(filtrar_sin_tarifa.length < 1) return toast.error('No hay pedidos pendientes de cotizar')
        
        // this.setState({ cotizando: true, operaciones_loading: filtrar_sin_tarifa.length, operaciones_loading_done:0, operaciones_mensajes: [] })
        setMensajeLoading('Cotizando')
        setLoadingMaster(true)
        setLoadingOperaciones(pedidos.length)
        setOperacionesListas(0)
        let total_procesadas = 0

        const dividir = sliceIntoChunks(filtrar_sin_tarifa, 50)
            for( const array of dividir ){
                const resultados = await cotizarOnline(array)
                analizarResultadosCotizacion(resultados)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
        }

        setLoadingMaster(false)
        toast.success("Progreso finalizado")

    }

    const cotizacionesAcciones = () => {
        if(!codigoProveedor) return false

        return <div>
            <h4>Cotizar órdenes</h4>
            <Row>
                <Col md={4}>
                <SelectorServicios titulo="Tipo de servicio a cotizar" onChange={data => onChangeServicio(data)} codigo_proveedor={codigoProveedor} />
                </Col>
                <Col md={4}>
                <label className='form-control-label d-block'>Click par cotizar</label>
                <Button disabled={loadingMaster}  onClick={() => cotizarOrdenes()} variant='light' >COTIZAR</Button>
                </Col>
                <Col md={12}>{mostrarTarifas()}</Col>
            </Row>
            </div>
    }

    const onClickEmpresa = (data) => {
        setCodigoProveedor(data.codigo)
    }

    const handleCheckReferencias = (e) => {
        const valor = e.target.checked
        return setOmitirEstados(valor)
    }

    const accionesCarga = () => {
//         if(errores.length > 0) return false NO ESTOY SEGURO DE SI BORRAR
        if(registros.length < 1) return false
        let vehiculos = []
        registros.map(re => {
            const i = vehiculos.filter(v => v === re.vehiculo)
            if(i.length > 0) return 
            vehiculos.push(re.vehiculo)
            return
        })


        return <div className='mb-3'>
            {
                loadingCarga === true ? <div>
                    <Spinner animation='border' />
                    <h5>Espere un momento</h5>
                    </div> : <div>
                    
                    <Row>
                       <Col md={12}>
                        <h5>{registros.length} Registros cargados</h5>
                        <p className='mb-1'>Has click para crear <b className='text-primary'>{registros.length} vehiculos</b></p>
                        {cotizacionesAcciones()}
                        <Button size="sm" variant="success" className="mt-3" onClick={()=>subirRegistros()} disabled={statusBoton} >CREAR {registros.length} VEHICULO</Button>
                        </Col>
                    </Row>
                    </div>
            }
        </div>
    }

    const onChangeRuta = (data) => {
        return setIdRutaImportar(data.value)
    }

    const mostrarTarifas = () => {
        const filter = registros.filter(o => o.tarifa)
        if(filter.length < 1) return false


        const total_cotizacion = registros.reduce((prev,next) => {
            let sumar = 0
            let tarifa = false
            if(next.tarifa) if(typeof next.tarifa === "object") if(!isNaN(next.tarifa.tarifa_cotizacion)) tarifa = next.tarifa.tarifa_cotizacion
            if(tarifa !== false) sumar = tarifa
            return prev + sumar
        }, 0)

        return <div>
            <Card bg="info" className="p-2 text-white">
            <h6 className="mb-0">¿Deseas ver las tarifas cotizadas? <Button size="sm" variant="link text-white" onClick={() => setShowModalTarifas(true)}><BiSolidChevronRight size={20} /> <b>VER COTIZACIÓN {formatoMoneda(total_cotizacion)}</b></Button></h6>
            </Card>
            <Modal show={showModalTarifas} size="lg" onHide={handleCloseTarifas} centered >
                <Modal.Header closeButton>
                <Modal.Title>Tarifas cotizadas </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                <h4 className='mb-3'>Total cotización: <b className='text-success'>{formatoMoneda(total_cotizacion)}</b></h4>
                <Table>
                <thead>
                    <tr>
                        <th>REFERENCIA</th>
                        <th>DIRECCION</th>
                        <th>TARIFA</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            let tarifa = false
                            if(reg.tarifa) if(typeof reg.tarifa === "object") if(!isNaN(reg.tarifa.tarifa_cotizacion)) tarifa = reg.tarifa.tarifa_cotizacion
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.referencia}</th>
                            <th>{reg.direccion}</th>
                            <th>{tarifa !== false ? formatoMoneda(tarifa) : false }</th>
                        </tr>
                        })
                    }
                </tbody>
            </Table>
                </Modal.Body>
            </Modal>
        </div>
    }

    const mostrarRegistros = () => {
        if(loadingExcel === true ) return cargandoScreen()
        return <div>
            {mostrarErrores()}
            {accionesCarga()}
            {tablaRegistros()}
        </div>
    }

    const reemplazarLlave = (key) => {
        if(!formatoSeleccionado) return key
        const i = formatoSeleccionado.columnas.findIndex(e => e.target === key)
        if(i > -1) return formatoSeleccionado.columnas[i].key
        return key
    }

    const handleInputChange = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let columnas_buscar = columnasrequeridas

            if(formatoSeleccionado){
                if(formatoSeleccionado.columnas){
                    if( Array.isArray(formatoSeleccionado.columnas) !== false ){
                        for( const col of formatoSeleccionado.columnas ){
                            const { target } = col
                            const pos = columnas_buscar.findIndex(c => c === target)
                            if(pos > -1) columnas_buscar[pos] = col.key
                        }
                    }
                }
            }

            console.log(hojas[0])
                        
            hojas[0].map((row,irow) => {
                
                let campos_faltantes = []
                columnas_buscar.map(nombre => {
                    // if(tipoAsignacion === "proveedor"){
                        if(typeof nombre !== "String" || nombre === null){
                        if(!row[nombre]) campos_faltantes.push(nombre)
                    } else {
                        if(!row[nombre]) campos_faltantes.push(nombre)
                    }
                })

                if(campos_faltantes.length > 0){
                    errores.push(`Fila ${irow+2} le faltan campos obligatorios ${campos_faltantes.join(', ')}`)
                    return false
                }

                // const email = validateEmail(row[reemplazarLlave("email_contacto")] ? row[reemplazarLlave("email_contacto")].toString().trim() : '')
                // if(!email){
                //     console.log({ email, excel_email: row[reemplazarLlave("email_contacto")]  })
                //     errores.push(`Fila ${irow+2} tiene un email inválido.`)
                //     return false
                // }

                // const i_existe = registros.findIndex(re => re.referencia === row[reemplazarLlave("referencia")].toString().trim())
                // let cantidad = parseInt(row[reemplazarLlave("cantidad")])
                // if(isNaN(cantidad)) cantidad = 1

                // if(i_existe > -1){
                //     const i_producto_existe = registros[i_existe].productos.findIndex(pro => pro.codigo_item === row[reemplazarLlave("codigo_item")].toString())
                //     if(i_producto_existe > -1){
                //         registros[i_existe].productos[i_producto_existe].quantity = registros[i_existe].productos[i_producto_existe].quantity + cantidad
                //     } else {
                //         registros[i_existe].productos.push({ 
                //             description: row[reemplazarLlave('item')] ? row[reemplazarLlave('item')].toString() : '',
                //             quantity: cantidad,
                //             codigo_item: row[reemplazarLlave('codigo_item')] ? row[reemplazarLlave('codigo_item')].toString() : '',
                //             peso: row[reemplazarLlave("peso")] ? parseInt(row[reemplazarLlave("peso")]) : 0,
                //             alto: row[reemplazarLlave("alto")] ? parseInt(row[reemplazarLlave("alto")]) : 0,
                //             ancho: row[reemplazarLlave("ancho")] ? parseInt(row[reemplazarLlave("ancho")]) : 0,
                //             largo: row[reemplazarLlave("largo")] ? parseInt(row[reemplazarLlave("largo")]) : 0,
                //             price: row[reemplazarLlave("precio_item")] ? parseInt(row[reemplazarLlave("precio_item")]) : 0,
                //         })
                //     }
                //     return
                // }
                        // if(typeof row["nombres"] !== "String" || row["nombres"].length < 1  ) return toast.error(`El campo nombres es requerido y debe ser un texto`)
                        // if(typeof row["apellidos"] !== "String" || row["apellidos"].length < 1  ) return toast.error(`El campo apellidos es requerido y debe ser un texto`)
                        // if(typeof row["rut_conductor"] !== "String" || row["rut_conductor"].length < 1  ) return toast.error(`El campo rut_conductor es requerido y debe ser un texto`)
                        // if(typeof row["modelo_vehiculo"] !== "String" || row["modelo_vehiculo"].length < 1  ) return toast.error(`El campo modelo_vehiculo es requerido y debe ser un texto`)
                        // if(typeof row["año_vehiculo"] !== "Number" || row["año_vehiculo"].length < 1  ) return toast.error(`El campo año_vehiculo es requerido y debe ser un dato numerico`)
                        // if(row["año_vehiculo"].length < 4  ) return toast.error(`El campo año_vehiculo es invalido debe llevar solo 4 digitos`)
                        // if(typeof row["patente_vehiculo"] !== "String" || row["patente_vehiculo"].length < 1  ) return toast.error(`El campo patente_vehiculo es requerido y debe ser un texto`)
                        // if(typeof row["movil_whatsapp"] !== "String" || row["movil_whatsapp"].length < 1  ) return toast.error(`El campo movil_whatsapp es requerido y debe ser un texto`)

                let nuevo_registro = {
                    nombres: row[reemplazarLlave("nombres")] ? row[reemplazarLlave("nombres")].toString() : '',
                    apellidos: row[reemplazarLlave("apellidos")] ? row[reemplazarLlave("apellidos")].toString() : '',
                    rut_conductor: row[reemplazarLlave("rut_conductor")] ? row[reemplazarLlave("rut_conductor")].toString() : '',
                    modelo_vehiculo: row[reemplazarLlave("modelo_vehiculo")] ? row[reemplazarLlave("modelo_vehiculo")].toString() : '',
                    ano_vehiculo: row[reemplazarLlave("año_vehiculo")] ? row[reemplazarLlave("año_vehiculo")].toString().replace(/\D/g, "").slice(0, 4) : 0,
                    patente_vehiculo: row[reemplazarLlave("patente_vehiculo")] ? row[reemplazarLlave("patente_vehiculo")].toString().toLowerCase().trim().replace(/\s/g, "").replace(/[^a-zA-Z0-9 ]/g, "") : '',
                    movil_whatsapp: row[reemplazarLlave("movil_whatsapp")] ? row[reemplazarLlave("movil_whatsapp")].toString() : '',
                }
                
                // if(camposPersonalizados.length > 0){
                //     for( const meta of camposPersonalizados ){
                //         if(row[meta.slug]){
                //             nuevo_registro.meta_data.push({
                //                 key: meta.slug,
                //                 value: row[meta.slug]
                //             })
                //         }
                //     }
                // }

                // VERIFICAR CONCATENACIONES

                // if(formatoSeleccionado){
                //     if(formatoSeleccionado.columnas){
                //         if(Array.isArray(formatoSeleccionado.columnas) !== false){
                //             const filtradas_concatenaciones = formatoSeleccionado.columnas.filter(c => {
                //                 if(!c.concatenar) return false
                //                 if(Array.isArray(c.concatenar) !== true) return false
                //                 if(c.concatenar.length < 1) return false
                //                 return true
                //             })
                //             console.log({filtradas_concatenaciones})
                //             if(filtradas_concatenaciones.length > 0){
                //                 for( const filtrada of filtradas_concatenaciones ){
                //                     for( const concatenacion of filtrada.concatenar ){
                //                         if(!concatenacion.columna) continue
                //                         let valor = nuevo_registro[filtrada.target]
                //                         if(concatenacion.texto) valor = `${valor}${concatenacion.texto}`
                //                         if(row[concatenacion.columna]) valor = `${valor}${row[concatenacion.columna]}`
                //                         nuevo_registro[filtrada.target] = valor
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }

                registros.push(nuevo_registro)
            })
            
            console.log(registros)
            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }
            setLoadingExcel(false)
        
            return setRegistros(registros)
          }
        }
    }


    // const mostrarCamposPersonalizados = () => {
    //     if(loadingCamposPersonalizados === true) return <div><Spinner animation='border' /></div>
    //     if(camposPersonalizados.length < 1) return false
    //     return <div>
    //         <h6 className="mb-0" style={{ fontWeight: "bold" }}>Campos personalizados</h6>
    //         <p className="mb-0">Puedes agregar cualquier de estos campos personalizados a tu archivo excel</p>
    //         <hr className='hr' />
    //         {
    //             camposPersonalizados.map(campo => {
    //                 return <Button size="sm" variant="light" className='mr-3 mb-3' style={{ fontSize: 11, textTransform: "uppercase" }}>{campo.slug}</Button>
    //             })
    //         }
    //         <hr className='hr' />
    //     </div>
    // }


    const obtenerOrdenes = async (cond)=>{
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/ordenes/pedidos-custom-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion: cond,
                campos: { 
                    estado_entrega: true, 
                    sub_estado_entrega: true,
                    pedido: true
                }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)
            }
            if(Array.isArray(res) !== false){
                setOrdenes(res)
                // setTotal(res.total)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingOrdenes(false)
        })
    }

    const buscarOrdenesFiltradas = async () => {
        const desde = selectionRangeBusqueda.startDate
        const hasta = addMoreDays(selectionRangeBusqueda.endDate,1)

        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            const condicion = { fecha_max_entrega: { $gte: new Date( formatDateHoy(selectionRange.startDate)), $lte: new Date(hasta.getTime()-1) } }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = { $in: filtroEstado }
            setCondicionBusqueda(condicion)
            obtenerOrdenes(condicion)
        }
    }

    const onChangeValueExcel = (e) => {
        setFormatoSeleccionado(e)
    }

    const showAsignementBox = () => {
        if(tipoAsignacion === "vehiculo"){
            return <div>
                <Card className="p-3">
                <img src={`${url_images}/animations/driver.gif`} style={{ width: 150 }} />
                    <h4> Asignar a conductor</h4>
                    <p className='mb-0'>Seleccionando un conductor se creará una ruta asignada a dicho conductor y se asignarán las órdenes correspondientemente.</p>
                    <BuscadorVehiculos onChange={data => setConductorSeleccinoado(data)} token={token} />
                </Card>
            </div>
        } else if( tipoAsignacion === "proveedor"){
            return <div>
                <ProveedoresListadoProveedores hightLightCodigo={codigoProveedor} onClick={(data) => onClickEmpresa(data)} displayAll={true} condicion_default={{ propietario_cliente: session.data.propietario }} />
            </div>
        }
      }

    return <div style={{background: statusBoton ? '#ffd971' : '', padding: 10, borderRadius: 10}}>
        <h4><i className="far fa-file-excel"></i> Importar datos excel</h4>
        <p>Puedes importar un archivo excel para crear conductores</p>
        <a href={`${host}/models/vehiculo-modelo.xlsx`}><Button variant="outline-primary" className="mb-3" size="sm"><AiOutlineFileExcel  />  DESCARGAR MODELO</Button></a>
        {/* {mostrarCamposPersonalizados()} */}

        {/* <Row>
            <Col><SelectorBodega onChange={(data) => seleccionarBodegaOrigen(data)} /></Col>
            <Col> <SelectorTipoAsignacion onChange={(e) => handleChangeAsignacion(e)} /></Col>
        </Row> */}
        <Row>
            {/* <Col md={6} className="mb-3">
                    {showAsignementBox()}
            </Col>
            <Col md={6} className="mb-3">
                <Card className="p-3">
                    <img src={`${url_images}/animations/cargo-list.gif`} style={{ width: 150 }} />
                    <h4>Asignar a ruta existente</h4>
                    <p className='mb-0'>Seleccionando una ruta a continuación se ignorará la creación de nuevas rutas y se asignarán las órdenes a la ruta seleccionada</p>
                    <BuscadorRutas token={token} onChangeValue={(data) => onChangeRuta(data)} />
                </Card>
            </Col> */}
            <Col md={12} className="mb-3">
            <Card className='p-3'>
            <h4 className='d-block'><AiOutlineFileExcel size={30} color='#2d9d57' /> Subir archivo excel</h4>
            <Row>
                <Col>
                <label className='form-control-label'>Carga aquí tu archivo excel</label>
                {
                    loadingExcel ? <Spinner animation='border' /> : <input
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control mb-3"
                    onChange={handleInputChange} 
                    placeholder="Archivo de excel" 
                />
                }
                </Col>
            </Row>
            
            </Card>
            </Col>
        </Row>
        
        
        <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
        {
            registros ? <VerifyModelBusiness vehiculo={true} excelVehiculos={registros.length} token={token} handleChangeBoton={(status) => setStatusBoton(status)} />
            : <Spinner animation='border' />
        }
        {mostrarRegistros()}
    </div>

}

export default VehiculosImportador