import { useCallback, useEffect, useState } from "react"
import { Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import PreviewTrackingWeb from "./preview_tracking"

const PersonalizarTracking = (props) => {
    const components = {
        resumen: ''
    }
    const estructura = {
        modulos: [
            {
                nombre: "Resumen",
                slug: "resumen",
                activo: true,
            },
            {
                nombre:"Mapa",
                slug: "mapa",
                activo: true
            },
            {
                nombre: "Estados de carga",
                slug: "estados_carga",
                activo: true
            },
            {
                nombre: "Más información",
                slug: "mas_info",
                activo: true
            },
            {
                nombre: "Pie de página",
                slug: "footer",
                activo: true
            },
            {
                nombre: "Calificar",
                slug: "calificar",
                activo: false,
                onlyRead: false
            }
        ],
        campos: [
            {
                nombre: "Destinatario",
                slug: "destinatario",
                activo: true
            },
            {
                nombre: "Email",
                slug: "email",
                activo: true
            },
            {
                nombre: "Móvil",
                slug: "movil",
                activo: true
            },
            {
                nombre: "Dirección",
                slug: "direccion",
                activo: true
            },
            {
                nombre: "Bultos",
                slug: "bultos",
                activo: true
            },
            {
                nombre: "Bultos refrigerados",
                slug: "bultos_refrigerados",
                activo: true
            },
            {
                nombre: "Bultos congelados",
                slug: "bultos_congelados",
                activo: true
            },
            {
                nombre: "Notas",
                slug: "notas",
                activo: true
            }
        ]
    }

    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'

    useEffect(() => {
        obtenerDatos("tracking")
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "personalizacion",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                for( const modulo of estructura.modulos){
                    const i = res.detalles.modulos.findIndex(mod => mod.slug === modulo.slug)
                    if(i > -1){
                        let crear_modulo = res.detalles.modulos[i]
                        Object.keys(modulo).forEach(key => {
                            if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                        })
                        console.log(crear_modulo)
                        res.detalles.modulos[i] = crear_modulo
                    } else {
                        res.detalles.modulos.push(modulo)
                    }
                }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const handleChangeExtra = (e, type, campo) => {
        const valor = e.target.value
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 setPendienteGuardar(true)
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].extra = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const handleCheck = (e, type, campo) => {
        const valor = e.target.checked
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].onlyRead === true) return false
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        } else if(type === "campos"){
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            if( i_ > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.campos[i_].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }
            return <div>
                <Row>
                    <Col><h6 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }} className="mb-0">{titulo}</h6></Col>
                    <Col className="text-right">
                    <Form.Group key={`${type}-${campo}`} className="mb-1" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" checked={activo} label={ activo === true ? "Activo" : "Inactivo"} onChange={(e) => handleCheck(e,type,campo)} />
                    </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <label className="form-control-label">Contenido extra al final del módulo</label>
                        <textarea className="form-control" value={extra} onChange={(e) => handleChangeExtra(e,type, campo)}></textarea>
                    </Col>
                </Row>
                
                
            </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" checked={activo} label={titulo} onChange={(e) => handleCheck(e,type,campo)} />
                </Form.Group>
            </div>
        }
    }

    if(loading) return <PantallaCargando />

    return <div>
        <PreviewTrackingWeb estructuraActual={estructuraActual} components={components} />
        <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"calificar")}</Card>

        <Row>
            <Col><p>Actualiza la visualización por módulo y por campos </p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        
        <Row>
            <Col xs={5}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3 pb-5 mb-4">
                    {mostrarBoton('modulo',"resumen")}
                </Card>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3 mb-4">
                    {mostrarBoton('modulo',"mas_info")}
                    <hr className="mt-0 mb-2" />
                    {mostrarBoton('campos',"destinatario")}
                    {mostrarBoton('campos',"email")}
                    {mostrarBoton('campos',"movil")}
                    {mostrarBoton('campos',"direccion")}
                    {mostrarBoton('campos',"bultos")}
                    {mostrarBoton('campos',"bultos_refrigerados")}
                    {mostrarBoton('campos',"bultos_congelados")}
                    {mostrarBoton('campos',"notas")}
                </Card>
            </Col>
            <Col xs={7}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3 pb-5 mb-4">{mostrarBoton('modulo',"mapa")}</Card>

                <Card style={{ border: "none", backgroundColor: gris }} className="p-3 pb-5 mb-4">{mostrarBoton('modulo',"estados_carga")}</Card>
            </Col>
            <Col xs={12}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3 pb-5 mb-4">{mostrarBoton('modulo',"footer")}</Card>
            </Col>
        </Row>
        
    </div>
}

export default PersonalizarTracking