import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { urlapi } from "../../lib/backend/data"
import { Button, Card, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useSelector } from "react-redux"
import { IoIosHelpCircle, IoMdTimer } from "react-icons/io"
import { LuWarehouse } from "react-icons/lu"
import { estilo_last_mile } from "../../lib/global/styles"
import { FaCheckCircle, FaRegEye, FaTruckLoading } from "react-icons/fa"

const EstadoExpedicionRuta = (props) => {
    const {
        id_ruta
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState('')
    const [ datos, setDatos ] = useState(null)
    const [ searchText, setSearchText ] = useState('')
    const [ showModal, setShowModal ] = useState(false)
    const [ titleModal, setTitleModal ] = useState('')
    const [ recordsModal, setRecordsModal ] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const obtenerDetalles = async () => {
        if(!id_ruta) return setLoading(false)
        return fetch(`${urlapi}/bodega/expediciones/estado-ruta?id=${id_ruta}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                setError('Hubo un error de servidor')
              } else if(pros.errorMessage){
                setError("Error al procesar la información")
              } else if(pros._id){
                  setDatos(pros)
              }
              return setLoading(false)
          })
          .catch(async error => {
                setError('Error al obtener los datos')
                return setLoading(false)
          })
    }

    useEffect(() => {
        obtenerDetalles()
    }, [])

    if(loading) return <div>
        <Skeleton height={20} className="mb-3" />
        <Skeleton height={20} className="mb-3" />
        <Skeleton height={20} className="mb-3" />
    </div>

    const TooltipMessage = (props) => {
        const {
            message
        } = props
        return <OverlayTrigger
        key={'right'}
        trigger="hover"
        overlay={
            <Tooltip id={'right'}>{message}</Tooltip>
        }>
            <IoIosHelpCircle size={20} />
        </OverlayTrigger>
    }

    const buscarPedidos = (searchText, records) => {
        if(!searchText) return records
        return records.filter(record => record.pedido.includes(searchText))
    }

    const mostrarContenido = () => {

        const filtrados = searchText ? buscarPedidos(searchText, recordsModal) : recordsModal

        const byStatus = (record) => {
            const size = 20
            if(record.listo === true) return <FaCheckCircle size={size} color="green" />
            if(record.iniciado === true) return <FaTruckLoading size={size} />
            if(record.pendiente === true) return <IoMdTimer size={size} />
        }
        
        return <div>
            <h2>{titleModal}</h2>
            <input className="form-control" placeholder="Filtra por referencia de la orden" onChange={(e) => setSearchText(e.target.value)} />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <td>Estado</td>
                        <td>Orden</td>
                        <td>Escaneados</td>
                        <td>Total</td>
                    </tr>
                </thead>
            {
                filtrados.map((record, i) => {
                    return <tr key={`r-${i}`}>
                        <td>{byStatus(record)}</td>
                        <td>{record.pedido}</td>
                        <td>{record.escaneados}</td>
                        <td>{record.labels}</td>
                    </tr>
                })
            }
            </table>
        </div>
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const abrirModal = (titulo, registros) => {
        setTitleModal(titulo)
        setRecordsModal(registros)
        setShowModal(true)
    }

    const mostrarBotonAbrirModal = (titulo, registros) => {
        const registros_ = registros?.records
        return <h2><b>{registros?.total}</b> <Button disabled={registros_.length < 1} variant="light" size="sm" onClick={()=> abrirModal(titulo, registros?.records)}><FaRegEye /></Button></h2>
    }

    if(!datos) return false

    const titulo1 = "Órdenes preparadas"
    const titulo2 = "Órdenes en preparación"
    const titulo3 = "Órdenes pendientes"

    return <div>
        <Modal  className="mb-0 p-3" show={showModal} size="lg" onHide={()=>handleClose()} centered >
        <Modal.Body>
          {mostrarContenido()}
        </Modal.Body>
      </Modal>
        <Card className="p-2">
        <h4><LuWarehouse style={estilo_last_mile.icon} /> <b>Expedición en bodega</b></h4>
        <p>Cuando los conductores escanean los bultos de la bodega para salir a ruta acá puedes validar si las órdenes de esta ruta ya fueron escaneadas.</p>
        <Row>
            <Col>
                <h6 className="mb-0">{titulo1} <TooltipMessage message="Estas son las órdenes cuyos bultos ya fueron escaneadas por el conductor a la espera de confirmación" /> </h6>
                {mostrarBotonAbrirModal(titulo1, datos.ordenes_listas)}
            </Col>
            <Col>
                <h6 className="mb-0">{titulo2} <TooltipMessage message="Estas son las órdenes que tienen algunos bultos escaneados pero aún faltan" /></h6>
                {mostrarBotonAbrirModal(titulo2, datos.ordenes_parciales)}
            </Col>
            <Col>
                <h6 className="mb-0">{titulo3} <TooltipMessage message="Estas son las órdenes cuyos bultos ya fueron escaneadas en su totalidad" /></h6>
                {mostrarBotonAbrirModal(titulo3, datos.ordenes_pendientes)}
            </Col>
        </Row>
        </Card>
    </div>
}

export default EstadoExpedicionRuta