import { useState } from "react"
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Modal, Nav, Navbar, Spinner } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import data from '../../lib/backend/data'
import BuscadorRutas from "../rutas/buscador"
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import ErrorBoundary from "../errorsHandler/errorBoundary"

const AccionesModal = (props) => {
    const [ error, setError ] = useState(null)
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ loadingBloqueo, setLoadingBloqueo ] = useState(false)
    const [ loadingMostrarApp, setLoadingMostrarApp ] = useState(false)
    const [ numeroRuta, setNumeroRuta ] = useState('')
    const [ tipoVisualizacion, setTipoVisualizacion ] = useState('')
    const token = props.token ? props.token : false
    const [ pedido, setPedido ] = useState(props.pedido ? props.pedido : false)
    const dispatch = useDispatch()

    const openModalEstado = (tipo) => {
        setOpenModal(true)
        setTipoVisualizacion(tipo)
    }


    const handleChangeRuta = (e) => {
        return setNumeroRuta(e.value) 
    }

    const showByTipoVisualizacion = () => {
        switch (tipoVisualizacion) {
            case 'reasignar':
                return <div className="text-primary">
                    <h3 style={{fontWeight:700}}>Reasignar ruta</h3>
                    <label className="form-control-label d-block" style={{fontWeight:700, fontSize:16}}>Número de ruta</label>
                    <BuscadorRutas token={token} onChangeValue={(e) => handleChangeRuta(e)} />
                    <div className=" d-flex justify-content-end">
                    { loading === true ? <Spinner animation="border" /> : <Button size="sm" className='shadow-sm' variant="primary" onClick={()=>reasignarRuta()} ><b>CONFIRMAR</b></Button> }
                    </div>
                </div>
            default:
                break;
        }
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const reasignarRuta = () => {
        if(!numeroRuta) return setError('Número de ruta es requerido')
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes/ruta`,{
            method:'PUT',
            body: JSON.stringify({
                id_pedido: pedido._id,
                ruta: numeroRuta
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                toast.success('Operación realizada exitosamente')
                setOpenModal(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }


    const confirmarEliminado = () => {
        setLoading(true)
        return fetch(`${data.urlapi}/ordenes?id=${pedido._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/ordenes'
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }
    
    const bloquearEntrega = (i) => {
        setLoadingBloqueo(true)
        return fetch(`${data.urlapi}/ordenes/bloquear-entrega?id=${pedido._id}&bloquear=${i}`,{
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingBloqueo(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingBloqueo(false)
            } else if(res._id){
                toast.success('Operación exitosa')
                pedido.entrega_bloqueada = i === 'yes' ? true : false
                setPedido(pedido)
            }
            return setLoadingBloqueo(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoadingBloqueo(false)
        })
    }
    
    const mostrarEnApp = (i) => {
        setLoadingMostrarApp(true)
        return fetch(`${data.urlapi}/ordenes/ocultar-entrega?id=${pedido._id}&mostrar=${i}`,{
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingMostrarApp(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingMostrarApp(false)
            } else if(res._id){
                toast.success('Operación exitosa')
                pedido.show_on_app = i === 'yes' ? true : false
                setPedido(pedido)
            }
            return setLoadingMostrarApp(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoadingMostrarApp(false)
        })
    }

    const solicitarEliminar = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta orden, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    return <div>
        <ErrorBoundary message={error} />
            <div className="mt-2 ">
                <Navbar bg="light" variant="light" className='mb-3'>
                <Nav className="mr-auto" style={{fontSize:14}}>
                <Nav.Link ><Link to={`/${rutas.report_tracking.slug}/${pedido._id}`} ><Button size="sm" className='shadow-sm mr-1 w-100' variant="outline-primary">Reporte de tracking</Button></Link> </Nav.Link>
                <Nav.Link ><Link to={`/${rutas.receipts.slug}/${pedido._id}`} ><Button size="sm" className='shadow-sm mr-1 w-100' variant="outline-primary">Recibo PDF</Button></Link> </Nav.Link>
                <Nav.Link onClick={() => openModalEstado('reasignar')} ><Button size="sm" className='shadow-sm mr-1 w-100' variant="outline-primary">Reasignar Ruta</Button></Nav.Link>
                <Nav.Link onClick={() => bloquearEntrega(pedido.entrega_bloqueada === true ? 'no' : 'yes')} ><Button disabled={loadingBloqueo} size="sm" className='shadow-sm mr-1 w-100' variant="outline-primary">{ pedido.entrega_bloqueada === true ? 'Desbloquear pedido' : 'Bloquear pedido' }</Button></Nav.Link>
                <Nav.Link onClick={() => mostrarEnApp(pedido.show_on_app === true ? 'no' : 'yes')} ><Button size="sm" className='shadow-sm mr-1 w-100' disabled={loadingMostrarApp} variant="outline-primary">{ pedido.show_on_app === true ? 'Ocultar pedido en app' : 'Mostrar pedido en app' }</Button></Nav.Link>
                <Nav.Link onClick={() => solicitarEliminar()} ><Button size="sm" disabled={loading} className='shadow-sm mr-1 w-100' variant="danger">Eliminar orden</Button></Nav.Link>
                </Nav>
                </Navbar>
            {/* <Button style={{ fontSize: 12 }} className='shadow-sm mr-1 w-100' variant="outline-primary" size="sm" ><Link  ><b>Reporte de tracking</b></Link></Button> */}
            {/* <Link to={`/${rutas.receipts.slug}/${pedido._id}`} target="_blank" className="w-100" ><Button style={{ fontSize: 12 }} className='shadow-sm mr-1 w-100' variant="outline-primary" size="sm" ><b>Recibo PDF</b></Button></Link>
            <Button style={{ fontSize: 12 }} className='shadow-sm mr-1 w-100' variant="outline-primary" size="sm" onClick={() => openModalEstado('reasignar')}><b>Reasignar Ruta</b></Button>
            <Button style={{ fontSize: 12 }} className='shadow-sm mr-1 w-100' variant="outline-primary" size="sm" onClick={() => bloquearEntrega(pedido.entrega_bloqueada === true ? 'no' : 'yes')}><b>{ pedido.entrega_bloqueada === true ? 'Desbloquear pedido' : 'Bloquear pedido' }</b></Button>
            <Button style={{ fontSize: 12 }} className='shadow-sm mr-1 w-100' variant="outline-primary" size="sm" onClick={() => mostrarEnApp(pedido.show_on_app === true ? 'no' : 'yes')}><b>{ pedido.show_on_app === true ? 'Ocultar pedido en app' : 'Mostrar pedido en app' }</b></Button>
            <Button style={{ fontSize: 12 }} className='shadow-sm mr-1 w-100' variant="danger" size="sm" onClick={() => solicitarEliminar()}><b>Eliminar orden</b></Button> */}
            </div>

        <Modal show={openModal} size="lg" onHide={()=>handleClose()} centered >
            <Modal.Body>
            {showByTipoVisualizacion()}
            </Modal.Body>
        </Modal>
    </div>
}

export default AccionesModal